import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"

import Icon from "@/components/Icon"

const styles = {
  container:
    "w-full h-full py-14 flex flex-col items-center gap-y-2.5 px-8 py-28",
  subtitle: "font-h2-bold text-primary-300",
  card: "font-body-medium-regular text-neutra-700 px-4 py-6 bg-neutral-100 flex flex-col gap-y-4 border border-neutral-300 rounded shadow-card",
  cardTitle: "font-h4-bold",
}

const EmptyQueueView: FunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.container} data-sentry-unmask>
      <Icon name="medal" size="xxl" />
      <h2 className={styles.subtitle}>{t("callPanel.emptyQueue.subtitle")}</h2>
      <div className={styles.card}>
        <h4 className={styles.cardTitle}>
          {t("callPanel.emptyQueue.cardTitle")}
        </h4>
        <p>{t("callPanel.emptyQueue.cardText")}</p>
      </div>
    </div>
  )
}

export default EmptyQueueView
