import { format } from "date-fns"
import i18n from "i18next"

import { ColumnGroupDef, DashboardTable } from "@/components/DashboardTable"
import { PaginationState } from "@/components/pagination/pagination-state"
import { CurrentStatusPill } from "@/components/status-pill"
import {
  EmptyValue,
  TableLink,
  TruncatedTextCell,
} from "@/components/table-helpers"
import { SortOptions } from "@/hooks/table-sorting"

import { CampaignStudent } from "../api/students"

function useColumnGroups(campaign_id: string) {
  const cols: ColumnGroupDef<CampaignStudent>[] = [
    {
      header: i18n.t("campaigns.details.students.name"),
      cols: [
        {
          id: "fullName",
          cell: (student) => (
            <TableLink
              to={`/campaigns/${campaign_id}/students/${student.student_id}`}
            >
              {student.last_name} {student.first_name}
            </TableLink>
          ),
        },
      ],
    },
    {
      sortKey: "gender",
      header: i18n.t("campaigns.preview.table.gender"),
      cols: [
        {
          id: "gender",
          cell: (student) => <>{student.gender}</>,
        },
      ],
    },
    {
      header: i18n.t("campaigns.preview.table.university"),
      cols: [
        {
          id: "university",
          cell: (student) => <>{student.university}</>,
        },
      ],
    },
    {
      header: i18n.t("campaigns.details.students.email"),
      cols: [
        {
          id: "email",
          cell: (student) => <>{student.email}</>,
        },
      ],
    },
    {
      header: i18n.t("campaigns.preview.table.phoneNumber"),
      cols: [
        {
          id: "phoneNumber",
          cell: (student) => <>{student.phone_number}</>,
        },
      ],
    },
    {
      sortKey: "contact_count",
      header: i18n.t("campaigns.details.students.contactCount"),
      cols: [
        {
          id: "contact_count",
          cell: (student) =>
            student.contact_count ? (
              <>{student.contact_count}</>
            ) : (
              <EmptyValue />
            ),
        },
      ],
    },
    {
      sortKey: "latest_contacted_at",
      header: i18n.t("campaigns.details.students.contactDate"),
      cols: [
        {
          id: "latest_contacted_at",
          cell: (student) =>
            student.latest_contacted_at ? (
              <>
                {format(
                  new Date(student.latest_contacted_at),
                  "yyyy/MM/dd HH:mm",
                )}
              </>
            ) : (
              <EmptyValue />
            ),
        },
      ],
    },
    {
      sortKey: "status",
      header: i18n.t("campaigns.details.students.currentStatus"),
      cols: [
        {
          id: "actualEntry",
          cell: (student) => (
            <CurrentStatusPill
              current={student.current_status}
              latestActionTaken={student.latest_status_action_taken}
              className="max-w-full text-center"
            />
          ),
        },
      ],
    },
    {
      header: i18n.t("campaigns.details.students.comment"),
      cols: [
        {
          id: "comment",
          cell: (student) =>
            student.comment ? (
              <TruncatedTextCell text={student.comment} numberOfChar={15} />
            ) : (
              <EmptyValue />
            ),
        },
      ],
    },
  ]

  return cols
}

export function StudentsTable({
  campaign_id,
  students,
  ...rest
}: PaginationState & {
  campaign_id: string
  isLoading: boolean
  sortOptions?: SortOptions
  students?: CampaignStudent[]
  toggleSort: (key: string) => void
}) {
  const columnGroups = useColumnGroups(campaign_id)

  return (
    <DashboardTable
      columnGroups={columnGroups}
      getItemId={(student) => student.student_id}
      items={students || []}
      {...rest}
    />
  )
}
