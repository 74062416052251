import { PropsWithChildren } from "react"

import Header from "@/components/Header"
import * as layoutValues from "@/components/layout/value"
import Sidebar from "@/components/Sidebar"
import { cn } from "@/helpers/classNames"

type Props = PropsWithChildren<{
  agentProfile: layoutValues.Decoder.ConnectUserInformation | null
  isModalOpen?: boolean
}>

export function PageLayout({ agentProfile, children, isModalOpen }: Props) {
  return (
    <div
      className={cn([
        "flex size-full flex-col",
        isModalOpen && "overflow-hidden",
      ])}
    >
      <Header agentProfile={agentProfile} />
      <div className="flex grow flex-row">
        <Sidebar />
        <div className="grow overflow-x-hidden bg-neutral-200">{children}</div>
      </div>
    </div>
  )
}
