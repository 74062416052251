import { PropsWithChildren } from "react"
import { useTranslation } from "react-i18next"
import { AlertCircle, ChevronLeftIcon, InfoIcon } from "lucide-react"

import FileUploader from "@/components/FileUploader"
import Icon from "@/components/Icon"
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert"
import { Button } from "@/components/ui/button"
import {
  DialogBody,
  DialogBodyError,
  DialogBodySuccess,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"
import { useI18n } from "@/helpers/i18n"

import { useUploadNoCallCSV } from "./upload-api"
import { SkippedRow, UploadErrorResponse } from "./upload-schemas"

type Props = {
  close: (result: boolean | null) => void
}

export function DialogUploadNoCallCSV({ close }: Props) {
  const { t } = useTranslation()
  const { tMap } = useI18n()

  const { errors, file, onFileChange, reset, results, step, upload } =
    useUploadNoCallCSV()

  const isProcessing = step === "processing"

  if (step === "success") {
    return (
      <SuccessState
        close={close}
        onGoBack={reset}
        updatedRowCount={results?.data.updated_students.length || 0}
        invalid_data={results?.data.skipped_rows || []}
      />
    )
  }

  if (step === "error") {
    return (
      <ErrorState
        onGoBack={reset}
        errorType={errors?.data.error_type}
        filename={file?.name}
      />
    )
  }

  return (
    <DialogWrapper isProcessing={isProcessing}>
      <DialogBody>
        <div className="flex flex-col gap-y-6">
          <h2 className="font-bold">{t("noCallList.importCSV.intro")}</h2>

          <div className="flex flex-col gap-y-2">
            <div>1 - {t("noCallList.importCSV.step1")}</div>
            <a
              href="/templates/ng_list_template.csv"
              download
              className="flex items-center gap-x-2 font-bold text-primary-400"
            >
              template.csv <Icon name="download" />
            </a>
          </div>

          <div className="flex flex-col gap-y-2">
            2 - {t("noCallList.importCSV.step2")}
            <ul className="list-disc pl-6">
              {tMap("noCallList.importCSV.details", (text, i) => (
                <li key={i}>{text}</li>
              ))}
            </ul>
          </div>

          <div className="flex flex-col gap-y-2">
            <div>3 - {t("noCallList.importCSV.step3")}</div>
            <FileUploader
              accept=".csv"
              disabled={isProcessing}
              className="mt-2"
              fileName={file?.name}
              onUpload={onFileChange}
              placeholder={t("entries.upload.noFilePlaceholder")}
            />
          </div>

          <div>{t("noCallList.importCSV.explanations")}</div>
        </div>
      </DialogBody>
      <DialogFooter>
        <Button variant="outline" onClick={() => close(false)}>
          {t("global.button.cancel")}
        </Button>
        <Button isDisabled={!file} isLoading={isProcessing} onClick={upload}>
          {t("global.button.upload")}
        </Button>
      </DialogFooter>
    </DialogWrapper>
  )
}

/** Show any combination of error / updated rows results */
function SuccessState({
  close,
  invalid_data,
  onGoBack,
  updatedRowCount = 0,
}: {
  close: (result: boolean | null) => void
  invalid_data: SkippedRow[]
  onGoBack: () => void
  updatedRowCount?: number
}) {
  const { t } = useTranslation()
  const errorCount = invalid_data.length

  // Happy path, no errors!
  if (updatedRowCount > 0 && errorCount === 0) {
    return (
      <DialogWrapper>
        <DialogBodySuccess>
          <p className="mb-4 font-bold">
            {t("entries.upload.modal.successTitle")}
          </p>
          {t("entries.upload.modal.successDescription", {
            count: updatedRowCount,
          })}
        </DialogBodySuccess>
        <DialogFooter>
          <Button onClick={() => close(false)} variant="outline">
            {t("global.button.close")}
          </Button>
        </DialogFooter>
      </DialogWrapper>
    )
  }

  // More probable, show two `Alert` blocks: one for the summary and one for the errors
  return (
    <DialogWrapper>
      <DialogBody>
        <div className="flex flex-col gap-3">
          {updatedRowCount > 0 && (
            <Alert>
              <InfoIcon className="size-4" />
              <AlertDescription>
                {t("entries.upload.modal.successDescription", {
                  count: updatedRowCount,
                })}
              </AlertDescription>
            </Alert>
          )}

          {errorCount > 0 && (
            <Alert variant="destructive">
              <AlertCircle className="size-4" />
              <AlertTitle className="font-bold">
                {t("entries.upload.modal.errorTitle", { count: errorCount })}
              </AlertTitle>
              <AlertDescription>
                <div className="h-[300px] overflow-y-auto">
                  <ul className="ml-5 list-disc">
                    {invalid_data
                      .filter((item) => !!item.skippedReason)
                      .map((item: SkippedRow, index) => (
                        <li key={index}>
                          <ErrorRow item={item} />
                        </li>
                      ))}
                  </ul>
                </div>
              </AlertDescription>
            </Alert>
          )}
        </div>
      </DialogBody>
      <DialogFooter>
        <Button onClick={onGoBack} variant="outline">
          <ChevronLeftIcon />
          {t("global.button.goBack")}
        </Button>
        <Button onClick={() => close(false)} variant="outline">
          {t("global.button.close")}
        </Button>
      </DialogFooter>
    </DialogWrapper>
  )
}

/** Catch 400 errors from the API for global errors (invalid file, too many rows...) */
function ErrorState({
  errorType,
  filename,
  onGoBack,
}: {
  errorType?: UploadErrorResponse["data"]["error_type"]
  filename?: string
  onGoBack: () => void
}) {
  const { t } = useTranslation()

  const maxNumberOfRows = 120

  return (
    <DialogWrapper>
      <DialogBodyError>
        <div className="flex flex-col gap-4">
          <p>{t("noCallList.importCSV.globalError", { filename })}</p>
          <p>
            {errorType &&
              t(`noCallList.importCSV.errors.${errorType}`, {
                max: maxNumberOfRows,
              })}
          </p>
        </div>
      </DialogBodyError>
      <DialogFooter>
        <Button onClick={onGoBack}>{t("global.button.goBack")}</Button>
      </DialogFooter>
    </DialogWrapper>
  )
}

function DialogWrapper({
  children,
  isProcessing,
}: PropsWithChildren<{ isProcessing?: boolean }>) {
  const { t } = useTranslation()

  return (
    <DialogContent className="max-w-3xl" isPending={isProcessing}>
      <DialogHeader>
        <DialogTitle>{t("noCallList.importCSV.action")}</DialogTitle>
      </DialogHeader>
      {children}
    </DialogContent>
  )
}

function ErrorRow({ item }: { item: SkippedRow }) {
  const { t } = useTranslation()

  const errorTypes = getErrorTypes(item)
  const { email, phoneNumber, rowNumber } = item
  const row = rowNumber + 1

  const message = errorTypes
    .map((errorType) =>
      t(`noCallList.importCSV.errors.${errorType}`, { email, phoneNumber }),
    )
    .join("; ")

  return <>{t("campaigns.details.importCSV.errorRow", { message, row })}</>
}

function getErrorTypes(item: SkippedRow) {
  const errorTypes = [getEmailError(item), getPhoneNumberError(item)].filter(
    Boolean,
  )

  return errorTypes.length > 0 ? errorTypes : ["UNKNOWN_ERROR"]
}

function getEmailError(item: SkippedRow) {
  switch (item.emailValidationResult) {
    case "INVALID_EMAIL":
      return "EMAIL_INVALID"
    case "NOT_FOUND":
      return "EMAIL_NOT_FOUND"
  }
}

function getPhoneNumberError(item: SkippedRow) {
  switch (item.phoneNumberValidationResult) {
    case "DUPLICATE":
      return "PHONE_NUMBER_DUPLICATE"
    case "INVALID_PHONE_NUMBER":
      return "PHONE_NUMBER_INVALID"
    case "NOT_FOUND":
      return "PHONE_NUMBER_NOT_FOUND"
  }
}
