import { useTranslation } from "react-i18next"
import { Check, EllipsisIcon, TriangleAlertIcon } from "lucide-react"
import { z } from "zod"

import { useModal } from "@/components/core/Root/modal-root"
import { AlertLoadingError } from "@/components/ui/alert"
import { Button } from "@/components/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command"
import {
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"
import { Skeleton } from "@/components/ui/skeleton"
import { TooltipWrapper } from "@/components/ui/tooltip"
import { cn } from "@/helpers/classNames"
import { useQueryAPI } from "@/hooks/query-api"

type Props = {
  onChange: (value: string) => void
  value: string | null
}

export function QueuePicker({ onChange, value }: Props) {
  const { t } = useTranslation()
  const { showModal } = useModal()
  const { data, error, isLoading } = useFetchAvailableQueues()

  if (isLoading) {
    return (
      <div
        className={
          "flex h-[40px] w-64 items-center rounded-md border bg-neutral-100 px-3"
        }
      >
        <Skeleton className="h-4 w-full" />
      </div>
    )
  }

  if (error) {
    return (
      <AlertLoadingError
        error={error}
        title="Unable to load the list of available queues"
      />
    )
  }

  const queues = data?.data || []

  async function handleClick() {
    const selectedQueue = await showModal<string>((close) => (
      <DialogQueuePicker close={close} queues={queues} />
    ))
    if (selectedQueue) {
      onChange(selectedQueue)
    }
  }

  return (
    <Button
      variant="outline"
      onClick={handleClick}
      className="w-64 justify-between"
    >
      {value ? value : t("campaigns.create.queuePlaceholder")}
      <EllipsisIcon className="ml-2 size-5 opacity-50" />
    </Button>
  )
}

export function DialogQueuePicker({
  close,
  queues,
}: {
  close: (queue: string | null) => void
  queues: Queue[]
}) {
  const { t } = useTranslation()

  const enabledQueues = queues.filter(
    (queue) => queue.ConfigurationIssue !== "DISABLED",
  )

  return (
    <DialogContent className="max-w-2xl">
      <DialogHeader>
        <DialogTitle>{t("campaigns.create.queuePlaceholder")}</DialogTitle>
      </DialogHeader>
      <DialogBody>
        <div className="border">
          <QueueList onChange={close} queues={enabledQueues} value={null} />
        </div>
      </DialogBody>
      <DialogFooter>
        <Button onClick={() => close(null)} variant="outline">
          {t("global.button.cancel")}
        </Button>
      </DialogFooter>
    </DialogContent>
  )
}

function QueueList({
  onChange,
  queues,
  value,
}: {
  onChange: (value: string) => void
  queues: Queue[]
  value: string | null
}) {
  const { t } = useTranslation()

  function handleSelect(selectedValue: string) {
    onChange(selectedValue)
  }

  return (
    <Command>
      <CommandInput placeholder={t("global.search.action")} />
      <CommandList>
        <CommandEmpty>{t("global.search.noItemsFound")}</CommandEmpty>
        <CommandGroup>
          {queues.map((queue) => (
            <CommandItem
              key={queue.Name}
              value={queue.Name}
              onSelect={queue.ConfigurationIssue ? undefined : handleSelect}
            >
              <Check
                className={cn(
                  "mr-2 size-4",
                  value === queue.Name ? "opacity-100" : "opacity-0",
                )}
              />
              <span
                className={cn(queue.ConfigurationIssue && "italic opacity-70")}
              >
                {queue.Name}
              </span>
              {queue.ConfigurationIssue && (
                <TooltipWrapper
                  tooltip={t(
                    `campaigns.create.errors.${queue.ConfigurationIssue}`,
                  )}
                >
                  <TriangleAlertIcon className="ml-2 size-4 text-danger-300" />
                </TooltipWrapper>
              )}
            </CommandItem>
          ))}
        </CommandGroup>
      </CommandList>
    </Command>
  )
}

function useFetchAvailableQueues() {
  return useQueryAPI({
    apiEndPoint: "/campaign-filters/queues",
    params: { exclude_existing_campaigns: true },
    schema: queueListResponseSchema,
  })
}

const queueSchema = z.object({
  Name: z.string(),
  ConfigurationIssue: z.string().nullable(),
})

const queueListResponseSchema = z.object({
  data: z.array(queueSchema),
})

export type Queue = z.infer<typeof queueSchema>
