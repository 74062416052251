import { useEffect, useMemo, useState } from "react"
import axios, {
  AxiosInstance,
  AxiosInterceptorManager,
  AxiosResponse,
  CreateAxiosDefaults,
  InternalAxiosRequestConfig,
} from "axios"
import { useAtomValue } from "jotai"

import { config } from "@/config/index"
import { cognitoModeAtom } from "@/helpers/atoms"
import { isNull } from "@/helpers/typeguards"
import { useLogger } from "@/hooks/useLogger"
import { ArgumentTypes } from "@/types/tuples"

import { useRefetchTokenAndRetry } from "./interceptors"

interface AxiosClientHookType {
  axiosClient: AxiosInstance | null
}

export type RequestInterceptors = ArgumentTypes<
  AxiosInterceptorManager<InternalAxiosRequestConfig>["use"]
>

export type ResponseInterceptors = ArgumentTypes<
  AxiosInterceptorManager<AxiosResponse>["use"]
>

const baseApiUrl = `https://${config.apiEndpoint}`

const useAxiosInstanceHook = ({
  config,
  requestInterceptors,
  responseInterceptors,
}: {
  config: CreateAxiosDefaults
  requestInterceptors?: RequestInterceptors
  responseInterceptors?: ResponseInterceptors
}): AxiosClientHookType => {
  const [axiosClient, setAxiosClient] = useState<AxiosInstance | null>(null)
  const cognitoMode = useAtomValue(cognitoModeAtom)
  const { attachRetryInterceptor } = useRefetchTokenAndRetry()
  const log = useLogger()

  const accessToken = useMemo(() => {
    if (cognitoMode.current === "AUTHENTICATED") {
      return cognitoMode.state.accessToken
    } else {
      return null
    }
  }, [cognitoMode])

  useEffect(() => {
    if (isNull(accessToken)) {
      return setAxiosClient(null)
    }

    try {
      const newClient = axios.create({
        baseURL: baseApiUrl,
        withCredentials: false,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        ...config,
      })

      // https://axios-http.com/docs/interceptors
      // https://jaello-world.hashnode.dev/axios-interceptors-with-typescript
      if (requestInterceptors?.length) {
        newClient.interceptors.request.use(...requestInterceptors)
      }

      if (responseInterceptors?.length) {
        newClient.interceptors.response.use(...responseInterceptors)
      }

      attachRetryInterceptor(newClient)

      setAxiosClient(() => newClient)
    } catch (err) {
      log.error(err)
      setAxiosClient(null)
    }
  }, [accessToken]) // eslint-disable-line react-hooks/exhaustive-deps

  return { axiosClient }
}

export { useAxiosInstanceHook }
