/* eslint-disable react-refresh/only-export-components */
import { FunctionComponent, PropsWithChildren } from "react"
import { useTranslation } from "react-i18next"
import { atom, useAtom, useSetAtom } from "jotai"

import Icon from "@/components/Icon"
import { Button } from "@/components/ui/button"
import { modalModeAtom } from "@/helpers/atoms"
import { useI18n } from "@/helpers/i18n/use-translation"
import { unique } from "@/helpers/other"
import {
  type CallErrorMap,
  callErrorMap,
  type VoiceCallError,
} from "@/models/call-errors"

const voiceCallErrorAtom = atom<Array<VoiceCallError>>([])

export const useErrorToast = () => {
  const [errors, setErrors] = useAtom(voiceCallErrorAtom)

  const pushCallError = (newMessage: keyof CallErrorMap) => {
    const error = callErrorMap[newMessage]

    setErrors((prevErrors) => {
      const newErrors = unique([...prevErrors, error])

      return newErrors
    })
  }

  const onClose = (message: VoiceCallError) => {
    setErrors((errors) => {
      return errors.filter((err) => err !== message)
    })
  }

  return { pushCallError, onClose, errors }
}

const CallErrorCard: FunctionComponent<
  PropsWithChildren & { callError: VoiceCallError }
> = ({ callError, children }) => {
  const { t } = useTranslation()
  const { onClose } = useErrorToast()
  const title = t(`callPanel.callErrorCard.${callError}.title`)

  return (
    <div className="font-body-small-regular flex flex-col gap-y-2 rounded-sm border border-danger-200 bg-danger-100 px-4 py-3 text-neutral-600">
      <div className="flex flex-row flex-wrap items-center gap-x-2.5">
        <Icon name="alert" className="text-danger-300" />
        <div className="font-body-small-bold grow">{title}</div>
        <div className="cursor-pointer" onClick={() => onClose(callError)}>
          <Icon name="close" className="text-danger-200" />
        </div>
      </div>
      <>{children}</>
    </div>
  )
}

const MicPermissionButton = () => {
  const { t } = useI18n()
  const setModalMode = useSetAtom(modalModeAtom)

  return (
    <div>
      <Button
        variant="secondary"
        size="sm"
        onClick={() => setModalMode({ mode: "microphoneNotShared" })}
      >
        {t("callPanel.callErrorCard.micPermissionError.button")}
      </Button>
    </div>
  )
}

const CallErrorDescription = ({ callError }: { callError: VoiceCallError }) => {
  const { tMap } = useI18n()

  return (
    <div>
      {tMap(
        `callPanel.callErrorCard.${callError}.description`,
        (descriptionText, i) =>
          Array.isArray(descriptionText) ? (
            <ul className="list-inside list-disc" key={i}>
              {tMap(
                `callPanel.callErrorCard.${callError}.description.${i}`,
                (descSubtext, j) => (
                  <li key={j}>{descSubtext}</li>
                ),
              )}
            </ul>
          ) : (
            <p key={i}>{descriptionText}</p>
          ),
      )}
    </div>
  )
}

export const ShowErrorToast: FunctionComponent = () => {
  const { errors } = useErrorToast()

  if (!errors.length) {
    return null
  }

  return (
    <div className="mb-2 flex flex-col gap-y-2">
      {errors.map((callError, i) => (
        <CallErrorCard key={i} callError={callError}>
          <CallErrorDescription key={i} callError={callError} />
          {callError === "micPermissionError" && <MicPermissionButton />}
        </CallErrorCard>
      ))}
    </div>
  )
}
