import { CognitoUserPool, CognitoUserSession } from "amazon-cognito-identity-js"
import axios, { AxiosInstance, CreateAxiosDefaults } from "axios"

import { config as calliopeConfig } from "@/config/index"
import { hasValue } from "@/helpers/typeguards"

const userPool = new CognitoUserPool({
  UserPoolId: calliopeConfig.cognitoUserPoolId,
  ClientId: calliopeConfig.cognitoClientId,
})

const getCognitoSession = async (): Promise<CognitoUserSession> => {
  const cognitoUser = userPool.getCurrentUser()

  if (!cognitoUser) {
    throw new Error("No cognito user found")
  }

  return new Promise((resolve, reject) => {
    cognitoUser.getSession(
      (err: Error | null, credentials: CognitoUserSession | null) => {
        if (hasValue(credentials)) {
          resolve(credentials)
        } else {
          reject(err ?? new Error("No cognito session found"))
        }
      },
    )
  })
}

const getAxiosClient = async (
  config: CreateAxiosDefaults = {},
): Promise<AxiosInstance> => {
  const baseApiUrl = `https://${calliopeConfig.apiEndpoint}`
  const credentials = await getCognitoSession()
  const accessToken = credentials.getAccessToken().getJwtToken()

  const axiosClient = axios.create({
    baseURL: baseApiUrl,
    withCredentials: false,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    },
    ...config,
  })

  return axiosClient
}

export { getAxiosClient }
