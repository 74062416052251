import { formatDateDigits } from "@/helpers/dateFormat"

import { Timer } from "./hook"

export function CallPanelTimer({ timer }: { timer: Timer | null }) {
  return (
    <>
      {timer && !!timer.hours && (
        <span role="timer">{formatDateDigits(timer.hours ?? 0)}:</span>
      )}
      <span role="timer">{formatDateDigits(timer?.minutes ?? 0)}</span>
      <span>:</span>
      <span role="timer">{formatDateDigits(timer?.seconds ?? 0)}</span>
    </>
  )
}
