import { useTranslation } from "react-i18next"

import { SearchBox } from "@/components/search/search-box"

import { StudentSearchOptions } from "../api/students"
import { UniversityPicker } from "../university/university-picker"
import { StatusPicker } from "./status-picker"
import { useStudentsSearch } from "./students-search"

type Props = {
  onChange: ReturnType<typeof useStudentsSearch>["onChange"]
  searchOptions: StudentSearchOptions
}

export function StudentsSearchBar({ onChange, searchOptions }: Props) {
  const { t } = useTranslation()

  return (
    <div className="flex gap-4">
      <UniversityPicker
        values={searchOptions.universities}
        onChange={onChange("universities")}
        allowReset
        placeholder={t("campaigns.details.search.universities")}
      />
      <StatusPicker
        allowReset
        values={searchOptions.statuses}
        onChange={onChange("statuses")}
      />
      <SearchBox
        defaultValue={searchOptions.search_query || ""}
        onChange={onChange("search_query")}
      />
    </div>
  )
}
