import { PropsWithChildren } from "react"

import CallView from "@/components/CallView"
import EmptyQueueView from "@/components/EmptyQueueView"
import Loader from "@/components/layout/Loader"
import PreparingCallView from "@/components/PreparingCallView"
import ResetCallView from "@/components/ResetCallView"
import { useAgentProfile } from "@/hooks/agent-profile"
import { CallDurationProvider } from "@/hooks/callDuration"

import { CallReportsPage } from "../CallHistory/call-history-page"
import { AgentMetricsPanel } from "./agent-metrics/agent-metrics-panel"
import { useCustomPanelHook } from "./hook"

const CustomConnectPanel = () => {
  const { displayMode: agentDisplayMode } = useCustomPanelHook()

  switch (agentDisplayMode.current) {
    case "offline":
      return (
        <ConnectPanelContainer>
          <CallReportsPage />
        </ConnectPanelContainer>
      )
    case "initializing":
      return <Loader />
    case "available":
      return (
        <ConnectPanelContainer showMetricsPanel>
          <EmptyQueueView />
        </ConnectPanelContainer>
      )

    case "busy":
      return (
        <ConnectPanelContainer showMetricsPanel>
          {agentDisplayMode.state.isLoading ? (
            <PreparingCallView />
          ) : (
            <CallDurationProvider>
              <CallView
                studentProfile={agentDisplayMode.state.studentProfile}
                currentCase={agentDisplayMode.state.currentCase}
                caseStatuses={agentDisplayMode.state.caseStatuses}
              />
            </CallDurationProvider>
          )}
        </ConnectPanelContainer>
      )

    case "reset":
      return (
        <ConnectPanelContainer showMetricsPanel>
          <ResetCallView currentCase={agentDisplayMode.state.currentCase} />
        </ConnectPanelContainer>
      )

    default:
      return null
  }
}

function ConnectPanelContainer({
  children,
  showMetricsPanel,
}: PropsWithChildren<{ showMetricsPanel?: boolean }>) {
  const { agentProfile } = useAgentProfile()

  return (
    <div className="size-full">
      {showMetricsPanel && agentProfile && <AgentMetricsPanel />}
      {children}
    </div>
  )
}

export default CustomConnectPanel
