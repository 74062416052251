import { MutableRefObject } from "react"

import { getAxiosClient } from "@/components/core/AxiosInstanceProvider/get_axios_client"
import { getAgentId } from "@/helpers/agent"

type ErrorMetric =
  | "callpanel-network-error-handshake-failure"
  | "callpanel-network-error-signalling-connection"
  | "callpanel-network-error-webrtc"

const pushErrorMetric = async (
  agentRef: MutableRefObject<connect.Agent | null>,
  metricName: ErrorMetric,
) => {
  const axiosClient = await getAxiosClient()

  const agent = agentRef.current
  const agentConfig = agent?.getConfiguration()
  const connectUsername = agentConfig?.username
  const agentARN = agentConfig?.agentARN
  const connectUserId = getAgentId(agentARN)
  const contactId = getCurrentContactId(agent)

  // The API side schema is a little picky when it comes to the dimensions object; values must be strings
  const dimensions = {
    ...(connectUsername && { connect_username: connectUsername }),
  }

  const hasDimensionsToPost = Boolean(Object.keys(dimensions).length)

  const thisErrorMetricPayload = {
    metric_name: metricName,
    ...(hasDimensionsToPost && { dimensions }),
    metadata: { connect_user_id: connectUserId, contact_id: contactId },
    unit: "Count",
    increment_by: 1,
  }

  const totalErrorMetricPayload = {
    metric_name: "callpanel-network-error-total",
    metadata: {
      connect_user_id: connectUserId,
      contact_id: contactId,
      connect_username: dimensions?.connect_username,
    },
    unit: "Count",
    increment_by: 1,
  }

  return await Promise.all([
    axiosClient.post("/metrics/cloudwatch", thisErrorMetricPayload),
    axiosClient.post("/metrics/cloudwatch", totalErrorMetricPayload),
  ])
}

function getCurrentContactId(agent: connect.Agent | null): string | undefined {
  const taskId = agent?.getContacts(connect.ContactType.TASK)[0]?.getContactId()
  const voiceId = agent
    ?.getContacts(connect.ContactType.VOICE)[0]
    ?.getContactId()

  return voiceId || taskId
}

export { pushErrorMetric }
