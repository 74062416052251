/* eslint-disable react-refresh/only-export-components */
import {
  createContext,
  FunctionComponent,
  MutableRefObject,
  useContext,
  useEffect,
  useRef,
} from "react"
import { useAtomValue } from "jotai"
import invariant from "tiny-invariant"

import { inManualCallAtom } from "@/helpers/atoms"
import { ChildrenProps } from "@/types/props"

const ManualCallContext = createContext<MutableRefObject<boolean> | null>(null)

const ManualCallProvider: FunctionComponent<ChildrenProps> = ({ children }) => {
  const { inManualCallRef } = useManualCallHook()

  return (
    <ManualCallContext.Provider value={inManualCallRef}>
      {children}
    </ManualCallContext.Provider>
  )
}

function useManualCallHook() {
  // we need a ref that duplicates the atom's state to be used in the iframe event handlers
  // because these don't have access to the atom state updates
  const inManualCall = useAtomValue(inManualCallAtom)
  const inManualCallRef: MutableRefObject<boolean> = useRef(inManualCall)

  useEffect(() => {
    inManualCallRef.current = inManualCall
  }, [inManualCall])

  return { inManualCallRef }
}

function useManualCallContext() {
  const context = useContext(ManualCallContext)

  invariant(ManualCallContext, "ManualCallContext should have been initialized")

  return context
}

export { ManualCallProvider, useManualCallContext }
