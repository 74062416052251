import { AlertLoadingError } from "@/components/ui/alert"

import { useAgentData, useAgentMetricsToday } from "./agent-metrics-api"
import { AgentMetricsPanelContent } from "./agent-metrics-panel-content"
import { AgentMetricsSheet } from "./agent-metrics-sheet"

/**
 * Widget displaying agent metrics in a tiny side panel, to be included in the Call panel
 */
export function AgentMetricsPanel() {
  const { agentId, campaignIds } = useAgentData()
  if (!agentId) {
    // don't try to render anything if agent data is not available yet
    // TODO the usual problem to fix, only mount the app when agent data is loaded
    return null
  }

  return (
    <AgentMetricsSheet>
      <SheetContent agentId={agentId} campaignIds={campaignIds} />
    </AgentMetricsSheet>
  )
}

function SheetContent({
  agentId,
  campaignIds,
}: {
  agentId: string
  campaignIds: string[]
}) {
  const { data, error, isFetching, refetch } = useAgentMetricsToday({
    agentId,
    campaignIds,
  })

  if (isFetching) {
    return (
      <AgentMetricsPanelContent
        isLoading
        campaignIds={campaignIds}
        metrics={null}
        metadata={null}
      />
    )
  }
  const metrics = data?.data.metrics[0]?.metricData || null
  const metadata = data?.data.metadata || null
  const lastUpdated = data?.data.cacheDetails?.cacheSetTimestamp

  if (error) {
    return <AlertLoadingError title="Error" error={error} />
  }

  return (
    <AgentMetricsPanelContent
      campaignIds={campaignIds}
      lastUpdated={lastUpdated}
      metrics={metrics}
      metadata={metadata}
      refetch={refetch}
    />
  )
}
