import { ReplayFrameEvent } from "@sentry/react"
import type { ErrorEvent, EventHint } from "@sentry/types"

import { hasProperty, hasValue, isObject, isString } from "@/helpers/typeguards"

export const beforeSend = (event: ErrorEvent, hint: EventHint) => {
  const eventHasAxiosErrorContexts =
    hasValue(event) &&
    hasProperty("contexts", isObject)(event) &&
    hasProperty("AxiosError", isObject)(event.contexts)

  if (eventHasAxiosErrorContexts) {
    const formattedAxiosContexts = getFriendlyAxiosErrorContexts(event)
    const hasFormattedAxiosContexts = hasValue(formattedAxiosContexts)

    if (hasFormattedAxiosContexts) {
      event.contexts.AxiosError = formattedAxiosContexts
    }
  }

  const ccpLogs = getCcpLogs()
  const hasCcpLogs = hasValue(ccpLogs)

  if (hasCcpLogs) {
    hint.attachments = [
      { filename: "ccp_logs.txt", data: ccpLogs, contentType: "text/plain" },
    ]
  }

  return event
}

/**
 * Function to extract the relevant Axios error contexts from an Axios error into a one level deep Record;
 * These relevant details are then used to decorate the event that is pushed to Sentry.
 * It improves the readability of the error and makes sure we don't ignore important details hidden in deeply nested objects.
 * @param event - the Sentry error event to be processed
 * @returns
 */

function getFriendlyAxiosErrorContexts(
  event: ErrorEvent,
): Record<string, unknown> | undefined {
  let formattedContexts: Record<string, unknown> | undefined = undefined

  try {
    const hasAxiosErrorContexts =
      hasValue(event) &&
      hasProperty("contexts", isObject)(event) &&
      hasProperty("AxiosError", isObject)(event.contexts) &&
      hasProperty("name", isString)(event.contexts.AxiosError) &&
      event.contexts.AxiosError.name === "AxiosError"

    if (hasAxiosErrorContexts) {
      formattedContexts = {}

      if (hasProperty("code")(event.contexts.AxiosError)) {
        formattedContexts.code = event.contexts.AxiosError.code
      }
      if (hasProperty("message")(event.contexts.AxiosError)) {
        formattedContexts.message = event.contexts.AxiosError.message
      }
      if (hasProperty("name")(event.contexts.AxiosError)) {
        formattedContexts.name = event.contexts.AxiosError.name
      }
      if (hasProperty("stack")(event.contexts.AxiosError)) {
        formattedContexts.stack = event.contexts.AxiosError.stack
      }
      if (hasProperty("status")(event.contexts.AxiosError)) {
        formattedContexts.status = event.contexts.AxiosError.status
      }

      if (hasProperty("config", isObject)(event.contexts.AxiosError)) {
        if (hasProperty("method")(event.contexts.AxiosError.config)) {
          formattedContexts.method = event.contexts.AxiosError.config.method
        }

        if (hasProperty("params")(event.contexts.AxiosError.config)) {
          formattedContexts.params = event.contexts.AxiosError.config.params
        }

        if (hasProperty("url")(event.contexts.AxiosError.config)) {
          formattedContexts.url = event.contexts.AxiosError.config.url
        }
      }

      if (
        hasProperty("request", isObject)(event.contexts.AxiosError) &&
        hasProperty("__sentry_xhr_v3__")(event.contexts.AxiosError.request)
      ) {
        formattedContexts.request =
          event.contexts.AxiosError.request.__sentry_xhr_v3__
      }

      if (
        hasProperty("response", isObject)(event.contexts.AxiosError) &&
        hasProperty("data")(event.contexts.AxiosError.response)
      ) {
        formattedContexts.response = event.contexts.AxiosError.response.data
      }
    }
    // eslint-disable-next-line no-empty
  } catch {}

  return formattedContexts
}

function getCcpLogs(maxLength = 1000): string | undefined {
  try {
    const connectLogger = window.connect.getLog()

    // @ts-expect-error - AWS Connect Streams API, the Kinder Surprise of APIs
    const ccpLogs = (connectLogger?._logs ?? []) as Array<
      Record<string, unknown>
    >

    if (!ccpLogs.length) {
      return undefined
    }

    if (ccpLogs.length > maxLength) {
      const truncatedLogs = ccpLogs.slice(-maxLength)

      return JSON.stringify(truncatedLogs, null, 2)
    }

    return JSON.stringify(ccpLogs, null, 2)
  } catch {
    return undefined
  }
}

/**
 * Function to filters out the request and response bodies of
 * successful API requests to prevent leaking sensitive data.
 * @param event - the event to sanitize
 *
 * @returns the sanitized event if body property if found | the original event if no body property is found
 */
const sanitizeEventBody = (event: ReplayFrameEvent): ReplayFrameEvent => {
  if (
    event.data.tag === "performanceSpan" &&
    (event.data.payload.op === "resource.fetch" ||
      event.data.payload.op === "resource.xhr") &&
    event.data.payload.data?.statusCode === 200
  ) {
    if (
      hasProperty("response", isObject)(event.data.payload.data) &&
      hasProperty("body")(event.data.payload.data.response)
    ) {
      event.data.payload.data.response.body = "[Filtered]"
    }

    if (
      hasProperty("request", isObject)(event.data.payload.data) &&
      hasProperty("body")(event.data.payload.data.request)
    ) {
      event.data.payload.data.request.body = "[Filtered]"
    }
  }

  return event
}

export const beforeAddRecordingEvent = (
  event: ReplayFrameEvent,
): ReplayFrameEvent | null => {
  // Filter out bodies of successful API requests. Only log the full response body if the
  // request has failed for some reason.
  const sanitizedEvent = sanitizeEventBody(event)

  return sanitizedEvent
}
