import { Badge } from "@/components/ui/badge"
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip"

import { truncateList } from "./truncate-list"

/**
 * Given a number of characters, truncate a list of items and render the extra items in a separate element.
 * It's supposed to be used in a table cell with a fixed width. E.g. the student table in the campaign details page.
 */
export function TruncatedList({
  items,
  numberOfCharacters,
  renderExtraItems,
}: {
  items: string[]
  numberOfCharacters: number
  renderExtraItems: (extraItems: string[]) => JSX.Element | null
}) {
  const separator = ", "
  const { hiddenItems, visibleItems } = truncateList(
    items,
    numberOfCharacters,
    separator,
  )

  return (
    <>
      {visibleItems.join(separator)}
      {hiddenItems.length > 0 && <>{renderExtraItems(hiddenItems)}</>}
    </>
  )
}

export function TruncatedListWithBadge({
  items,
  numberOfCharacters,
}: {
  items: string[]
  numberOfCharacters: number
}) {
  return (
    <TruncatedList
      items={items}
      numberOfCharacters={numberOfCharacters}
      renderExtraItems={(extraItems) => (
        <Badge className="ml-1">
          <Tooltip>
            <TooltipTrigger>+{extraItems.length}</TooltipTrigger>
            <TooltipContent className="max-w-100">
              <div className="flex flex-col gap-2">
                {extraItems.map((item, index) => (
                  <p key={index} className="truncate">
                    {item}
                  </p>
                ))}
              </div>
            </TooltipContent>
          </Tooltip>
        </Badge>
      )}
    />
  )
}
