import { useTranslation } from "react-i18next"
import { format } from "date-fns"

import { useModal } from "@/components/core/Root/modal-root"
import { ColumnGroupDef, DashboardTable } from "@/components/DashboardTable"
import { EventStatusPill } from "@/components/status-pill"
import {
  CellContent,
  EmptyValue,
  TruncatedTextCell,
} from "@/components/table-helpers"
import { Button } from "@/components/ui/button"
import { formatFullName } from "@/helpers/names"
import { ActivityFeedEntry } from "@/pages/CustomConnectPanel/value"

import { DialogEditComment } from "./dialog-edit-comment"

export function ActivityTable({
  caseId,
  isLoading,
  items,
}: {
  caseId?: string
  isLoading?: boolean
  items: ActivityFeedEntry.ActivityFeedEntryType[]
}) {
  const { t } = useTranslation()

  const columnsGroups: ColumnGroupDef<ActivityFeedEntry.ActivityFeedEntryType>[] =
    [
      {
        header: t("caseHistory.activityFeed.staff"),
        cols: [
          {
            id: "staff",
            cell: (item) => {
              return (
                <CellContent
                  value={item.status_event}
                  render={(value) =>
                    formatFullName(value.first_name, value.last_name)
                  }
                />
              )
            },
          },
        ],
      },
      {
        header: t("caseHistory.activityFeed.callType.label"),
        cols: [
          {
            id: "callType",
            cell: (item) => {
              return (
                <>
                  {t(
                    `caseHistory.activityFeed.callType.values.${
                      item?.call_contact?.type || "noCall"
                    }`,
                  )}
                </>
              )
            },
          },
        ],
      },
      {
        header: t("caseHistory.activityFeed.lastUpdate"),
        cols: [
          {
            id: "lastUpdate",
            cell: (item) => {
              return (
                <CellContent
                  value={item.status_event?.created_at}
                  render={(value) =>
                    format(new Date(value), "yyyy/MM/dd HH:mm")
                  }
                />
              )
            },
          },
        ],
      },
      {
        header: t("caseHistory.activityFeed.status"),
        cols: [
          {
            id: "status",
            cell: (item) => {
              return (
                <CellContent
                  value={item.status_event?.event_name}
                  render={(value) => <EventStatusPill value={value} />}
                />
              )
            },
          },
        ],
      },
      {
        header: t("caseHistory.activityFeed.nextCall"),
        cols: [
          {
            id: "nextCall",
            cell: (item) => {
              return (
                <CellContent
                  value={item.task_contact?.scheduled_at}
                  render={(value) =>
                    format(new Date(value), "yyyy/MM/dd HH:mm")
                  }
                />
              )
            },
          },
        ],
      },
      {
        header: t("caseHistory.activityFeed.comments"),
        cols: [
          {
            id: "comments",
            className: "w-[300px]",
            cell: (item) => {
              const comment = item.comment?.comment

              return (
                <div className="flex w-full items-center justify-between">
                  {comment ? (
                    <TruncatedTextCell text={comment} numberOfChar={30} />
                  ) : (
                    <EmptyValue />
                  )}
                  {item.status_event?.group_id && caseId && (
                    <EditCommentButton
                      activityFeedEntry={item}
                      caseId={caseId}
                      groupId={item.status_event?.group_id}
                      hasComment={Boolean(comment)}
                    />
                  )}
                </div>
              )
            },
          },
        ],
      },
    ]

  return (
    <DashboardTable
      items={items}
      columnGroups={columnsGroups}
      getItemId={(item) => item?.status_event?.group_id || "-"}
      isLoading={isLoading}
    />
  )
}

function EditCommentButton({
  activityFeedEntry,
  caseId,
  groupId,
  hasComment,
}: {
  activityFeedEntry: ActivityFeedEntry.ActivityFeedEntryType
  caseId: string
  groupId: string
  hasComment: boolean
}) {
  const { t } = useTranslation()
  const { showModal } = useModal()
  const editComment = async (groupId: string) => {
    await showModal<boolean>((close) => (
      <DialogEditComment
        activityFeedEntry={activityFeedEntry}
        caseId={caseId}
        groupId={groupId}
        close={close}
      />
    ))
  }

  return (
    <Button variant="outline" size="sm" onClick={() => editComment(groupId)}>
      {hasComment ? t("global.button.edit") : t("global.button.add")}
    </Button>
  )
}
