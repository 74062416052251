import { hasValue } from "@/helpers/typeguards"

import CCPAuthError from "./CCPAuthError"
import { useErrorHook } from "./hook"
import NotFoundError from "./NotFoundError"
import RouteError from "./RouteError"

export default function ErrorPage() {
  const { ccpAuthFormattedError, formattedError } = useErrorHook()

  if (hasValue(ccpAuthFormattedError)) {
    return (
      <CCPAuthError
        errorMessage={ccpAuthFormattedError.errorMessage}
        errorDescription={ccpAuthFormattedError.errorDescription}
      />
    )
  }

  if (hasValue(formattedError)) {
    if (formattedError.errorStatus === 404) {
      return (
        <NotFoundError
          errorStatus={formattedError.errorStatus}
          errorMessage={formattedError.errorMessage}
          errorDescription={formattedError.errorDescription}
        />
      )
    } else {
      return (
        <RouteError
          errorStatus={formattedError.errorStatus}
          errorMessage={formattedError.errorMessage}
          errorDescription={formattedError.errorDescription}
        />
      )
    }
  }

  return null
}
