import { useAtomValue } from "jotai"

import { agentAtom } from "@/helpers/atoms"
import { SentryLogger } from "@/helpers/sentryLogger"

interface Logger {
  error: (error: unknown, context?: Record<string, unknown>) => void
  info: (message: string, context?: Record<string, unknown>) => void
  warn: (error: unknown, context?: Record<string, unknown>) => void
}

/**
 * Generic hook to log data or errors in the React app where the call panel internal state is not needed.
 */
const useLogger = (): Logger => {
  const sentryLogger = new SentryLogger()

  const log = {
    error: (error: unknown, context?: Record<string, unknown>) => {
      return sentryLogger.error(error, context)
    },
    warn: (error: unknown, context?: Record<string, unknown>) => {
      return sentryLogger.warn(error, context)
    },
    info: (message: string, context?: Record<string, unknown>) => {
      return sentryLogger.info(message, context)
    },
  }

  return log
}

/**
 * Hook to log data or errors where the call panel internal state is needed.
 */
const useCallPanelLogger = (): Logger => {
  const logger = useLogger()
  const agent = useAtomValue(agentAtom)

  const callPanelLogger: Logger = {
    info: (message: string, context?: Record<string, unknown>) => {
      const agentSnapshot = agent?.toSnapshot()

      return logger.info(message, { agent: agentSnapshot, ...context })
    },
    warn: (error: unknown, context?: Record<string, unknown>) => {
      const agentSnapshot = agent?.toSnapshot()

      return logger.warn(error, { agent: agentSnapshot, ...context })
    },
    error: (error: unknown, context?: Record<string, unknown>) => {
      const agentSnapshot = agent?.toSnapshot()

      return logger.error(error, { agent: agentSnapshot, ...context })
    },
  }

  return callPanelLogger
}

export { type Logger, useCallPanelLogger, useLogger }
