// see type connect.SoftphoneErrorTypes

export const callErrorMap = {
  signalling_handshake_failure: "networkError",
  signalling_connection_failure: "networkError",
  ice_collection_timeout: "networkError",
  webrtc_error: "networkError",
  realtime_communication_error: "networkError",
  unsupported_browser: "browserError",
  microphone_not_shared: "micPermissionError",
  other: "unexpectedError",
  user_busy_error: "unexpectedError",
  missed_call_agent_state: "missedCallNetworkError",
  web_socket_connection_lost: "networkError",
} as const

export type CallErrorMap = typeof callErrorMap

export type VoiceCallError = CallErrorMap[keyof CallErrorMap]
