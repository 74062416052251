import { useEffect } from "react"
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom"
import * as Sentry from "@sentry/react"
import { z } from "zod"

import { isNumber } from "@/helpers/typeguards"

export const languages = {
  english: "en",
  japanese: "ja",
} as const

export const langSchema = z.nativeEnum(languages)

export type Lang = z.infer<typeof langSchema>

const defaultLangFallback = languages.japanese

const sentryEnvironmentSchema = z.enum(["local", "development", "production"])

const isLocal = import.meta.env.DEV

const schema = z
  .object({
    VITE_CONNECT_INSTANCE_ALIAS: z.string().nonempty(),
    PROD: z.union([z.string(), z.boolean()]), // For some reason test mode returns PROD: "" although the docs this env variable is typed as boolean
    VITE_DEFAULT_LANG: langSchema.default(defaultLangFallback),
    VITE_SENTRY_DSN: z.string().optional(),
    VITE_SENTRY_ENVIRONMENT: sentryEnvironmentSchema.optional(),
    VITE_AWS_REGION: z.string().nonempty(),
    VITE_COGNITO_USER_POOL_ID: z.string().nonempty(),
    VITE_COGNITO_CLIENT_ID: z.string().nonempty(),
    VITE_API_ENDPOINT: z.string().nonempty(),
    VITE_CUSTOMER_PROFILES_DOMAIN: z.string().nonempty(),
    VITE_CONNECT_COOKIE_REFRESH_TIMEOUT_BUFFER: z.string(),
    VITE_ADMIN_ROLES: z.string(),
    VITE_EMPTY_QUEUE_OFFSET: z.string().nonempty(),
    VITE_RESET_PANEL_TIMEOUT: z.string().nonempty(),
    VITE_COGNITO_ACCESS_TOKEN_TIMEOUT_BUFFER: z.string().nonempty(),
    VITE_UPDATE_POLLING_INTERVAL: z.coerce.number().default(0), // set zero to disable the polling
    VITE_UPDATE_REPEAT_INTERVAL: z.coerce.number().default(60 * 1000),
    VITE_VERSION_NUMBER: z.string().default("0.0.0"), // set by `dev` and `build` scripts, the dummy default value is used only for test
    VITE_BUILD_DATE: z.string().default("2030-01-01T01:00:00.000"), // set by `dev` and `build` scripts, the dummy default value is used only for test
    VITE_SLOW_INITIALIZATION_THRESHOLD: z.coerce.number().default(55 * 1000), // 55 seconds
    VITE_ENABLE_MANUAL_CALL: z
      .enum(["true", "false"])
      .default("false")
      .transform((val) => val === "true"),
  })
  .transform((config) => ({
    connectInstanceAlias: config.VITE_CONNECT_INSTANCE_ALIAS,
    isProduction: config.PROD || false,
    defaultLang: config.VITE_DEFAULT_LANG,
    sentryDSN: config.VITE_SENTRY_DSN,
    sentryEnvironment: config.VITE_SENTRY_ENVIRONMENT,
    awsRegion: config.VITE_AWS_REGION,
    cognitoUserPoolId: config.VITE_COGNITO_USER_POOL_ID,
    cognitoClientId: config.VITE_COGNITO_CLIENT_ID,
    apiEndpoint: config.VITE_API_ENDPOINT,
    customerProfilesDomain: config.VITE_CUSTOMER_PROFILES_DOMAIN,
    // Transform hours into ms.
    connectCookieRefreshTimeoutBuffer:
      parseInt(config.VITE_CONNECT_COOKIE_REFRESH_TIMEOUT_BUFFER, 10) *
      60 *
      60 *
      1000,
    adminRoles: config.VITE_ADMIN_ROLES.split(",").filter(Boolean),
    emptyQueueOffset: parseInt(config.VITE_EMPTY_QUEUE_OFFSET, 10),
    resetPanelTimeout: parseInt(config.VITE_RESET_PANEL_TIMEOUT, 10),
    /**
     * Time interval, in milliseconds, for making the next scheduled refresh token request
     * before the current token expires
     * */
    accessTokenTimeoutBuffer: parseInt(
      config.VITE_COGNITO_ACCESS_TOKEN_TIMEOUT_BUFFER,
      10,
    ),
    /**
     * Time interval, in milliseconds, for checking the app update when polling to fetch `meta.json`
     */
    checkAppUpdatePollingInterval: config.VITE_UPDATE_POLLING_INTERVAL,
    /**
     * Time interval, in milliseconds, before showing again the App Update notification, when the user dismisses it
     */
    checkAppUpdateRepeatInterval: config.VITE_UPDATE_REPEAT_INTERVAL,
    /** Version number displayed by the UI and used when checking if an update is available */
    versionNumber: config.VITE_VERSION_NUMBER,
    buildDate: config.VITE_BUILD_DATE,
    slowInitializationThreshold: config.VITE_SLOW_INITIALIZATION_THRESHOLD,
    enableManualCall: config.VITE_ENABLE_MANUAL_CALL,
  }))
  .superRefine((config, ctx) => {
    const isMissingSentryConfig =
      !isLocal && (!config.sentryDSN || !config.sentryEnvironment)

    if (isMissingSentryConfig) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Missing Sentry configuration! Check your env variables.",
      })

      return
    }

    if (!isNumber(config.connectCookieRefreshTimeoutBuffer)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "VITE_CONNECT_COOKIE_REFRESH_TIMEOUT_BUFFER is not a number",
      })

      return
    }

    if (!isNumber(config.emptyQueueOffset)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "VITE_EMPTY_QUEUE_OFFSET is not a number",
      })

      return
    }

    if (!isNumber(config.resetPanelTimeout)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "VITE_RESET_PANEL_TIMEOUT is not a number",
      })

      return
    }

    if (!isNumber(config.accessTokenTimeoutBuffer)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "VITE_ACCESS_TOKEN_TIMEOUT_BUFFER is not a number",
      })

      return
    }
  })

export type Config = z.infer<typeof schema>

const getConfig = () => {
  // https://vitejs.dev/guide/env-and-mode.html#env-variables
  try {
    const config: Config = schema.parse(import.meta.env)

    return config
  } catch (error) {
    const sentryDSN = import.meta.env.VITE_SENTRY_DSN || undefined
    const sentryEnvironment =
      import.meta.env.VITE_SENTRY_ENVIRONMENT || undefined
    Sentry.init({
      dsn: sentryDSN,
      environment: sentryEnvironment,
      initialScope: { tags: { repository: "cci-call-panel" } },
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          ),
        }),
      ],
      tracesSampleRate: 1.0,
      debug: false,
    })
    throw error // sentry will capture this unhandled exception
  }
}
const config = getConfig()

export { config }
