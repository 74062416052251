import { z } from "zod"

import { useQueryAPI } from "@/hooks/query-api"
import * as connectPanelValue from "@/pages/CustomConnectPanel/value"

export const caseWithActivityFeedDataSchema = z.object({
  case: connectPanelValue.Decoder.caseSchema,
  activity_feed: z.array(
    connectPanelValue.ActivityFeedEntry.activityFeedSchema,
  ),
})

export const caseWithActivityFeedSchema = z.object({
  data: caseWithActivityFeedDataSchema,
})

export type ActivityFeedEntry = z.infer<
  typeof connectPanelValue.ActivityFeedEntry.activityFeedSchema
>

export type CaseWithActivityFeed = z.infer<
  typeof caseWithActivityFeedDataSchema
>

export function useFetchStudentCase(campaign_id: string, student_id: string) {
  return useQueryAPI({
    apiEndPoint: `/case`,
    params: { campaign_id, student_id },
    schema: caseWithActivityFeedSchema,
  })
}

export function useFetchStudentProfile(student_id: string) {
  return useQueryAPI({
    apiEndPoint: `/students`,
    params: { student_id },
    schema: z.object({ data: connectPanelValue.Decoder.studentProfileSchema }),
  })
}
