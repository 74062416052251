import {
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react"

import { isNull } from "@/helpers/typeguards"
import { formatStringValue as formatValue } from "@/helpers/valueFormat"

interface TruncatedAnswerListHook {
  formattedItems: string[]
  isOpen: boolean
  listRef: RefObject<HTMLUListElement>
  setIsOpen: Dispatch<SetStateAction<boolean>>
  showButton: boolean
}

const useTruncatedAnswerListHook = (
  items: string[],
): TruncatedAnswerListHook => {
  const [isOpen, setIsOpen] = useState(false)
  const [showButton, setShowButton] = useState(false)
  const listRef = useRef<HTMLUListElement>(null)
  const formattedItems = items.filter(Boolean).map((item) => formatValue(item))
  const joinedText = formattedItems.join()
  const maxHeight = 60 // 3 x 20px (line height)

  useEffect(() => {
    if (isNull(listRef.current)) {
      return
    }

    const scrollHeight = listRef.current.scrollHeight

    if (scrollHeight > maxHeight) {
      setShowButton(true)
    }
  }, [joinedText, maxHeight])

  return {
    listRef,
    formattedItems,
    showButton,
    isOpen,
    setIsOpen,
  }
}

export { useTruncatedAnswerListHook }
