import { z } from "zod"

export const skippedRowSchema = z.object({
  email: z.string(),
  phoneNumber: z.string(),
  skippedReason: z.string().optional(),
  emailValidationResult: z.string().nullish(),
  phoneNumberValidationResult: z.string().nullish(),
  rowNumber: z.number(),
})

export type SkippedRow = z.infer<typeof skippedRowSchema>

export const uploadResponseDataSchema = z.object({
  total_row_count: z.number(),
  skipped_rows: z.array(skippedRowSchema),
  updated_students: z.array(z.string()), // student ids
})

export type UploadResponseData = z.infer<typeof uploadResponseDataSchema>

export const uploadResponseSuccessSchema = z.object({
  data: uploadResponseDataSchema,
  success: z.string(),
  reqId: z.string(),
})

export type UploadSuccessResponse = z.infer<typeof uploadResponseSuccessSchema>

const uploadErrorSchema = z.object({
  error_type: z.string(),
})

export type UploadGlobalError = z.infer<typeof uploadErrorSchema>

export const uploadResponseErrorSchema = z.object({
  data: uploadErrorSchema,
  // TODO handle `invalid_data` from the API?
})

export type UploadErrorResponse = z.infer<typeof uploadResponseErrorSchema>
