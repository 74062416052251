import { SearchBox } from "@/components/search/search-box"

import { useNoCallListSearchOptions } from "./search-options"

type Props = {
  searchState: ReturnType<typeof useNoCallListSearchOptions>
}

export function NoCallListSearchBar({ searchState }: Props) {
  const { onChange, searchOptions } = searchState

  return (
    <SearchBox
      defaultValue={searchOptions.search_query || ""}
      onChange={onChange("search_query")}
    />
  )
}
