import { useTranslation } from "react-i18next"
import { format } from "date-fns"

import { DataTable } from "@/components/ui/data-table"

import { useFetchTasks } from "./tasks-api"
import { useTasksColumns } from "./tasks-columns"

export function TasksRenewalPage() {
  const { t } = useTranslation()

  const { data, isLoading } = useFetchTasks()

  const campaigns = data?.data.campaignData ?? []
  const columns = useTasksColumns(data?.data.dollarCostPerTask ?? 0)
  const lastUpdate = data?.data.cacheDetails.cacheSetTimestamp

  return (
    <main className="flex h-full flex-col gap-y-4 px-8 pt-10">
      <div className="container flex flex-col gap-8">
        <div className="flex items-center justify-between">
          <h1 className="font-h2-bold text-neutral-700">
            {t("taskRenewal.heading")}
          </h1>
          <div className="text-muted-foreground">
            {lastUpdate ? (
              <>
                {t("campaigns.details.lastUpdate")}{" "}
                {format(lastUpdate, "yyyy/MM/dd HH:mm")}
              </>
            ) : (
              "--"
            )}
          </div>
        </div>
        <DataTable
          columns={columns}
          data={campaigns}
          isLoading={isLoading}
          size="sm"
        />
      </div>
    </main>
  )
}
