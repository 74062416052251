import { createColumnHelper } from "@tanstack/react-table"
import { t } from "i18next"

import { ToggleSortButton } from "@/components/DashboardTable"
import { EmptyValue, TableLink } from "@/components/table-helpers"
import { cn } from "@/helpers/classNames"

import { CampaignItem } from "./tasks-api"

const TASK_DURATION_IN_DAYS = 7

const columnHelper = createColumnHelper<CampaignItem>()

export function useTasksColumns(dollarCostPerTask: number) {
  const columns = [
    columnHelper.accessor("campaignId", {
      header: t("campaigns.list.name"),
      cell: ({ getValue }) => (
        <TableLink to={`/campaigns/${getValue()}`}>{getValue()}</TableLink>
      ),
    }),
    ...[0, 1, 2, 3, 4, 5, 6].map((index) =>
      getRemainingDaysColumn(index, dollarCostPerTask),
    ),
  ]

  return columns
}

function getRemainingDaysColumn(
  taskAgeIndex: number,
  dollarCostPerTask: number,
) {
  return columnHelper.accessor((item) => item.taskAgeData[taskAgeIndex], {
    id: `taskAgeData-${taskAgeIndex}`,
    header: ({ column }) => {
      const oneDayLeft = taskAgeIndex === 6
      const remainingDays = TASK_DURATION_IN_DAYS - taskAgeIndex

      return (
        <div className="flex w-20 items-center justify-end">
          {oneDayLeft
            ? t("taskRenewal.oneDay")
            : t("taskRenewal.days", { days: remainingDays })}
          <ToggleSortButton
            direction={column.getIsSorted() || undefined}
            onClick={() =>
              column.toggleSorting(column.getIsSorted() !== "desc")
            }
          />
        </div>
      )
    },
    cell: ({ getValue }) => {
      const tasks = getValue()
      const oneDayLeft = taskAgeIndex === 6

      return (
        <div className="flex min-h-10 w-20 items-center justify-end text-right">
          {tasks ? (
            <div className={cn(oneDayLeft && "text-danger-300")}>
              {t("taskRenewal.tasks", { tasks })}
              <div
                className={cn(
                  oneDayLeft ? "text-danger-200" : "text-muted-foreground",
                )}
              >
                {formatAmount(tasks * dollarCostPerTask)}
              </div>
            </div>
          ) : (
            <EmptyValue />
          )}
        </div>
      )
    },
    size: 100,
  })
}

function formatAmount(amount: number) {
  return `$ ${amount.toFixed(2)}`
}
