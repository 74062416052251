import { useState } from "react"
import { ButtonProps } from "react-day-picker"
import { useTranslation } from "react-i18next"
import { useCopyToClipboard, useTimeoutFn } from "react-use"
import { CopyIcon } from "lucide-react"

import { Button } from "@/components/ui/button"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip"
import { cn } from "@/helpers/classNames"

const delay = 2_000 // show "Copied!" message for 2 seconds before resetting

type Props = ButtonProps & {
  uuid: string
}

/**
 * A button to display a long UUID, showing a "Copy" icon on the right when hovered
 * When clicked the ID is copied to the clipboard and the button shows "Copied" instead of the UUID.
 */
export function CopyIdButton({ className, uuid, ...rest }: Props) {
  const { t } = useTranslation()
  const [showCopied, setShowCopied] = useState(false)
  const [, copyToClipboard] = useCopyToClipboard()
  const [, , resetCopiedState] = useTimeoutFn(
    () => setShowCopied(false),
    showCopied ? delay : undefined,
  )

  const handleCopy = () => {
    copyToClipboard(uuid)
    setShowCopied(true)
    resetCopiedState()
  }

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="ghost"
            {...rest}
            className={cn(
              "hover:bg-transparent group h-8 w-full max-w-md justify-between gap-2 overflow-hidden p-0 text-sm font-normal",
              className,
            )}
            onClick={handleCopy}
          >
            <span className="truncate" data-sentry-unmask>
              {showCopied ? t("global.button.copied") : uuid}
            </span>
            {!showCopied && (
              <CopyIcon className="size-4 shrink-0 opacity-0 transition-opacity group-hover:opacity-100" />
            )}
          </Button>
        </TooltipTrigger>
        <TooltipContent>{t("global.button.clickToCopy")}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
