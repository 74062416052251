type Meta = {
  date: string
  version: string
}

const debug = import.meta.env.VITE_DEBUG === "1" // start the app with `VITE_DEBUG=1 pnpm dev` to enable debug logs, when working on the feature

type Options = {
  buildDate: string
  pollingInterval: number
  repeatInterval: number
  versionNumber: string
}

export class AppUpdateChecker {
  callback?: () => void
  pollingInterval: number
  repeatInterval: number
  initialMeta: Meta | null
  timerRef?: number

  constructor({
    buildDate,
    pollingInterval,
    repeatInterval,
    versionNumber,
  }: Options) {
    this.initialMeta = { date: buildDate, version: versionNumber }
    this.pollingInterval =
      debug && pollingInterval > 0
        ? 1000 * 10 // 10 seconds when debugging!
        : pollingInterval
    this.repeatInterval = repeatInterval
  }

  async init(callback?: () => void) {
    if (this.pollingInterval === 0) {
      return log("Feature disabled")
    }
    this.callback = callback
    document.addEventListener("visibilitychange", this.handleVisibilityChange)
    this.start()
  }

  start() {
    log(`Start checking every ${this.pollingInterval / 1000}s.`)
    this.timerRef = window.setInterval(() => {
      this.checkForUpdate()
    }, this.pollingInterval)
  }

  stop() {
    clearInterval(this.timerRef)
  }

  skip() {
    log(`Skip update, ask again in ${this.repeatInterval / 1000}s.`)
    this.timerRef = window.setInterval(() => {
      this.notifyUpdateIsAvailable()
    }, this.repeatInterval)
  }

  async checkForUpdate() {
    const latestMeta = await this.fetchMeta()
    if (!latestMeta) return false
    if (
      latestMeta.date !== this.initialMeta?.date ||
      latestMeta.version !== this.initialMeta?.version
    ) {
      log(
        "New version available!",
        latestMeta.date,
        latestMeta.version,
        this.initialMeta,
      )
      this.notifyUpdateIsAvailable()
    }
  }

  notifyUpdateIsAvailable() {
    this.stop()
    this.callback?.()
  }

  async fetchMeta() {
    try {
      const meta = await fetch("/meta.json").then((response) => response.json())
      log("Latest version", meta.date, meta.version)

      return meta as Meta
    } catch (error) {
      log("Failed to fetch meta.json", error)

      return null
    }
  }

  handleVisibilityChange = () => {
    if (document.hidden) {
      log("Stop polling")
      this.stop()
    } else {
      // Check immediately when the page becomes visible/active, before restarting polling
      log("Check and restart polling")
      this.checkForUpdate()
      this.start()
    }
  }

  destroy() {
    log("Destroy")
    if (this.pollingInterval === 0) return
    this.stop()
    document.removeEventListener(
      "visibilitychange",
      this.handleVisibilityChange,
    )
  }
}

function log(...args: unknown[]) {
  // eslint-disable-next-line no-console
  console.log("[UPDATE CHECKER]", ...args)
}
