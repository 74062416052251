import { Fragment } from "react"
import { useTranslation } from "react-i18next"

import { TruncatedListWithBadge } from "@/components/TruncatedList"
import { Badge } from "@/components/ui/badge"
import { Skeleton } from "@/components/ui/skeleton"
import {
  Table,
  TableCell,
  TableHead,
  TableSubHead,
} from "@/components/ui/table"
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip"
import { cn } from "@/helpers/classNames"
import { times } from "@/helpers/other"

import { Student } from "../api/students"

const MAX_NUMBER_OF_QUESTIONS = 5 // limit the number of QA by student to keep the table readable

type ColumnDef<T> = {
  cell: (item: T) => JSX.Element
  className?: string
  header?: string
  id: string
}

type Props = {
  studentsByChannel: Record<string, Student[]>
}

export function StudentTable({ studentsByChannel }: Props) {
  const cols = useColumns()
  const numberOfColumns = cols.length

  return (
    <div className="overflow-x-auto border border-neutral-300">
      <Table className="table-fixed">
        <thead>
          <tr>
            {cols.map((col) => (
              <TableHead key={col.id} className={col.className}>
                {col.header}
              </TableHead>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.entries(studentsByChannel).map(([channelId, students]) => (
            <Fragment key={channelId}>
              <tr className="table-thead-row">
                <TableSubHead colSpan={numberOfColumns}>
                  {channelId}
                </TableSubHead>
              </tr>
              {students
                .map((student) =>
                  filterStudentQuestionsAnswersByChannel(student, channelId),
                )
                .map((student) => (
                  <tr key={student._id}>
                    {cols.map((col) => (
                      <TableCell
                        key={col.id}
                        className={cn("truncate", col.className)}
                      >
                        {col.cell(student)}
                      </TableCell>
                    ))}
                  </tr>
                ))}
              {students.length === 0 && (
                <tr>
                  <TableCell colSpan={cols.length}>
                    <div className="text-xs italic text-muted-foreground">
                      —
                    </div>
                  </TableCell>
                </tr>
              )}
            </Fragment>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

/**
 * Update the `student` passed to the view to only include questions and answers for the specified channel
 * TODO: this logic should be moved to the backend as the API returns students categorized by channel
 */
function filterStudentQuestionsAnswersByChannel(
  student: Student,
  channel_title: string,
): Student {
  const ALL_STUDENTS_CHANNEL = "全てのチャンネル"
  if (channel_title === ALL_STUDENTS_CHANNEL) return student

  return {
    ...student,
    questions_and_answers: student.questions_and_answers.filter(
      (qa) => qa.channel_title === channel_title,
    ),
  }
}

function useColumns() {
  const { t } = useTranslation()
  const cols: ColumnDef<Student>[] = [
    {
      header: t("campaigns.preview.table.lastName"),
      id: "lastName",
      className: "w-32",
      cell: (student) => <>{student.last_name}</>,
    },
    {
      header: t("campaigns.preview.table.firstName"),
      id: "firstName",
      className: "w-32",
      cell: (student) => <>{student.first_name}</>,
    },
    {
      header: t("campaigns.preview.table.phoneNumber"),
      id: "phoneNumber",
      className: "w-48",
      cell: (student) => <>{student.phone_number}</>,
    },
    {
      header: t("campaigns.preview.table.channels"),
      id: "questions",
      className: "w-48",
      cell: (student) => {
        const allQuestions = student.questions_and_answers.flatMap((qa) =>
          qa.questions.map((item) => item.question),
        )

        const questions = allQuestions.slice(0, MAX_NUMBER_OF_QUESTIONS)

        const numberOfHiddenQuestions =
          allQuestions.length - MAX_NUMBER_OF_QUESTIONS

        return (
          <div className="flex flex-col gap-2">
            {questions.map((question, index) => (
              <p key={index} className="truncate">
                {question}
              </p>
            ))}
            {numberOfHiddenQuestions > 0 && (
              <div>
                <Tooltip>
                  <TooltipTrigger>
                    <Badge>+{numberOfHiddenQuestions}</Badge>
                  </TooltipTrigger>
                  <TooltipContent side="bottom">
                    <div className="flex flex-col gap-2">
                      {allQuestions
                        .slice(MAX_NUMBER_OF_QUESTIONS)
                        .map((question, index) => (
                          <p key={index} className="truncate">
                            {question}
                          </p>
                        ))}
                    </div>
                  </TooltipContent>
                </Tooltip>
              </div>
            )}
          </div>
        )
      },
    },
    {
      header: "",
      id: "answers",
      className: "w-80 align-top",
      cell: (student) => {
        const answerGroups = student.questions_and_answers
          .flatMap((qa) => qa.questions.map((item) => item.answers))
          .slice(0, MAX_NUMBER_OF_QUESTIONS)

        return (
          <div className="flex flex-col gap-2">
            {answerGroups.map((answerGroup, index) => (
              <div key={index} className="w-64">
                <TruncatedListWithBadge
                  items={answerGroup}
                  numberOfCharacters={18}
                />
              </div>
            ))}
          </div>
        )
      },
    },
    {
      header: t("campaigns.preview.table.university"),
      id: "university",
      className: "w-48",
      cell: (student) => <>{student.university}</>,
    },
    {
      header: t("campaigns.preview.table.graduationYear"),
      id: "graduationYear",
      className: "w-32",
      cell: (student) => <>{student.graduation_year}</>,
    },
    {
      header: t("campaigns.preview.table.major"),
      id: "major",
      className: "w-48",
      cell: (student) => <>{student.major}</>,
    },
    {
      header: t("campaigns.preview.table.gender"),
      id: "gender",
      className: "w-32",
      cell: (student) => <>{student.gender}</>,
    },
  ]

  return cols
}

export function LoadingStudentTable() {
  const cols = useColumns()
  const numberOfRows = 5

  return (
    <div className="overflow-x-auto border border-neutral-300">
      <Table className="table-fixed">
        <thead>
          <tr>
            {cols.map((col) => (
              <TableHead key={col.id} className={col.className}>
                {col.header}
              </TableHead>
            ))}
          </tr>
        </thead>
        <tbody>
          {times(numberOfRows).map((index) => (
            <tr key={index}>
              {cols.map((col) => (
                <TableCell key={col.id} className={col.className}>
                  <Skeleton className="h-4 w-[120px]" />
                </TableCell>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}
