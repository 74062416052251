import React from "react"

import Icon from "@/components/Icon"
import { Card } from "@/components/ui/card"
import { formatPhoneNumber } from "@/helpers/phoneNumber"

import { CallPanelTimer } from "./callpanel-timer"
import { Timer } from "./hook"

type Props = {
  buttons: React.ReactNode
  campaignName: string
  error?: React.ReactNode
  phoneNumber: string | null
  status?: React.ReactNode
  timer?: Timer | null
}

export function CallPanelCard({
  buttons,
  campaignName,
  error,
  phoneNumber,
  status,
  timer,
}: Props) {
  return (
    <Card className="rounded-sm">
      <div className="flex flex-col gap-6 px-4 py-6">
        <div className="font-body-large-bold flex flex-col gap-y-2 text-neutral-600">
          <p data-sentry-unmask>{campaignName}</p>
          <div className="flex flex-wrap items-center justify-between">
            <div className="font-body-medium-bold flex flex-wrap items-center gap-x-2">
              <p className="font-body-large-regular">
                {formatPhoneNumber(phoneNumber)}
              </p>
              {status}
            </div>
            <div
              className="font-body-large-regular flex items-center gap-x-1"
              data-sentry-unmask
            >
              {timer && <CallPanelTimer timer={timer} />}
            </div>
            {error && (
              <div
                className="font-h4-bold mt-4 flex w-full items-center gap-x-1 rounded border border-danger-300 bg-danger-200 px-4 py-6 text-neutral-100"
                data-sentry-unmask
              >
                <Icon name="alert" size="sm" />
                <h4 data-sentry-unmask>{error}</h4>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-wrap gap-2">{buttons}</div>
      </div>
    </Card>
  )
}
