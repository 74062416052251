import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"

import CallReportForm from "@/components/ACWForm"
import CallPanel from "@/components/CallPanel"
import ProfileView from "@/components/ContactProfileView"
import * as connectPanelValue from "@/pages/CustomConnectPanel/value"

import { useACWButtonsHook } from "../CallPanel/ACWButtons/hook"
import { useCallPanelHook } from "../CallPanel/hook"
import { useCallViewHook } from "./hook"

interface Props {
  caseStatuses: connectPanelValue.Decoder.CaseStatuses | null
  currentCase: connectPanelValue.Decoder.CaseAndActivityFeeds | null
  studentProfile: connectPanelValue.Decoder.StudentProfile | null
}

export type CallReportModeType =
  | "add-status"
  | "automatic-call-mode"
  | "manual-call-mode"
  | "skip-call"

const IdContainer: FunctionComponent<{
  studentId: string
  taskId: string
  voiceId: string
}> = ({ studentId, taskId, voiceId }) => {
  const { t } = useTranslation()

  const styles = {
    uuidContainer:
      "row-start-1 col-span-full h-fit px-4 pt-3 pb-1 font-label-small text-neutral-500 flex gap-x-2 items-center flex-wrap",
    uuid: "flex gap-x-1 items-center",
  }

  return (
    <div className={styles.uuidContainer} data-sentry-unmask>
      <div className={styles.uuid}>
        <span>{t("ids.taskId")}</span>
        <span>{taskId}</span>
      </div>
      <div className={styles.uuid}>
        <span>{t("ids.voiceId")}</span>
        <span>{voiceId}</span>
      </div>
      <div className={styles.uuid}>
        <span>{t("ids.uuid")}</span>
        <span>{studentId}</span>
      </div>
    </div>
  )
}

const CallView: FunctionComponent<Props> = ({
  caseStatuses,
  currentCase,
  studentProfile,
}) => {
  const {
    form,
    formattedTaskId,
    formattedVoiceId,
    historyItem,
    setHistoryItem,
    studentId,
  } = useCallViewHook({
    currentCase,
    studentProfile,
  })

  const callPanelState = useCallPanelHook({
    campaignName: currentCase?.case?.campaign_id || "",
    phoneNumber: studentProfile?.phone_number,
  })

  const callReportMode =
    callPanelState.displayMode.current === "connected-task"
      ? callPanelState.displayMode.state.callReportMode
      : "automatic-call-mode"

  const acwButtonState = useACWButtonsHook({
    currentCase,
    isInboundCall:
      callPanelState.displayMode.current === "acw" &&
      callPanelState.displayMode.state.isInboundCall,
    timer: callPanelState.timer,
  })

  return (
    <div
      className="grid size-full grid-cols-[3fr_2fr] gap-x-6 gap-y-2 px-12 pb-10 lg:grid-rows-[repeat(3,max-content)] lg:px-34.5"
      data-testid="call-view"
    >
      <IdContainer
        taskId={formattedTaskId}
        voiceId={formattedVoiceId}
        studentId={studentId}
      />
      <ProfileView
        currentCase={currentCase}
        studentProfile={studentProfile}
        historyItem={historyItem}
      />
      <CallPanel
        acwButtonState={acwButtonState}
        callPanelState={callPanelState}
        currentCase={currentCase}
        historyItem={historyItem}
        setHistoryItem={setHistoryItem}
      />
      <CallReportForm
        className="rounded border border-neutral-300 bg-neutral-100 px-4 py-6 shadow-card"
        caseStatuses={caseStatuses}
        form={form}
        historyItem={historyItem}
        setHistoryItem={setHistoryItem}
        currentCase={currentCase}
        callReportMode={callReportMode}
      />
    </div>
  )
}

export default CallView
