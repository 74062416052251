import * as React from "react"
import * as DialogPrimitive from "@radix-ui/react-dialog"
import { cva } from "class-variance-authority"
import { X } from "lucide-react"

import { ErrorCircleIcon, SuccessCircleIcon } from "@/components/Icon"
import { cn } from "@/helpers/classNames"

const Dialog = DialogPrimitive.Root

const DialogTrigger = DialogPrimitive.Trigger

const DialogPortal = DialogPrimitive.Portal

const DialogClose = DialogPrimitive.Close

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      "fixed inset-0 z-50 bg-black/80  data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
      className,
    )}
    {...props}
  />
))
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName

type DialogContentSize = "2xl" | "3xl" | "4xl" | "lg" | "xl"

const dialogContentVariants = cva(
  "fixed left-1/2 top-1/2 z-50 grid w-full -translate-x-1/2 -translate-y-1/2 gap-0 border bg-background shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg",
  {
    variants: {
      size: {
        lg: "max-w-lg",
        xl: "max-w-xl",
        "2xl": "max-w-2xl",
        "3xl": "max-w-3xl",
        "4xl": "max-w-4xl",
      },
    },
    defaultVariants: {
      size: "lg",
    },
  },
)

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & {
    isPending?: boolean
    size?: DialogContentSize
  }
>(({ children, className, isPending, size = "lg", ...props }, ref) => {
  // Prevent the user from closing the dialog that is doing some work
  const onEscapeKeyDown = (event: KeyboardEvent) => {
    if (isPending) {
      event.preventDefault()
    }
  }
  const onInteractOutside: DialogPrimitive.DialogContentProps["onInteractOutside"] =
    (event) => {
      if (isPending) {
        event.preventDefault()
      }
    }

  return (
    <DialogPortal>
      <DialogOverlay />
      <DialogPrimitive.Content
        ref={ref}
        className={cn(dialogContentVariants({ size }), className)}
        onEscapeKeyDown={onEscapeKeyDown}
        onInteractOutside={onInteractOutside}
        aria-describedby={undefined}
        {...props}
      >
        {children}
        {!isPending && (
          <DialogPrimitive.Close className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
            <X className="size-4" />
            <span className="sr-only">Close</span>
          </DialogPrimitive.Close>
        )}
      </DialogPrimitive.Content>
    </DialogPortal>
  )
})
DialogContent.displayName = DialogPrimitive.Content.displayName

const DialogHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "flex flex-col space-y-1.5 p-6 text-center sm:text-left",
      className,
    )}
    {...props}
  />
)
DialogHeader.displayName = "DialogHeader"

export const DialogBody = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn("font-body-medium-regular grow px-6 pb-6", className)}
    {...props}
  />
)

const DialogFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      [
        "flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2",
        "flex items-center justify-end gap-x-2 bg-neutral-200 px-4 py-2",
        "sm:rounded-b-lg",
      ],
      className,
    )}
    {...props}
  />
)
DialogFooter.displayName = "DialogFooter"

type DialogTitleVariant = "h2" | "lg"

const dialogTitleVariants = cva("leading-none tracking-tight", {
  variants: {
    variant: {
      lg: "text-lg font-semibold",
      h2: "font-h2-bold",
    },
  },
  defaultVariants: {
    variant: "lg",
  },
})

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title> & {
    variant?: DialogTitleVariant
  }
>(({ className, variant = "lg", ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cn(dialogTitleVariants({ variant }), className)}
    {...props}
  />
))
DialogTitle.displayName = DialogPrimitive.Title.displayName

const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    className={cn("text-sm text-muted-foreground", className)}
    {...props}
  />
))
DialogDescription.displayName = DialogPrimitive.Description.displayName

/** To show successful processing inside a modal window */
export function DialogBodySuccess({ children }: React.PropsWithChildren) {
  return (
    <DialogBodyWithIcon icon={<SuccessCircleIcon />}>
      {children}
    </DialogBodyWithIcon>
  )
}

/** To show errors when running async processes inside a modal window */
export function DialogBodyError({ children }: React.PropsWithChildren) {
  return (
    <DialogBodyWithIcon icon={<ErrorCircleIcon />}>
      {children}
    </DialogBodyWithIcon>
  )
}

export function DialogBodyWithIcon({
  children,
  icon,
}: React.PropsWithChildren<{ icon: React.ReactNode }>) {
  return (
    <DialogBody>
      <div className="flex flex-row gap-3">
        {icon}
        <div>{children}</div>
      </div>
    </DialogBody>
  )
}

export {
  Dialog,
  DialogClose,
  DialogContent,
  type DialogContentSize,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
  type DialogTitleVariant,
  DialogTrigger,
}
