import { useLocation, useSearchParams } from "react-router-dom"
import { z } from "zod"

const DEFAULT_PAGE_SIZE = 20

export type PaginationState = {
  page: number
  pageSize: number
  total: number
}

export type PaginationParams = Omit<PaginationState, "total">

/**
 * Read `page` and `limit` (the number of items per page) query parameters from the URL
 */
export function usePaginationParams(
  defaultPageSize: number = DEFAULT_PAGE_SIZE,
) {
  const [searchParams] = useSearchParams()

  const paginationSchema = z.object({
    page: z.coerce.number().default(1),
    pageSize: z.coerce.number().default(defaultPageSize),
  })

  const { page, pageSize } = paginationSchema.parse({
    pageSize: searchParams.get("limit") || undefined,
    page: searchParams.get("page") || undefined,
  })

  return { page, pageSize }
}

export function usePagination({ page, pageSize, total }: PaginationState) {
  const location = useLocation()

  const [searchParams] = useSearchParams()

  const hasPreviousPage = page > 1
  const hasNextPage = total > page * pageSize

  function getNextPagePath() {
    return setPage(page + 1)
  }
  function getPreviousPagePath() {
    return setPage(page - 1)
  }

  function setPage(pageNumber: number) {
    searchParams.set("page", pageNumber.toString())

    return getURL()
  }

  function getURL() {
    return location.pathname + "?" + searchParams.toString()
  }

  return {
    pageSize,
    page,
    hasPreviousPage,
    hasNextPage,
    getNextPagePath,
    getPreviousPagePath,
    total,
  }
}
