import { useAtom, useAtomValue } from "jotai"

import {
  agentAtom,
  currentContactIdAtom,
  isMutedAtom,
  isOnHoldAtom,
} from "@/helpers/atoms"
import { connectErrorHandler, toError } from "@/helpers/error"
import { SentryLogger } from "@/helpers/sentryLogger"
import { hasValue } from "@/helpers/typeguards"

interface ConnectActionsHook {
  acceptTask: () => Promise<connect.Contact>
  answerPhoneCall: () => Promise<string>
  callPhoneNumber: (phoneNumber: string) => Promise<string>
  closeContact: () => Promise<string>
  closeTask: () => Promise<string>
  endTask: () => Promise<string>
  getCurrentTask: () => connect.Contact
  getCurrentVoiceContact: () => connect.Contact
  getQueueARN: () => string
  getVoiceContactConnection: (
    connectionType: "agent" | "contact",
  ) => connect.BaseConnection
  hangUpPhoneCall: () => Promise<string>
  isMuted: boolean
  isOnHold: boolean
  manualCallPhoneNumber: (
    phonenumber: string,
    queueARN: string,
  ) => Promise<string>
  rejectCall: () => Promise<string>
  rejectTask: () => Promise<string>
  safeGetCurrentVoiceContact: () => connect.Contact | null
  toggleAgentStatus: ({
    enqueueNextState,
    newStateType,
  }: {
    enqueueNextState?: boolean
    newStateType: connect.AgentStateType
  }) => Promise<string>
  toggleHoldCall: () => Promise<string>
  toggleMuteCall: VoidFunction
}

const log = new SentryLogger()

export const logMultipleTasks = (
  agent: connect.Agent,
  contacts: connect.Contact[],
) => {
  const agentUsername = agent.getConfiguration().username

  const caseDetails = contacts.map((contact) => {
    const attributes = contact.getAttributes()

    const taskId = contact.getContactId()
    const customerProfileId = attributes?.customer_profile_id?.value
    const caseId = attributes?.case_id?.value

    return { customerProfileId, caseId, taskId }
  })

  const infoMessage = `Multiple tasks at a time were found for the agent ${agentUsername}. Tasks' details: ${JSON.stringify(
    caseDetails,
  )}`

  log.warn(infoMessage, { agent: agent.toSnapshot() })
}

const useConnectActionsHook = (): ConnectActionsHook => {
  const agent = useAtomValue(agentAtom)
  const currentContactId = useAtomValue(currentContactIdAtom)
  const [isMuted, setIsMuted] = useAtom(isMutedAtom)
  const isOnHold = useAtomValue(isOnHoldAtom)

  const getCurrentTask = (): connect.Contact => {
    if (!agent) {
      throw new Error("Agent is not found")
    }

    const contacts = agent.getContacts(connect.ContactType.TASK)

    if (!contacts.length) {
      throw new Error("No incoming task found")
    }

    if (contacts.length > 1) {
      logMultipleTasks(agent, contacts)
    }

    const task = contacts[0]

    return task
  }

  const getCurrentVoiceContact = (): connect.Contact => {
    if (!agent) {
      throw new Error("Agent is not found")
    }

    const contacts = agent.getContacts(connect.ContactType.VOICE)

    if (!contacts.length) {
      throw new Error("No voice contact found")
    }

    // Raw outbound calls (no task associated) will have not set a contactId in state
    const matchingContact = contacts.find(
      (contact) => contact.contactId === currentContactId,
    )

    return matchingContact || contacts[0]
  }

  const safeGetCurrentVoiceContact = (): connect.Contact | null => {
    try {
      return getCurrentVoiceContact()
    } catch (error) {
      return null
    }
  }

  const getVoiceContactConnection = (
    connectionType: "agent" | "contact",
  ): connect.BaseConnection => {
    if (!agent) {
      throw new Error("Agent is not defined")
    }

    const contacts = agent.getContacts(connect.ContactType.VOICE)

    if (!contacts.length) {
      throw new Error("No contacts found")
    }

    const contact = contacts[0]

    const activeConnection =
      connectionType === "agent"
        ? contact?.getAgentConnection()
        : contact?.getInitialConnection()

    if (!activeConnection) {
      throw new Error("No Active Connections")
    }

    return activeConnection
  }

  const toggleAgentStatus = ({
    enqueueNextState = false,
    newStateType,
  }: {
    enqueueNextState?: boolean
    newStateType: connect.AgentStateType
  }): Promise<string> => {
    if (!agent) {
      throw new Error("Agent is not defined")
    }

    const currentState = agent.getState()
    const nextState = agent.getNextState()
    const agentStates = agent.getAgentStates()
    const newState = agentStates.find((state) => state.type === newStateType)

    if (!newState) {
      throw new Error(`New agent state ${newStateType} is not found`)
    }

    if (currentState.agentStateARN === newState.agentStateARN) {
      return Promise.resolve(`Agent is already in state: ${newStateType}`)
    }

    // Edge case: Going offline while queued next state is offline
    if (
      hasValue(nextState) &&
      nextState.type === connect.AgentStateType.OFFLINE
    ) {
      return Promise.resolve("Success")
    }

    return new Promise((resolve, reject) => {
      agent.setState(
        newState,
        {
          success: () => resolve(`Success: ${newStateType}`),
          failure: (err) =>
            reject(connectErrorHandler(err, "Failure to toggle agent status")),
        },
        enqueueNextState ? { enqueueNextState } : undefined,
      )
    })
  }

  const rejectTask = (): Promise<string> => {
    const task = getCurrentTask()

    return new Promise((resolve, reject) => {
      task.reject({
        success: () => {
          resolve("Success rejecting task")
        },
        failure: (err) =>
          reject(connectErrorHandler(err, "Failure to reject task")),
      })
    })
  }

  const acceptTask = (): Promise<connect.Contact> => {
    const task = getCurrentTask()
    const taskStateType = task.getStatus().type

    if (taskStateType === connect.ContactStateType.CONNECTED) {
      return Promise.resolve(task)
    }

    return new Promise((resolve, reject) => {
      task.accept({
        success: () => resolve(task),
        failure: (err) =>
          reject(connectErrorHandler(err, "Failure to accept task")),
      })
    })
  }

  const closeTask = (): Promise<string> => {
    const task = getCurrentTask()

    return new Promise((resolve, reject) => {
      task.clear({
        success: () => resolve("Success closing task"),
        failure: (err) =>
          reject(connectErrorHandler(err, "Failure to close task")),
      })
    })
  }

  const endTask = (): Promise<string> => {
    const task = getCurrentTask()

    const connections = task.getConnections()
    if (!connections || !connections.length) {
      throw new Error("No connections found for the task")
    }

    const taskConnection = connections[0]

    return new Promise((resolve, reject) => {
      taskConnection.destroy({
        success: () => resolve("Success ending task"),
        failure: (err) =>
          reject(connectErrorHandler(err, "Failure to end task")),
      })
    })
  }

  const answerPhoneCall = (): Promise<string> => {
    const currentContact = getCurrentVoiceContact()
    const isInbound = currentContact.isInbound()
    const contactStateType = currentContact.getStatus().type

    if (!isInbound) {
      throw new Error("Contact is not an inbound voice call")
    }

    if (contactStateType === connect.ContactStateType.CONNECTED) {
      return Promise.resolve("Voice contact is already connected")
    }

    return new Promise((resolve, reject) => {
      currentContact.accept({
        success: () => {
          resolve("Success")
        },
        failure: (err) =>
          reject(connectErrorHandler(err, "Failure to answer call")),
      })
    })
  }

  const rejectCall = (): Promise<string> => {
    const currentContact = getCurrentVoiceContact()
    const isInbound = currentContact.isInbound()

    if (!isInbound) {
      throw new Error("Contact is not an inbound voice call")
    }

    // Not adding any guards for reject inbound calls because i can't say for sure whether the contact
    // will be in CONTACTING state or maybe in some other state (INIT, PENDING, etc.)
    // I'd rather have an uncaught error rather than a subtle bug in the UI
    return new Promise((resolve, reject) => {
      currentContact.reject({
        success: () => {
          resolve("Successfully rejected")
        },
        failure: (err) =>
          reject(connectErrorHandler(err, "Failure to reject call")),
      })
    })
  }

  const manualCallPhoneNumber = async (
    phoneNumber: string,
    queueARN: string,
  ): Promise<string> => {
    if (!agent) {
      throw new Error("Agent is not defined")
    }

    // See here https://github.com/amazon-connect/amazon-connect-streams/blob/master/Documentation.md#agentconnect
    const endpoint = connect.Endpoint.byPhoneNumber(phoneNumber)

    return new Promise((resolve, reject) => {
      agent.connect(endpoint, {
        queueARN,
        success: () => resolve("Success"),
        failure: (err) =>
          reject(connectErrorHandler(err, "Failure to make a call")),
      })
    })
  }

  const getQueueARN = (): string => {
    if (!agent) {
      throw new Error("Agent is not defined")
    }

    try {
      // get the queue ARN associated with the task
      const task = getCurrentTask()
      const taskQueue = task.getQueue()

      return taskQueue.queueARN
    } catch (err) {
      // Four outbound calls without an underlying task
      if (err instanceof Error && err.message === "No incoming task found") {
        const routingProfile = agent.getRoutingProfile()

        return routingProfile.defaultOutboundQueue.queueARN
      }

      throw err
    }
  }

  const callPhoneNumber = (phoneNumber: string): Promise<string> => {
    if (!agent) {
      throw new Error("Agent is not defined")
    }

    const voiceContact = safeGetCurrentVoiceContact()
    const hasVoiceContact = Boolean(voiceContact)

    if (hasVoiceContact) {
      throw new Error("Agent is already in a voice call")
    }

    // See here https://github.com/amazon-connect/amazon-connect-streams/blob/master/Documentation.md#agentconnect
    const endpoint = connect.Endpoint.byPhoneNumber(phoneNumber)
    const queueARN = getQueueARN()

    return new Promise((resolve, reject) => {
      agent.connect(endpoint, {
        queueARN,
        success: () => resolve("Success"),
        failure: (err) =>
          reject(connectErrorHandler(err, "Failure to make a call")),
      })
    })
  }

  const hangUpPhoneCall = (): Promise<string> => {
    const voiceContact = safeGetCurrentVoiceContact()

    if (!voiceContact) {
      return Promise.resolve("Voice call is already disconnected")
    }

    const agentConnection = voiceContact.getAgentConnection()
    const agentConnectionStateType = agentConnection.getStatus().type
    const isDisconnectedCall =
      agentConnectionStateType === connect.ConnectionStateType.DISCONNECTED

    if (isDisconnectedCall) {
      return Promise.resolve("Voice call is already disconnected")
    }

    return new Promise((resolve, reject) => {
      agentConnection.destroy({
        success: () => {
          resolve("Disconnected")
        },
        failure: (err) => {
          reject(connectErrorHandler(err, "Failure to hang up a call"))
        },
      })
    })
  }

  const toggleMuteCall = async () => {
    if (!agent) {
      throw new Error("Agent is not defined")
    }

    // There is also an event handler agent.onMuteToggle() triggered whenever agent is muted/ unmuted
    // to be used in connect event handlers (outside of the react state)
    if (isMuted) {
      agent.unmute()
      setIsMuted(false)
    } else {
      agent.mute()
      setIsMuted(true)
    }
  }

  const toggleHoldCall = async (): Promise<string> => {
    try {
      const activeConnection = getVoiceContactConnection("contact")

      if (isOnHold) {
        return new Promise((resolve, reject) => {
          activeConnection.resume({
            success: () => resolve("Success resuming connection"),
            failure: (err) =>
              reject(connectErrorHandler(err, "Failure to resume call")),
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          activeConnection.hold({
            success: () => resolve("Success holding connection"),
            failure: (err) =>
              reject(connectErrorHandler(err, "Failure to put call on hold")),
          })
        })
      }
    } catch (error) {
      const errorInstance = toError(error)

      return Promise.reject(
        new Error("Failure to change hold state for voice call", {
          cause: errorInstance,
        }),
      )
    }
  }

  const closeContact = (): Promise<string> => {
    const currentContact = getCurrentVoiceContact()

    return new Promise((resolve, reject) => {
      currentContact.clear({
        success: () => resolve("Closed contact"),
        failure: (err) =>
          reject(connectErrorHandler(err, "Failure to close contact")),
      })
    })
  }

  return {
    answerPhoneCall,
    rejectCall,
    toggleAgentStatus,
    rejectTask,
    acceptTask,
    closeTask,
    endTask,
    callPhoneNumber,
    hangUpPhoneCall,
    manualCallPhoneNumber,
    toggleMuteCall,
    toggleHoldCall,
    isMuted,
    isOnHold,
    closeContact,
    getCurrentTask,
    getCurrentVoiceContact,
    safeGetCurrentVoiceContact,
    getVoiceContactConnection,
    getQueueARN,
  }
}

export { useConnectActionsHook }
