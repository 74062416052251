import { DateRangeNav } from "@/components/date-range-nav"
import { SearchBox } from "@/components/search/search-box"

import { StatusPicker } from "../Campaigns/view/status-picker"
import { CallReportSearchOptions } from "./call-history-schemas"
import { useCallHistorySearch } from "./call-history-search"

type Props = {
  onChange: ReturnType<typeof useCallHistorySearch>["onChange"]
  searchOptions: CallReportSearchOptions
}

export function CallHistorySearchBar({ onChange, searchOptions }: Props) {
  return (
    <div className="flex flex-col gap-4 lg:flex-row">
      <StatusPicker
        allowReset
        values={searchOptions.statuses}
        onChange={onChange("statuses")}
      />
      <DateRangeNav value={searchOptions.date} onChange={onChange("date")} />
      <SearchBox
        defaultValue={searchOptions.search_query || ""}
        onChange={onChange("search_query")}
        placeholder="callHistory.searchPlaceholder"
      />
    </div>
  )
}
