import { pushErrorMetric } from "@/components/core/ConnectCCP/error-metrics"
import { SentryLogger } from "@/helpers/sentryLogger"
import { type CallErrorMap } from "@/models/call-errors"

import { checkResetSession } from "./connect_session"
import {
  RefreshConnectSessionInputType,
  SetAgent,
  SoftphoneErrorHandlerInputType,
} from "./types"

const log = new SentryLogger()

const checkAndRefreshConnectSession = async (
  refreshConnectSession: RefreshConnectSessionInputType["refreshConnectSession"],
): Promise<void> => {
  const shouldResetSession = checkResetSession()

  // Re-authenticate with AWS connect; The AWS Connect refresh token can only be re-used once
  if (shouldResetSession) {
    await refreshConnectSession()
  }
}

const handleAgentRefresh = (setAgent: SetAgent) => (agent: connect.Agent) => {
  try {
    setAgent(agent)
  } catch (error) {
    log.error(error, { agent: agent.toSnapshot() })
  }
}

/** Event handler for the agent status change.
 * @param refreshConnectSession - function to refresh the AWS Connect session
 * @returns void
 *
 * Attempts to refresh the Amazon Connect token when agent goes from Offline to Available to prevent the agent from being disconnected after 10 hours of being online.
 * We didn't attempt to do this while the agent is online because we're not sure whether it might disconnect the agent while in a call;
 * TODO: Needs more testing and research to make the token refresh in all kinds of agent states.
 */
const handleAgentStateChange =
  ({ refreshConnectSession }: RefreshConnectSessionInputType) =>
  async ({
    agent,
    newState,
    oldState,
  }: connect.AgentStateChange): Promise<void> => {
    try {
      if (oldState === "Offline" && newState === "Available") {
        await checkAndRefreshConnectSession(refreshConnectSession)
      }
    } catch (error) {
      log.error(error, { agent: agent.toSnapshot() })
    }
  }

const logUnhandledSoftphoneError = async (
  softPhoneError: connect.SoftphoneError,
  agentRef: SoftphoneErrorHandlerInputType["agentRef"],
) => {
  switch (softPhoneError.errorType) {
    case connect.SoftphoneErrorTypes.SIGNALLING_HANDSHAKE_FAILURE:
      await pushErrorMetric(
        agentRef,
        "callpanel-network-error-handshake-failure",
      )
      break
    case connect.SoftphoneErrorTypes.SIGNALLING_CONNECTION_FAILURE:
      await pushErrorMetric(
        agentRef,
        "callpanel-network-error-signalling-connection",
      )
      break
    case connect.SoftphoneErrorTypes.WEBRTC_ERROR:
      await pushErrorMetric(agentRef, "callpanel-network-error-webrtc")
      break
    default:
      log.error(
        `Unexpected softphone error: ${softPhoneError.errorType} - ${softPhoneError.errorMessage}`,
        {
          agent: agentRef.current?.toSnapshot(),
          isSoftphoneError: true,
        },
      )
      break
  }
}

/**
 * Event handler triggered whenever the iframe throws a softphone error. We've first encountered this when
 * agent's settings in the browser have microphone access disabled. See https://app.clickup.com/t/7508642/POC-4339
 *
 */
const handleSoftphoneError =
  ({ agentRef, pushCallError, setModalMode }: SoftphoneErrorHandlerInputType) =>
  async (initialError: connect.SoftphoneError): Promise<void> => {
    try {
      const isMicError =
        initialError.errorType ===
        connect.SoftphoneErrorTypes.MICROPHONE_NOT_SHARED

      const isUnsupportedBrowser =
        initialError.errorType ===
        connect.SoftphoneErrorTypes.UNSUPPORTED_BROWSER

      // wrong typing for errorType, of course!
      const errorType: unknown = initialError.errorType

      // Display an error card on top of the call panel
      pushCallError(errorType as keyof CallErrorMap)

      // Show a modal to the agent
      if (isMicError) {
        setModalMode({ mode: "microphoneNotShared" })
      } else if (isUnsupportedBrowser) {
        setModalMode({ mode: "unsupportedBrowser" })
      } else {
        // fire and forget. Async function `logUnhandledSoftphoneError` intentionally not awaited
        // because it calls `pushErrorMetric` which is a fire and forget function
        logUnhandledSoftphoneError(initialError, agentRef)
      }
    } catch (error) {
      log.error(error, {
        agent: agentRef.current?.toSnapshot(),
        isSoftphoneError: true,
      })
    }
  }

export { handleAgentRefresh, handleAgentStateChange, handleSoftphoneError }
