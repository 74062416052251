import { useTranslation } from "react-i18next"

import { SearchBox } from "@/components/search/search-box"
import { Checkbox } from "@/components/ui/checkbox"

import { useCampaignSearchOptions } from "./search-options"

type Props = {
  searchState: ReturnType<typeof useCampaignSearchOptions>
}

export function CampaignSearchBar({ searchState }: Props) {
  const { t } = useTranslation()
  const { onChange, searchOptions } = searchState

  return (
    <div className="flex gap-4">
      <SearchBox
        defaultValue={searchOptions.search_query || ""}
        onChange={onChange("search_query")}
      />
      <div className="flex items-center space-x-2">
        <Checkbox
          id="active_only"
          checked={searchState.searchOptions.enabled_campaigns_only}
          onCheckedChange={onChange("enabled_campaigns_only")}
        />
        <label htmlFor="active_only" className="text-sm">
          {t("campaigns.search.activeOnly")}
        </label>
      </div>
    </div>
  )
}
