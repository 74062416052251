import { useState } from "react"
import { AxiosError } from "axios"

import { useAxiosClientContext } from "@/components/core/AxiosInstanceProvider/useAxiosClient"
import { parseDataFromAPI } from "@/hooks/query-api"
import { useLogger } from "@/hooks/useLogger"

import {
  UploadErrorResponse,
  uploadResponseErrorSchema,
  uploadResponseSuccessSchema,
  UploadSuccessResponse,
} from "./upload-schemas"

type Step = "error" | "initial" | "processing" | "success" // a basic state machine with 4 steps

export function useUploadNoCallCSV() {
  const axiosClient = useAxiosClientContext()
  const [step, setStep] = useState<Step>("initial")
  const [file, setFile] = useState<File | null>(null)
  const [results, setResults] = useState<UploadSuccessResponse | null>(null)
  const [errors, setErrors] = useState<UploadErrorResponse | null>(null)
  const log = useLogger()

  async function upload() {
    setStep("processing")
    try {
      const formData = new FormData()
      formData.append("file", file as Blob)

      const { data } = await axiosClient.post(
        "/students/upload-global-no-call-csv",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      )

      const results = await parseDataFromAPI(data, uploadResponseSuccessSchema)

      setResults(results)
      setStep("success")

      return data
    } catch (error) {
      if (isUnexpectedError(error)) {
        // Only log unexpected errors, not fixable by the user
        log.error(error, { file: file?.name })
      }
      const errors =
        error instanceof AxiosError
          ? await parseDataFromAPI(
              error.response?.data,
              uploadResponseErrorSchema,
            )
          : null

      setErrors(errors)
      setStep("error")
    }
  }

  function reset() {
    setFile(null)
    setStep("initial")
  }

  function onFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    setFile(e.target.files?.[0] || null)
  }

  return { onFileChange, upload, file, step, results, errors, reset }
}

function isUnexpectedError(error: unknown) {
  if (!(error instanceof AxiosError)) {
    return true
  }

  return error.response?.status !== 400
}
