import { z } from "zod"

import { PaginationParams } from "@/components/pagination/pagination-state"
import { useQueryAPI } from "@/hooks/query-api"

const noCallListItemSchema = z.object({
  email: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  phone_number: z.string(),
  updated_at: z.string(),
})

export type NoCallStudent = z.infer<typeof noCallListItemSchema>

const searchResponseSchema = z.object({
  data: z.object({
    total: z.number(),
    students: z.array(noCallListItemSchema),
  }),
})

export function useFetchNoCallList({
  page,
  pageSize,
  searchOptions,
}: PaginationParams & {
  searchOptions: StudentSearchOptions
}) {
  return useQueryAPI({
    apiEndPoint: "/students/search/global-no-call",
    schema: searchResponseSchema,
    params: {
      page,
      page_size: pageSize,
      search_query: searchOptions.search_query,
    },
  })
}

/**
 * Search schema used to parse the URL query string
 */
export const searchOptionsSchema = z.object({
  search_query: z.string().optional(),
})

export type StudentSearchOptions = z.infer<typeof searchOptionsSchema>
