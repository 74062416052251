import { useTranslation } from "react-i18next"

import { usePaginationParams } from "@/components/pagination/pagination-state"

import { useFetchCallHistory } from "./call-history-schemas"
import { useCallHistorySearch } from "./call-history-search"
import { CallHistorySearchBar } from "./call-history-search-bar"
import { CallHistoryTable } from "./call-history-table"

const now = new Date() // used to set a default `to` date with the default time ranges

export function CallReportsPage() {
  const { t } = useTranslation()
  const defaultPageSize = 20
  const { page, pageSize } = usePaginationParams(defaultPageSize)
  const { onChange, searchOptions } = useCallHistorySearch()
  const { date, search_query, statuses } = searchOptions
  const { data, isLoading } = useFetchCallHistory({
    date: { range: date.range, to: date.to || now },
    pageSize,
    page,
    statuses,
    search_query,
  })

  const callReports = data?.data.call_reports || []
  const total = data?.data.total || 0

  return (
    <main className="flex flex-col gap-y-8 px-8 pt-10">
      <h1 className="font-h1-bold" data-sentry-unmask>
        {t("callHistory.heading")}
      </h1>
      <CallHistorySearchBar onChange={onChange} searchOptions={searchOptions} />
      <CallHistoryTable
        callReports={callReports}
        isLoading={isLoading}
        total={total}
        page={page}
        pageSize={pageSize}
      />
    </main>
  )
}
