/* eslint-disable react-refresh/only-export-components */
import { useTranslation } from "react-i18next"
import {
  addDays,
  addMonths,
  addWeeks,
  format,
  Locale,
  setMilliseconds,
  setMinutes,
  setSeconds,
  startOfMonth,
  startOfWeek,
  subDays,
} from "date-fns"
import { Check, ChevronDown, ChevronLeft, ChevronRight } from "lucide-react"
import { z } from "zod"

import { Button } from "@/components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { cn } from "@/helpers/classNames"
import { getLocale } from "@/helpers/i18n"
import { datetimeSchema } from "@/helpers/zodSchemas"

export const dateRangeSchema = z.object({
  range: z.enum(["day", "week", "month", "7days", "30days"]).default("7days"),
  to: datetimeSchema.optional(),
})

export type DateRangeValue = z.infer<typeof dateRangeSchema>

type Props = {
  onChange: (value: DateRangeValue) => void
  value: DateRangeValue
}

/**
 * Navigation component to go through different date ranges using previous and next buttons
 * A dropdown menu is used to select the range: today, this week, this month, last 7 days, last 30 days
 */
export function DateRangeNav({ onChange, value }: Props) {
  const { t } = useTranslation()
  const locale = getLocale()
  const { handleNext, handlePrevious, handleTypeChange } = useDateRangeNav({
    value,
    onChange,
  })

  return (
    <div className="flex items-center">
      <Button
        onClick={handlePrevious}
        variant="outline"
        size="icon"
        className="rounded-none rounded-l"
      >
        <ChevronLeft className="size-4" />
      </Button>

      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="outline"
            className="min-w-[200px] rounded-none border-x-0"
          >
            {formatDateRange(value, locale)}
            <ChevronDown className="ml-2 size-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="min-w-[200px]">
          <DropdownMenuItem onClick={() => handleTypeChange("7days")}>
            <SelectedIcon selected={value.range === "7days" && !value.to} />
            {t("global.datepicker.last7days")}
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => handleTypeChange("30days")}>
            <SelectedIcon selected={value.range === "30days" && !value.to} />
            {t("global.datepicker.last30days")}
          </DropdownMenuItem>

          <DropdownMenuSeparator />

          <DropdownMenuItem onClick={() => handleTypeChange("day")}>
            <SelectedIcon selected={value.range === "day" && !value.to} />
            {t("global.datepicker.today")}
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => handleTypeChange("week")}>
            <SelectedIcon selected={value.range === "week" && !value.to} />
            {t("global.datepicker.thisWeek")}
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => handleTypeChange("month")}>
            <SelectedIcon selected={value.range === "month" && !value.to} />
            {t("global.datepicker.thisMonth")}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <Button
        className="rounded-none rounded-r"
        variant="outline"
        size="icon"
        onClick={handleNext}
      >
        <ChevronRight className="size-4" />
      </Button>
    </div>
  )
}

function SelectedIcon({ selected }: { selected: boolean }) {
  return (
    <Check
      className={cn("mr-2 size-4", selected ? "opacity-100" : "opacity-0")}
    />
  )
}

function formatDateRange(value: DateRangeValue, locale: Locale) {
  const { range, to = new Date() } = value
  const yearFormat = locale.code.startsWith("ja") ? "yo" : "yyyy" // show suffix 年 in Japanese

  function formatDate(date: Date, showYear = true) {
    return showYear
      ? format(date, `${yearFormat} MMM do`, { locale })
      : format(date, "MMM do", { locale })
  }

  switch (range) {
    case "day":
      return formatDate(to)
    case "week":
      return `${formatDate(startOfWeek(to))} ~ ${formatDate(to)}`
    case "month":
      return format(startOfMonth(to), `${yearFormat} MMM`, { locale })
    case "7days":
      return `${formatDate(subDays(to, 7))} ~ ${formatDate(to, false)}`
    case "30days":
      return `${formatDate(subDays(to, 30))} ~ ${formatDate(to, false)}`
  }
}

function useDateRangeNav({ onChange, value }: Props) {
  function getNextEndDate(direction: -1 | 1) {
    const dateRef = value.to || new Date()
    switch (value.range) {
      case "day":
        return addDays(dateRef, direction)
      case "week":
        return addWeeks(dateRef, direction)
      case "month":
        return addMonths(dateRef, direction)
      case "7days":
        return addDays(dateRef, 7 * direction)
      case "30days":
        return addDays(dateRef, 30 * direction)
    }
  }

  const navigate = (direction: -1 | 1) => () => {
    const endDate = getNextEndDate(direction)
    onChange({ ...value, to: clearMinutes(endDate) })
  }

  const handleNext = navigate(1)
  const handlePrevious = navigate(-1)

  const handleTypeChange = (range: DateRangeValue["range"]) => {
    onChange({ to: undefined, range })
  }

  return {
    handlePrevious,
    handleNext,
    handleTypeChange,
  }
}

/** Remove the minutes, seconds and milliseconds to make the URL a bit cleaner */
function clearMinutes(date: Date) {
  return setMinutes(setSeconds(setMilliseconds(date, 0), 0), 0)
}
