// Sort profiles in order of importance. Highest values == more permissions.
const agentProfilesMapping: Record<string, number> = {
  admin: 5,
  callCenterManagerAndAgent: 4,
  callCenterManager: 3,
  qualityAnalyst: 2,
  agent: 1,
} as const

export { agentProfilesMapping }
