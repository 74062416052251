import { useCallDurationData } from "@/hooks/callDuration"
import { useProcessingTimeHook } from "@/hooks/callProcessingTime"
import { useConnectActionsHook } from "@/hooks/connectActions"
import { useCallPanelLogger } from "@/hooks/useLogger"

interface ErrorButtonsHookType {
  clearTimestampsInLocalStorage: VoidFunction
  closeContactWithFallback: () => Promise<void>
}

const useErrorButtonsHook = (): ErrorButtonsHookType => {
  const { clearProcessingTimeData } = useProcessingTimeHook()
  const { clearCallDurationData } = useCallDurationData()
  const { closeContact, closeTask } = useConnectActionsHook()

  const log = useCallPanelLogger()

  const clearTimestampsInLocalStorage = () => {
    try {
      clearProcessingTimeData()
      clearCallDurationData()
    } catch (err) {
      log.error(err)
    }
  }

  const closeContactWithFallback = async () => {
    try {
      await closeContact()
    } catch (err) {
      await closeTask()
    }
  }

  return {
    clearTimestampsInLocalStorage,
    closeContactWithFallback,
  }
}

export { useErrorButtonsHook }
