import * as React from "react"
import * as SheetPrimitive from "@radix-ui/react-dialog"

import { cn } from "@/helpers/classNames"

export const Sheet = SheetPrimitive.Root

export const SheetTrigger = SheetPrimitive.Trigger

export const SheetClose = SheetPrimitive.Close

export const SheetPortal = SheetPrimitive.Portal

type SheetContentProps = React.ComponentPropsWithoutRef<
  typeof SheetPrimitive.Content
>

/**
 * Simplified version of shadcn/ui `SheetContent` component
 * intended to display a small panel vertically centered on the screen, sliding in from the left.
 * This is a very specific component (no overlay, no close button inside)
 * used to display the agent metrics panel in the CallPanel view.
 */
export const SidePanelContent = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Content>,
  SheetContentProps
>(({ children, className, ...props }, ref) => (
  <SheetPrimitive.Content
    ref={ref}
    aria-describedby={undefined}
    onInteractOutside={
      (event) =>
        event.preventDefault() /* to keep it open when clicking outside */
    }
    className={cn(
      "fixed z-50 transition ease-in-out data-[state=closed]:duration-300 data-[state=open]:duration-500 data-[state=open]:animate-in data-[state=closed]:animate-out",
      "left-0 top-0 flex h-full items-center data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left",
      className,
    )}
    {...props}
  >
    <SheetPrimitive.Title className="sr-only">
      Side panel metrics
    </SheetPrimitive.Title>
    <div
      className={cn(
        "flex min-h-[200px] items-center rounded-r-xl border-y border-r bg-background p-2 shadow-lg",
        className,
      )}
    >
      {children}
    </div>
  </SheetPrimitive.Content>
))
SidePanelContent.displayName = "SidePanelContent"
