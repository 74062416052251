import { useSearchParams } from "react-router-dom"
import { z } from "zod"

import { updateSearchParams } from "@/helpers/url"

export const searchOptionsSchema = z.object({
  search_query: z.string(),
  enabled_campaigns_only: z.coerce.boolean(),
})

export type SearchOptions = z.infer<typeof searchOptionsSchema>

export function useCampaignSearchOptions() {
  const [params, setParams] = useSearchParams()

  const searchOptions = searchOptionsSchema.parse({
    search_query: params.get("search_query") || "",
    enabled_campaigns_only: params.get("enabled_campaigns_only") === "true",
  })

  const onChange =
    <K extends keyof SearchOptions>(key: K) =>
    (value: SearchOptions[K]) => {
      setParams((current) => {
        updateSearchParams(current, key, value.toString())

        return current
      })
    }

  return { searchOptions, onChange }
}
