import { useAtom } from "jotai"

import * as layoutValue from "@/components/layout/value" // TODO move this type to a better place and improve naming
import { agentProfileAtom } from "@/helpers/atoms"

export function useAgentProfile() {
  const [agentProfile] = useAtom(agentProfileAtom)

  const isManager = Boolean(
    agentProfile &&
      hasRole(agentProfile, ["Admin", "CallCenterManagerAndAgent"]),
  )

  return { agentProfile, isManager }
}

export function usePermissions() {
  const { isManager } = useAgentProfile()

  return {
    canEditCampaigns: isManager,
  }
}

function hasRole(
  agentProfile: layoutValue.Decoder.ConnectUserInformation,
  roles: string[],
) {
  return agentProfile.security_profile_names.some((profileName) =>
    roles.includes(profileName),
  )
}
