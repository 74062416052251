import { FunctionComponent, useEffect, useRef, useState } from "react"
import { useAtom } from "jotai"

import { useModal } from "@/components/core/Root/modal-root"
import Icon from "@/components/Icon"
import { Button } from "@/components/ui/button"
import {
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"
import { modalModeAtom } from "@/helpers/atoms"
import { cn } from "@/helpers/classNames"
import { useI18n } from "@/helpers/i18n/use-translation"
import { times } from "@/helpers/other"
import { isNullish } from "@/helpers/typeguards"
import { useCallPanelLogger } from "@/hooks/useLogger"
import { useOS } from "@/hooks/useOS"

const MicrophoneNotSharedDialog: FunctionComponent = () => {
  const { os } = useOS()
  const { t } = useI18n()
  const videoRef = useRef<HTMLVideoElement>(null)

  const styles = {
    wrapper: cn("flex flex-row gap-x-6 px-8 text-neutral-700"),
    textContainer: cn("flex w-72 flex-col gap-y-4"),
    buttonsContainer: cn("mt-auto flex justify-between gap-x-4"),
    buttonWrapper: cn(
      "font-button-small flex items-center justify-between gap-x-1",
    ),
    iconLeft: cn("-rotate-180"),
    subtitle: cn("font-h3-regular"),
    list: cn("font-body-medium-regular list-inside list-decimal"),
    listItem: cn("mb-4"),
    videoContainer: cn("w-[250px] grow"),
  }

  // TODO: Consider Linux in the future
  const [osSelection, setOSSelection] = useState<
    ReturnType<typeof useOS>["os"]
  >(os === "mac" ? "mac" : "win")

  const iterations = osSelection === "mac" ? 4 : 3

  const videoSrc = `/videos/${
    osSelection === "mac" ? "mac" : "windows"
  }-sequence.mp4`

  const posterSrc = `/videos/${
    osSelection === "mac" ? "mac" : "windows"
  }-poster.webp`

  // Reload the video when the OS selection changes
  useEffect(() => {
    videoRef.current?.load()
  }, [videoSrc])

  return (
    <DialogContent size="4xl">
      <DialogHeader />

      <DialogBody className={styles.wrapper} data-sentry-unmask>
        <div className={styles.textContainer}>
          <DialogTitle variant="h2">
            {t("global.modals.microphoneNotShared.title")}
          </DialogTitle>
          <h3 className={styles.subtitle}>
            {t(`global.modals.microphoneNotShared.${osSelection}.subtitle`)}
          </h3>
          <ol className={styles.list}>
            {times(iterations).map((i) => (
              <li key={i} className={styles.listItem}>
                {t(
                  `global.modals.microphoneNotShared.${osSelection}.text.${i}`,
                )}
              </li>
            ))}
          </ol>
          <div className={styles.buttonsContainer}>
            <Button
              variant="outline"
              onClick={() => setOSSelection("win")}
              isDisabled={osSelection === "win"}
            >
              <div className={styles.buttonWrapper}>
                <Icon name="chevron" size="sm" className={styles.iconLeft} />
                <span>{t("global.modals.microphoneNotShared.win.button")}</span>
              </div>
            </Button>
            <Button
              variant="outline"
              onClick={() => setOSSelection("mac")}
              isDisabled={osSelection === "mac"}
            >
              <div className={styles.buttonWrapper}>
                <span>{t("global.modals.microphoneNotShared.mac.button")}</span>
                <Icon name="chevron" size="sm" />
              </div>
            </Button>
          </div>
        </div>

        <video
          autoPlay
          muted={true}
          loop={true}
          className={styles.videoContainer}
          ref={videoRef}
          poster={posterSrc}
          preload="auto"
        >
          <source src={videoSrc} type="video/mp4" />
          <a href={videoSrc}>{t("global.button.download")}</a>
        </video>
      </DialogBody>
    </DialogContent>
  )
}

interface BrowserErrorDialogProps {
  close: (value: boolean) => void
}

const BrowserErrorDialog: FunctionComponent<BrowserErrorDialogProps> = ({
  close,
}) => {
  const { t, tMap } = useI18n()

  return (
    <DialogContent>
      <DialogHeader />
      <DialogBody>
        <div className="flex gap-x-2" data-sentry-unmask>
          <div className="relative mt-1 size-6 rounded-full border bg-danger-100">
            <Icon name="alert" className="absolute inset-0 text-danger-300" />
          </div>
          <div className="flex flex-col gap-y-4">
            <DialogTitle data-sentry-unmask className="font-h3-regular">
              {t("global.modals.browserError.title")}
            </DialogTitle>
            <div className="font-body-medium-regular">
              {tMap("global.modals.browserError.text", (text, i) => (
                <p key={i}>{text}</p>
              ))}
            </div>
          </div>
        </div>
      </DialogBody>
      <DialogFooter data-sentry-unmask>
        <Button type="button" variant="outline" onClick={() => close(false)}>
          {t("global.button.close")}
        </Button>
      </DialogFooter>
    </DialogContent>
  )
}

const MicrophoneErrorModal: FunctionComponent = () => {
  const [modalMode, setModalMode] = useAtom(modalModeAtom)
  const errorsInScope = ["microphoneNotShared", "unexpectedSoftphoneError"]
  const { showModal } = useModal()
  const log = useCallPanelLogger()

  useEffect(() => {
    const showErrorModal = async () => {
      if (isNullish(modalMode) || !errorsInScope.includes(modalMode.mode)) {
        return
      }

      try {
        await showModal<boolean>((close) => {
          return (
            <>
              {modalMode.mode === "microphoneNotShared" && (
                <MicrophoneNotSharedDialog />
              )}
              {modalMode.mode === "unsupportedBrowser" && (
                <BrowserErrorDialog close={close} />
              )}
            </>
          )
        })

        setModalMode(null)
      } catch (err) {
        setModalMode(null)
        log.error(err)
      }
    }

    showErrorModal()
  }, [modalMode?.mode]) // eslint-disable-line react-hooks/exhaustive-deps

  return null
}

export default MicrophoneErrorModal
