import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { isRouteErrorResponse, useRouteError } from "react-router-dom"
import { useAtomValue } from "jotai"

import { ccpModeAtom } from "@/helpers/atoms"
import { isNullish } from "@/helpers/typeguards"
import { useCallPanelLogger } from "@/hooks/useLogger"

interface RouterErrorType {
  errorDescription?: string
  errorMessage: string
  errorStatus?: number
}

interface ConnectCCPErrorType {
  errorDescription: string
  errorMessage: string
}

interface ErrorHookType {
  ccpAuthFormattedError?: ConnectCCPErrorType
  formattedError?: RouterErrorType
}

const useErrorHook = (): ErrorHookType => {
  const { t } = useTranslation()
  const error = useRouteError()
  const ccpMode = useAtomValue(ccpModeAtom)
  const log = useCallPanelLogger()

  const getFormattedError = (): RouterErrorType | undefined => {
    if (isNullish(error)) {
      return
    }

    if (isRouteErrorResponse(error)) {
      if (error.status === 404) {
        return {
          errorStatus: error.status,
          errorMessage: t("errors.errorPage.notFoundError"),
          errorDescription: t("errors.errorPage.notFoundDescription"),
        }
      } else {
        return {
          errorStatus: error.status,
          errorMessage: error.statusText,
          errorDescription: error.data,
        }
      }
    } else if (error instanceof Error) {
      return {
        errorMessage: error.name,
        errorDescription: error.message,
      }
    } else if (typeof error === "string") {
      return {
        errorMessage: t("errors.errorPage.unexpectedError"),
        errorDescription: error,
      }
    } else {
      return {
        errorMessage: t("errors.errorPage.unexpectedError"),
        errorDescription: t("errors.errorPage.unexpectedErrorDescription"),
      }
    }
  }

  const getCCPFormattedError = (): ConnectCCPErrorType | undefined => {
    if (ccpMode.current !== "ERROR") {
      return
    }

    return {
      errorMessage: t("errors.errorPage.unexpectedError"),
      errorDescription: t("errors.errorPage.unexpectedErrorDescription"),
    }
  }

  const formattedError = getFormattedError()
  const ccpAuthFormattedError = getCCPFormattedError()

  // log any caught react router errors to sentry (except 404 errors)
  useEffect(() => {
    if (error && (!isRouteErrorResponse(error) || error.status !== 404)) {
      log.error(error)
    }
  }, [error]) // eslint-disable-line react-hooks/exhaustive-deps

  return { formattedError, ccpAuthFormattedError }
}

export { useErrorHook }
