import { FunctionComponent } from "react"

import { AgentStatusSwitcher } from "./agent-status-switcher"
import { useAgentStatusHook } from "./hook"

const AgentStatusSelect: FunctionComponent = () => {
  const { isDisabled, onChange, selectedValue } = useAgentStatusHook()

  return (
    <AgentStatusSwitcher
      isDisabled={isDisabled}
      status={selectedValue}
      onChange={onChange}
      data-sentry-unmask
    />
  )
}

export default AgentStatusSelect
