import {
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react"

import { isNumber } from "@/helpers/typeguards"
import { formatStringValue as formatValue } from "@/helpers/valueFormat"

interface TruncatedParagraphHook {
  formattedText: string
  formattedTextRef: RefObject<HTMLDivElement>
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  showButton: boolean
}

const useTruncatedParagraphHook = (text: string): TruncatedParagraphHook => {
  const [isOpen, setIsOpen] = useState(false)
  const [showButton, setShowButton] = useState(false)
  const formattedTextRef = useRef<HTMLDivElement>(null)
  const formattedText = formatValue(text)

  // Condition to display the "Show more" button for the student focus
  useEffect(() => {
    if (formattedTextRef.current) {
      const lineHeight = parseInt(
        getComputedStyle(formattedTextRef.current).lineHeight,
        10,
      )

      if (isNumber(lineHeight)) {
        const height = formattedTextRef.current.clientHeight
        const lines = height / lineHeight

        if (lines > 2) {
          setShowButton(true)
        }
      }
    }
  }, [formattedText])

  return {
    isOpen,
    setIsOpen,
    showButton,
    formattedText,
    formattedTextRef,
  }
}

export { useTruncatedParagraphHook }
