import { PropsWithChildren } from "react"
import { HistoryIcon, LoaderCircle } from "lucide-react"

import Icon from "@/components/Icon"
import { EmptyValue } from "@/components/table-helpers"
import { Button } from "@/components/ui/button"
import { TooltipWrapper } from "@/components/ui/tooltip"
import { cn } from "@/helpers/classNames"
import { formatDistanceToDate } from "@/helpers/dateFormat"
import { resolveLanguage, useI18n } from "@/helpers/i18n"
import { formatRate } from "@/helpers/numbers"

import {
  AgentPerformanceMetadata,
  AgentPerformanceMetrics,
} from "./agent-metrics-api"

type Props = {
  campaignIds: string[]
  isLoading?: boolean
  lastUpdated?: Date
  metadata: AgentPerformanceMetadata | null
  metrics: AgentPerformanceMetrics | null
  refetch?: () => void
}

export function AgentMetricsPanelContent({
  campaignIds,
  isLoading,
  lastUpdated,
  metadata,
  metrics,
  refetch,
}: Props) {
  return (
    <div className="w-full space-y-4 pb-2">
      <PanelHeader campaignIds={campaignIds} />
      <div className="relative">
        <LoadingSpinner isLoading={isLoading} />
        <div className={cn("space-y-4", isLoading && "invisible")}>
          <PanelBody metrics={metrics} metadata={metadata} />
          <PanelFooter lastUpdated={lastUpdated} refetch={refetch} />
        </div>
      </div>
    </div>
  )
}

function PanelHeader({ campaignIds }: Pick<Props, "campaignIds">) {
  const { tMap } = useI18n()

  return (
    <div className="relative w-full rounded bg-primary-100 p-2 text-center text-xs font-bold text-primary-400">
      <TooltipWrapper
        tooltip={<TooltipContent campaignIds={campaignIds} />}
        contentProps={{ side: "right" }}
      >
        {tMap("sidePanelMetrics.title", (text) => (
          <div key={text}>{text}</div>
        ))}
        <Icon
          name="info"
          className="absolute right-0 top-1 size-6 text-primary-200"
        />
      </TooltipWrapper>
    </div>
  )
}

function TooltipContent({ campaignIds }: Pick<Props, "campaignIds">) {
  const { tMap } = useI18n()

  return (
    <div className="text-left">
      {tMap("sidePanelMetrics.description", (text, i) => (
        <div key={i}>{text}</div>
      ))}
      <ul className="list-disc pl-6">
        {campaignIds.map((campaignId) => (
          <li key={campaignId}>{campaignId}</li>
        ))}
      </ul>
    </div>
  )
}

function LoadingSpinner({ isLoading }: { isLoading?: boolean }) {
  return (
    <div className="absolute inset-0 m-auto size-12">
      <LoaderCircle
        className={cn(
          "size-12 animate-spin text-primary-200",
          !isLoading && "hidden",
        )}
      />
    </div>
  )
}

function PanelBody(props: Pick<Props, "metadata" | "metrics">) {
  const { t } = useI18n()

  return (
    <>
      <div className="space-y-4 border-b pb-4">
        <MetricNumber
          metricId="CallsPerHour"
          label={t("sidePanelMetrics.callsPerHour")}
          {...props}
        />
        <MetricNumber
          metricId="TotalConnectedCallsPerHour"
          label={t("sidePanelMetrics.connectedCalls.perHour")}
          {...props}
        />
        <MetricNumber
          metricId="ConnectedCallRate"
          label={t("sidePanelMetrics.connectedCalls.rate")}
          unit="%"
          {...props}
        />
      </div>

      <div className="space-y-4 border-b pb-4">
        <MetricNumber
          metricId="TotalCreditForEntryConfirmed"
          decimals={0}
          label={t("sidePanelMetrics.conversions.total")}
          {...props}
        />

        <MetricNumber
          metricId="CreditForEntryConfirmedCVR"
          label={t("sidePanelMetrics.conversions.rate")}
          unit="%"
          {...props}
        />
      </div>

      <div className="space-y-4 border-b pb-4">
        <MetricNumber
          metricId="TotalCreditForTentativeEntry"
          decimals={0}
          label={t("sidePanelMetrics.entries.total")}
          {...props}
        />
        <MetricNumber
          metricId="CreditForTentativeEntryCVR"
          label={t("sidePanelMetrics.entries.rate")}
          {...props}
        />
      </div>
    </>
  )
}

function PanelFooter({
  lastUpdated,
  refetch,
}: Pick<Props, "lastUpdated" | "refetch">) {
  return (
    <div className="flex items-center justify-center gap-1 text-xs text-muted-foreground">
      <LastUpdateButton lastUpdated={lastUpdated} refetch={refetch} />
    </div>
  )
}

function LastUpdateButton({
  lastUpdated,
  refetch,
}: {
  lastUpdated?: Date
  refetch?: () => void
}) {
  return (
    <Button
      variant="ghost"
      className="h-6 px-2 text-xs font-normal"
      onClick={refetch}
    >
      <HistoryIcon className="size-4" />
      {formatDistanceToDate(lastUpdated || new Date())
        .replace(/minutes?/, "min")
        .replace(/second?/, "sec")}
    </Button>
  )
}

function MetricNumber({
  decimals = 2,
  label,
  metadata,
  metricId,
  metrics,
  unit,
}: Pick<Props, "metadata" | "metrics"> & {
  decimals?: number
  label: string
  metricId: keyof AgentPerformanceMetrics
  unit?: string
}) {
  const value = metrics?.[metricId]
  const language = resolveLanguage()
  const tooltip = metadata?.[metricId]?.additionalInfo[language]

  return (
    <MetricItem label={label} tooltip={tooltip}>
      <MetricValue value={value} unit={unit} decimals={decimals} />
    </MetricItem>
  )
}

function MetricValue({
  decimals = 2,
  unit,
  value,
}: {
  decimals?: number
  unit?: string
  value?: number | null
}) {
  if (!value) return <EmptyValue />
  const formattedValue =
    unit === "%" ? formatRate(value * 100) : value.toFixed(decimals)

  return (
    <div className="flex items-end">
      <div className="text-xl">{formattedValue}</div>
      {unit && (
        <span className="relative top-[-2px] ml-[2px] text-sm">{unit}</span>
      )}
    </div>
  )
}

function MetricItem({
  children,
  label,
  tooltip,
}: PropsWithChildren<{ label: string; tooltip?: string }>) {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="text-xs text-muted-foreground">
        <TooltipWrapper tooltip={tooltip}>{label}</TooltipWrapper>
      </div>
      <div className="h-7">{children}</div>
    </div>
  )
}
