import { useMutation } from "@tanstack/react-query"
import { useAtomValue } from "jotai"

import * as callViewValue from "@/components/CallView/value"
import { useAxiosClientContext } from "@/components/core/AxiosInstanceProvider/useAxiosClient"
import { config } from "@/config"
import { inManualCallAtom, pseudoACWAtom } from "@/helpers/atoms"
import { createCallReportError } from "@/helpers/error"
import { isNull } from "@/helpers/typeguards"
import { useReactQueryOptions } from "@/hooks/reactQueryOptions"
import { statusMap, StatusUsageContext, statusUsageMap } from "@/models/cases"
import * as connectPanelValue from "@/pages/CustomConnectPanel/value"
import { contactStorageService } from "@/services/localStorageService"

import { useCallDurationData } from "./callDuration"
import { Timer } from "./callDurationTimer"
import { useProcessingTimeHook } from "./callProcessingTime"
import { useContactDataHook } from "./contactData"

const CALL_REPORT_URL = `https://${config.apiEndpoint}/call-reports`

export type CallReportType =
  | "abandon-manual-call"
  | "append-status"
  | "attempted-call"
  | "go-offline"
  | "reset-call-view"
  | "wont-call"

export const useSendCallReport = ({
  currentCase,
  isInboundCall,
  onSuccess,
  timer,
}: {
  currentCase: connectPanelValue.Decoder.CaseAndActivityFeeds | null
  isInboundCall?: boolean
  onSuccess?: () => void
  timer?: Timer | null
}) => {
  const isPseudoACW = useAtomValue(pseudoACWAtom)
  const inManualCall = useAtomValue(inManualCallAtom)
  const { callType, campaignId, currentTaskId, studentId, voiceContactId } =
    useContactDataHook(currentCase)
  const { getProcessingTime } = useProcessingTimeHook()
  const axiosClient = useAxiosClientContext()
  const { retry } = useReactQueryOptions()
  const { callDurationData } = useCallDurationData()

  const sendCallReportMutation = useMutation({
    mutationFn: async ({
      callReportType,
      formData,
    }: {
      callReportType: CallReportType
      formData?: callViewValue.Decoder.DecoderType
    }) => {
      if (isNull(studentId)) {
        throw new Error(`[${callReportType}] studentId is not found`)
      }

      if (isNull(campaignId)) {
        throw new Error(`[${callReportType}] campaignId is not found`)
      }
      const createdAt = new Date()
      const voiceCreatedAt = callDurationData?.call_start_timestamp
        ? new Date(callDurationData.call_start_timestamp)
        : new Date()
      const processingTime = getProcessingTime()
      const callReportDataParams = getCallReportDataByType(
        callReportType,
        inManualCall,
        formData,
        isInboundCall ?? false,
        isPseudoACW,
      )
      const callContact = voiceContactId
        ? {
            call_duration_seconds: timer
              ? timer.seconds + timer.minutes * 60 + timer.hours * 3600
              : null,
            contact_id: voiceContactId,
            created_at: voiceCreatedAt,
            type: callType,
          }
        : undefined
      const comment = formData?.memo
        ? { comment: formData.memo, created_at: createdAt }
        : undefined

      const callReportData = {
        campaign_id: campaignId,
        student_id: studentId,
        status_event: {
          context: callReportDataParams.context,
          event_name: callReportDataParams.event_name,
          created_at: createdAt,
          processing_duration_seconds: processingTime,
          call_attempted: callReportDataParams.call_attempted,
        },
        comment: comment,
        scheduled_call_datetime: formData?.scheduledCallDate,
        call_contact: callContact,
        stop_most_recent_task: callReportDataParams.stop_most_recent_task,
        open_task_contact_id: currentTaskId,
      }
      try {
        const response = await axiosClient.post(CALL_REPORT_URL, callReportData)
        const validResponse =
          await callViewValue.Decoder.saveReportSchema.parseAsync(response.data)

        if (!validResponse.success.length) {
          throw new Error(
            `[${callReportType}] Empty response from call report API`,
          )
        }

        return validResponse
      } catch (err) {
        throw createCallReportError(err, callReportData)
      }
    },
    onSuccess,
    retry,
  })

  return sendCallReportMutation
}

/**
 * Determines call report data based on the call report type and other parameters.
 *
 * @param callReportType - The type of call report
 * @param inManualCall - Whether the call is a manual call
 * @param formData - Optional form data from the call view
 * @param isInboundCall - Whether the call is inbound
 *
 * @returns An object with the following properties:
 * - event_name: The status or reason for the call event
 * - call_attempted: Whether a call was attempted
 * - stop_most_recent_task: Whether to stop the most recent task
 */
const getCallReportDataByType = (
  callReportType: CallReportType,
  inManualCall: boolean,
  formData: callViewValue.Decoder.DecoderType | undefined,
  isInboundCall: boolean,
  isPseudoACW: boolean,
): {
  call_attempted: boolean
  context: StatusUsageContext | null
  event_name: string
  stop_most_recent_task: boolean
} => {
  switch (callReportType) {
    case "abandon-manual-call":
      return {
        event_name: statusMap.LeaveAsIs,
        context: statusUsageMap.manualLeavingView,
        call_attempted: true,
        stop_most_recent_task: false,
      }
    case "append-status":
      return {
        event_name: formData?.reason || formData?.status || "",
        context: statusUsageMap.studentDetails,
        call_attempted: false,
        stop_most_recent_task: true,
      }
    case "go-offline":
      return {
        event_name: statusMap.Skip,
        context: statusUsageMap.automaticGoingOffline,
        call_attempted: false,
        stop_most_recent_task: false,
      }
    case "attempted-call":
      return {
        event_name: formData?.reason || formData?.status || "",
        call_attempted: true,
        context: getAttemptedCallContext(
          inManualCall,
          isPseudoACW,
          isInboundCall,
        ),
        stop_most_recent_task:
          formData?.status === statusMap.LeaveAsIs
            ? false
            : isInboundCall || inManualCall,
      }
    case "reset-call-view":
      return {
        event_name: statusMap.Skip,
        context: statusUsageMap.automaticResetView,
        stop_most_recent_task: false,
        call_attempted: contactStorageService.getAgentHasCalled(),
      }
    case "wont-call":
      return {
        event_name: formData?.reason || formData?.status || statusMap.Skip,
        context: statusUsageMap.automaticPreCall,
        call_attempted: false,
        stop_most_recent_task: false,
      }
  }
}

const getAttemptedCallContext = (
  inManualCall: boolean,
  isPseudoACW: boolean,
  isInboundCall: boolean,
) => {
  if (isInboundCall) {
    return statusUsageMap.automaticInbound
  }
  const callType = inManualCall ? "manual" : "automatic"
  const callStage = isPseudoACW ? "PreConnectedCall" : "Outbound"

  return statusUsageMap[`${callType}${callStage}`]
}
