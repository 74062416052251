import { MutableRefObject } from "react"

import { SentryLogger } from "@/helpers/sentryLogger"

const log = new SentryLogger()

export const unmountIframe = <T extends HTMLElement>(element: T | null) => {
  try {
    if (element?.children) {
      for (const child of element.children) {
        const isIframe = child.nodeName === "IFRAME"
        if (isIframe) {
          child.remove()
        }
      }
    }
  } catch (err) {
    log.error(err)
  }
}

export const mount = ({
  appContainer,
  appName,
  appUrl,
}: {
  appContainer: string
  appName: string
  appUrl: string
}) => {
  try {
    // Calling initCCP requires passing the html element while calling initApp
    // requires passing the div id as a string;
    connect.agentApp.initApp(appName, appContainer, appUrl)
  } catch (error) {
    log.error(error)
  }
}

export const unmount = <T extends HTMLElement>(
  ref: MutableRefObject<T | null>,
) => {
  const iframeContainer = ref.current

  try {
    connect.agentApp.stopApp()
  } catch (error) {
    log.error(error)
  }

  unmountIframe(iframeContainer)
}
