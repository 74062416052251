import { differenceInMilliseconds } from "date-fns"
import { useAtomValue } from "jotai"

import { getContactIdFromAgent } from "@/helpers/agent"
import { agentAtom } from "@/helpers/atoms"
import { isNullish } from "@/helpers/typeguards"
import { useCallPanelLogger } from "@/hooks/useLogger"
import { contactStorageService } from "@/services/localStorageService"

interface ProcessingTimeHook {
  clearProcessingTimeData: VoidFunction
  getProcessingTime: () => number
  setProcessingTimeData: VoidFunction
  updateProcessingTimeData: VoidFunction
}

/**
 * Hook containing all the necessary helper functions to perform CRUD operations on the
 * processing time data stored in the local storage;
 *
 * `contactIdForProcessingTime` equals the current task id for outbound calls; equals the current voice call id
 * for inbound calls when no task is present
 */
const useProcessingTimeHook = (isManualCall?: boolean): ProcessingTimeHook => {
  const agent = useAtomValue(agentAtom)
  const contactIdForProcessingTime = getContactIdFromAgent(agent)
  const log = useCallPanelLogger()

  /**
   * Function to return a processing time value based on the timestamp and the previous duration
   * spent processing the contact; This value is posted to the call report API
   */
  const getProcessingTime = (): number => {
    const parsedProcessingTimeData =
      contactStorageService.getProcessingTimeData()
    if (isNullish(parsedProcessingTimeData)) {
      return 0
    }

    if (!isManualCall) {
      if (isNullish(contactIdForProcessingTime)) {
        return 0
      }

      const isValidTimeData =
        parsedProcessingTimeData.contactId === contactIdForProcessingTime

      if (!isValidTimeData) {
        // TODO: restore this as Sentry warning when we have resolved: POC-5577,POC-5424 & POC-5675
        // eslint-disable-next-line no-console
        console.warn(
          `Processing time KPI is unavailable because processing time data is not matching the current contact ID: ${contactIdForProcessingTime}.`,
          {
            currentContactId: contactIdForProcessingTime,
            storedTimeData: parsedProcessingTimeData,
          },
        )

        return 0
      }
    }

    const now = new Date()

    const diffInSeconds =
      differenceInMilliseconds(
        now,
        parsedProcessingTimeData.updated_at_timestamp,
      ) / 1000

    const processingTime =
      diffInSeconds + parsedProcessingTimeData.prev_duration

    if (processingTime < 0) {
      log.warn(
        `Processing time KPI is unavailable because processing time for contact id ${contactIdForProcessingTime} is negative (=${processingTime}).`,
      )

      return 0
    }

    return parseFloat(processingTime.toFixed(2))
  }

  /**
   * Sets a processing time data object in local storage whenever the agent receives a new contact;
   * This can be either a new incoming contact from the Amazon Connect queue or when the agent is refreshing the page.
   */
  const setProcessingTimeData = () => {
    if (isNullish(contactIdForProcessingTime)) {
      return
    }

    const prevTimeData = contactStorageService.getProcessingTimeData()

    if (isNullish(prevTimeData)) {
      return contactStorageService.setProcessingTimeData(
        contactIdForProcessingTime,
        0,
      )
    }

    const isValidTimeData =
      contactIdForProcessingTime === prevTimeData.contactId

    // Overwrite the existing time data in local storage when receiving a new contact;
    // We do this to prevent outages if for some reason the processing time data in local storage is messed up
    // e.g. Failure post call report results in having the old time data object in local storage
    if (!isValidTimeData) {
      // TODO: restore this as Sentry warning when we have resolved: POC-5577,POC-5424 & POC-5675
      // eslint-disable-next-line no-console
      console.warn(
        "Setting the processing time data while there was a different contact id in local storage.",
        {
          contactId: contactIdForProcessingTime,
          prevTimeData,
        },
      )

      return contactStorageService.setProcessingTimeData(
        contactIdForProcessingTime,
        0,
      )
    }

    // Keep the previous duration of time spent on handling the contact
    return contactStorageService.setProcessingTimeData(
      contactIdForProcessingTime,
      prevTimeData.prev_duration,
    )
  }

  /**
   * Updates the duration that the agent spent on the contact in local storage whenever the agent leaves the call panel.
   * The reason for this: when coming back to the call panel we'll add the previous duration to the current duration.
   */
  const updateProcessingTimeData = () => {
    if (isNullish(contactIdForProcessingTime)) {
      return
    }

    const prevTimeData = contactStorageService.getProcessingTimeData()

    // Do not write a new processing time data object in local storage if we
    // are missing a previous one; we do this to prevent weird errors (such as when unmounting the useTrackProcessingTime hook)
    if (isNullish(prevTimeData)) {
      return
    }

    const isValidTimeData =
      prevTimeData.contactId === contactIdForProcessingTime

    if (!isValidTimeData) {
      const msg = `Attempting to update the processing duration for the wrong contact. Provided contact id: ${contactIdForProcessingTime}. Stored time data ${JSON.stringify(
        prevTimeData,
      )}.`

      return log.warn(msg, {
        contactId: contactIdForProcessingTime,
        prevTimeData,
      })
    }

    contactStorageService.updateProcessingDuration({
      contactId: contactIdForProcessingTime,
      duration: getProcessingTime(),
      previousTimeData: prevTimeData,
    })
  }

  const clearProcessingTimeData = () => {
    // Remove the processing time data
    contactStorageService.removeProcessingTimeData()
  }

  return {
    getProcessingTime,
    updateProcessingTimeData,
    clearProcessingTimeData,
    setProcessingTimeData,
  }
}

export { useProcessingTimeHook }
