import {
  ArrowDownUpIcon,
  ArrowDownWideNarrowIcon,
  ArrowUpNarrowWideIcon,
} from "lucide-react"

import EmptyContainer from "@/components/EmptyContainer"
import { PaginationBar } from "@/components/pagination/pagination-controls"
import { Button } from "@/components/ui/button"
import { Skeleton } from "@/components/ui/skeleton"
import {
  Table,
  TableCell,
  TableHead,
  TableSubHead,
} from "@/components/ui/table"
import { TooltipWrapper } from "@/components/ui/tooltip"
import { cn } from "@/helpers/classNames"
import { times } from "@/helpers/other"
import { SortOptions } from "@/hooks/table-sorting"

import { PaginationState } from "./pagination/pagination-state"

export type ColumnGroupDef<T> = {
  cols: ColumnDef<T>[]
  header: string
  headerTooltip?: string
  sortKey?: string // needed to enable sorting
}

export type ColumnDef<T> = {
  cell: (item: T, index: number) => JSX.Element
  className?: string | ((item: T) => string | undefined)
  id: string
  subHeader?: string
  subHeaderTooltip?: string
}

type Props<T> = Partial<PaginationState> & {
  columnGroups: ColumnGroupDef<T>[]
  emptyText?: string
  getItemId: (item: T) => string
  isLoading?: boolean
  items: T[]
  numberOfLoadingRows?: number
  sortOptions?: SortOptions
  toggleSort?: (key: string) => void
}

/**
 * A generic table component used as a primitive for all KPI pages
 * Passing `isLoading` true will render a skeleton loading state with the same column structure
 * */
export function DashboardTable<T>({
  columnGroups,
  emptyText,
  getItemId,
  isLoading,
  items = [],
  numberOfLoadingRows = 5,
  page,
  pageSize,
  sortOptions,
  toggleSort,
  total,
}: Props<T>) {
  const subHeaders = columnGroups.flatMap((group) =>
    group.cols.map((col) => ({
      header: col.subHeader,
      tooltip: col.subHeaderTooltip,
    })),
  )

  const showSubHeaders = subHeaders.some((subHeader) => subHeader.header)

  const tableBody = isLoading ? (
    <LoadingTableBody
      columnGroups={columnGroups}
      numberOfLoadingRows={numberOfLoadingRows}
    />
  ) : (
    <tbody>
      {items.map((item, index) => {
        return (
          <Row key={getItemId(item)} columnGroups={columnGroups} item={item}>
            {(col) => col.cell(item, index)}
          </Row>
        )
      })}
    </tbody>
  )

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto border border-neutral-300">
        <Table>
          <thead>
            <tr>
              {columnGroups.map((group, index) => {
                const { cols, header, headerTooltip, sortKey } = group
                const sortDirection =
                  sortKey && sortOptions?.id === sortKey
                    ? sortOptions?.direction
                    : undefined

                return (
                  <TableHead
                    key={index}
                    className={cn(index === 0 && "sticky left-0")}
                    colSpan={cols.length}
                  >
                    <div className="flex items-center justify-between">
                      <TooltipWrapper tooltip={headerTooltip}>
                        {header}
                      </TooltipWrapper>
                      {sortKey && toggleSort && (
                        <ToggleSortButton
                          direction={sortDirection}
                          onClick={() => toggleSort(sortKey)}
                        />
                      )}
                    </div>
                  </TableHead>
                )
              })}
            </tr>
            {showSubHeaders && (
              <tr>
                {subHeaders.map((subHeader, index) => (
                  <TableSubHead
                    key={index}
                    className={cn(index === 0 && "sticky left-0")}
                  >
                    <TooltipWrapper tooltip={subHeader.tooltip}>
                      {subHeader.header}
                    </TooltipWrapper>
                  </TableSubHead>
                ))}
              </tr>
            )}
          </thead>
          {tableBody}
        </Table>
      </div>
      {!isLoading && items?.length === 0 && (
        <EmptyContainer iconName="folder-empty" emptyText={emptyText} />
      )}
      {total && pageSize && page ? (
        <PaginationBar total={total} page={page} pageSize={pageSize} />
      ) : null}
    </div>
  )
}

function LoadingTableBody<T>({
  columnGroups,
  numberOfLoadingRows,
}: {
  columnGroups: ColumnGroupDef<T>[]
  numberOfLoadingRows: number
}) {
  return (
    <tbody>
      {times(numberOfLoadingRows).map((index) => (
        <Row key={index} columnGroups={columnGroups}>
          {() => <Skeleton className="h-6 w-24" />}
        </Row>
      ))}
    </tbody>
  )
}

function Row<T>({
  children,
  columnGroups,
  item,
}: {
  children: (col: ColumnDef<T>) => React.ReactNode
  columnGroups: ColumnGroupDef<T>[]
  item?: T
}) {
  return (
    <tr>
      {columnGroups.flatMap((group, groupIndex) =>
        group.cols.map((col, cellIndex) => (
          <TableCell
            key={col.id}
            className={cn(
              groupIndex === 0 && cellIndex === 0 && "sticky left-0",
              typeof col.className === "function"
                ? item && col.className(item)
                : col.className,
            )}
          >
            {children(col)}
          </TableCell>
        )),
      )}
    </tr>
  )
}

export function ToggleSortButton({
  direction,
  onClick,
}: {
  direction?: "asc" | "desc"
  onClick: () => void
}) {
  return (
    <Button
      size="icon"
      variant="ghost"
      className="hover:bg-transparent relative -right-2 size-6"
      onClick={onClick}
    >
      <SortIcon direction={direction} />
    </Button>
  )
}

function SortIcon({ direction }: { direction?: "asc" | "desc" }) {
  switch (direction) {
    case "asc":
      return (
        <ArrowUpNarrowWideIcon className="size-5 text-primary-300 hover:text-primary-300" />
      )
    case "desc":
      return (
        <ArrowDownWideNarrowIcon className="size-5 text-primary-300 hover:text-primary-300" />
      )
    default:
      return (
        <ArrowDownUpIcon className="size-4 text-neutral-500 hover:text-primary-300" />
      )
  }
}
