import { FunctionComponent, PropsWithChildren } from "react"
import { CreateAxiosDefaults } from "axios"

import { isNull } from "@/helpers/typeguards"

import {
  RequestInterceptors,
  ResponseInterceptors,
  useAxiosInstanceHook,
} from "./hook"
import { AxiosContext } from "./useAxiosClient"

type Props = PropsWithChildren<{
  config?: CreateAxiosDefaults
  requestInterceptors?: RequestInterceptors
  responseInterceptors?: ResponseInterceptors
}>

const AxiosInstanceProvider: FunctionComponent<Props> = ({
  children,
  config = {},
  requestInterceptors,
  responseInterceptors,
}) => {
  const { axiosClient } = useAxiosInstanceHook({
    config,
    requestInterceptors,
    responseInterceptors,
  })

  if (isNull(axiosClient)) {
    return null
  }

  return (
    <AxiosContext.Provider value={axiosClient}>
      {children}
    </AxiosContext.Provider>
  )
}

export default AxiosInstanceProvider
