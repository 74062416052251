import { format } from "date-fns"
import i18n from "i18next"

import { ColumnGroupDef, DashboardTable } from "@/components/DashboardTable"
import { PaginationState } from "@/components/pagination/pagination-state"
import { formatFullName } from "@/helpers/names"

import { NoCallStudent } from "./search-api"

type Props = PaginationState & {
  isLoading: boolean
  students: NoCallStudent[]
}
export function NoCallList({ students, ...otherProps }: Props) {
  const columnGroups: ColumnGroupDef<NoCallStudent>[] = [
    {
      header: i18n.t("campaigns.details.students.name"),
      cols: [
        {
          id: "fullName",
          cell: (student) => (
            <>{formatFullName(student.first_name, student.last_name)}</>
          ),
        },
      ],
    },
    {
      header: i18n.t("campaigns.details.students.email"),
      cols: [
        {
          id: "email",
          cell: (student) => <>{student.email}</>,
        },
      ],
    },
    {
      header: i18n.t("campaigns.preview.table.phoneNumber"),
      cols: [
        {
          id: "phoneNumber",
          cell: (student) => <>{student.phone_number}</>,
        },
      ],
    },
    {
      header: i18n.t("noCallList.columns.updatedAt"),
      cols: [
        {
          id: "updated_at",
          cell: (student) => (
            <>{format(new Date(student.updated_at), "yyyy/MM/dd HH:mm")}</>
          ),
        },
      ],
    },
  ]

  return (
    <DashboardTable
      columnGroups={columnGroups}
      items={students}
      getItemId={(student) => student.email}
      {...otherProps}
    />
  )
}
