import { FunctionComponent, MutableRefObject, useRef } from "react"

import { ChildrenProps } from "@/types/props"

import { CognitoTimeoutContext } from "./useCognitoTimeoutContext"

type Props = ChildrenProps

const CognitoTimeoutProvider: FunctionComponent<Props> = ({ children }) => {
  const accessTokenTimeoutRef: MutableRefObject<NodeJS.Timeout | undefined> =
    useRef(undefined)

  return (
    <CognitoTimeoutContext.Provider value={accessTokenTimeoutRef}>
      {children}
    </CognitoTimeoutContext.Provider>
  )
}

export default CognitoTimeoutProvider
