import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"

import Icon from "@/components/Icon"

const styles = {
  container: "flex justify-center pb-10 pt-40 w-full h-full",
  content:
    "max-w-md flex flex-col items-center gap-y-6 font-body-large-bold text-neutral-700",
  textContent: "text-center",
}

const Spinner: FunctionComponent = ({
  content,
  title,
}: {
  content?: string
  title?: string
}) => {
  const { t } = useTranslation()

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Icon name="spinner" size="lg" />
        <div className={styles.textContent}>
          <p>{title || t("global.initializingProfileTitle")}</p>
          <p>{content || t("global.initializingProfileSubtitle")}</p>
        </div>
      </div>
    </div>
  )
}

export default Spinner
