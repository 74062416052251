import { FunctionComponent, useRef } from "react"
import { createPortal } from "react-dom"
import { useSetAtom } from "jotai"

import Icon from "@/components/Icon"
import { modalModeAtom } from "@/helpers/atoms"
import { cn } from "@/helpers/classNames"
import { useClickOutside } from "@/hooks/clickOutside"
import { ChildrenProps } from "@/types/props"

const styles = {
  container:
    "absolute z-50 flex items-center justify-center inset-0 bg-overlay-default backdrop-blur-sm",
  modalCardContainer:
    "bg-neutral-100 transform-gpu transition-transform duration-300 max-h-3/4 w-128 rounded scale-100 overflow-y-auto pointer-events-auto",
  modalHeader: "p-2 flex justify-end items-center",
  closeIconContainer: (isDisabled: boolean) =>
    cn([
      "cursor-pointer",
      isDisabled ? "text-neutral-400" : "text-neutral-700",
    ]),
}

interface Props extends ChildrenProps {
  isCloseEnabled?: boolean
  onClose?: () => Promise<void>
}

const Modal: FunctionComponent<Props> = ({
  children,
  isCloseEnabled = true,
  onClose,
}) => {
  const setModalMode = useSetAtom(modalModeAtom)
  const modalCardRef = useRef<HTMLDivElement>(null)

  const closeModal = async () => {
    if (onClose) {
      await onClose()
    }

    setModalMode(null)
  }

  useClickOutside([modalCardRef], async () => {
    if (onClose) {
      await onClose()
    }
    isCloseEnabled && closeModal()
  })

  return createPortal(
    <div className={styles.container} translate="no">
      <div className={styles.modalCardContainer} ref={modalCardRef}>
        <div className={styles.modalHeader}>
          {isCloseEnabled && (
            <Icon
              size="sm"
              name="close"
              className={styles.closeIconContainer(!isCloseEnabled)}
              onClick={closeModal}
              isDisabled={!isCloseEnabled}
            />
          )}
        </div>
        {children}
      </div>
    </div>,
    document.body,
  )
}

export default Modal
