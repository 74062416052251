export const en = {
  translation: {
    global: {
      emptySubtitle: "There’s no available data to show.",
      loadingProfile: "Loading profile",
      initializingProfileTitle: "Please wait.",
      initializingProfileSubtitle: "We are collecting your information.",
      slowConnectionTitle: "Still Loading",
      slowConnectionSubtitle: "Please wait we are experiencing slow connection",
      loadingResetView: "Resetting the call view",
      errors: {
        unauthorized: "You are not authorized to access this page.",
      },
      peopleCount: "{{count}}", // used to include the kanji for people count
      button: {
        add: "Add",
        edit: "Edit",
        submit: "Submit",
        save: "Save",
        update: "Update",
        login: "Login",
        logout: "Logout",
        skip: "Skip",
        accept: "Accept",
        acceptInbound: "Accept",
        endCall: "End Call",
        hold: "Hold",
        resume: "Resume",
        mute: "Mute",
        unmute: "Unmute",
        endTask: "Next contact",
        call: "Call",
        addStatus: "Add Status",
        cancel: "Cancel",
        loading: "Loading",
        savingReport: "Saving report",
        resetPassword: "Request new password",
        refreshPage: "Refresh page",
        showMore: "Show more",
        showLess: "Show less",
        goBack: "Go back",
        close: "Close",
        download: "Download",
        upload: "Upload",
        moreActions: "More",
        apply: "Apply",
        wontCall: "Won't call",
        confirm: "Confirm",
        confirmAndReturn: "Confirm and return",
        clickToCopy: "Click to copy",
        copied: "Copied!",
      },
      search: {
        action: "Search",
        noItemsFound: "No items found",
        selectedValues: "{{count}} selected",
      },
      modals: {
        skipTask: {
          title: "Are you sure you want to skip?",
          description: "This student will be moved to the end of the queue.",
        },
        abandonCallReport: {
          title: "Are you sure you want to abandon the call report?",
          description: "All changes will be lost.",
        },
        goOffline: {
          title: "You have a call in progress",
          description:
            "Are you sure you want to skip this call and go offline?",
          callError: "You can't go offline while you have a call in progress.",
        },
        redirect: {
          title: "Your must be Offline to view this page.",
          description:
            "If you want to visit this page, please go back and set yourself Offline first. This may require skipping the current call.",
        },
        microphoneNotShared: {
          title: "Please connect your microphone",
          win: {
            subtitle: "Windows only",
            button: "Windows only",
            text: [
              "Click the speaker icon in the bottom right of the taskbar.",
              "Click 'Open Volume Mixer'.",
              "A list of available audio output devices will appear. Select the device you want to use for all system sounds, including Chrome.",
            ],
          },
          mac: {
            subtitle: "Mac only",
            button: "Mac only",
            text: [
              "Click the Apple menu in the top left of the screen.",
              "Click 'System Preferences'.",
              "Click 'Sound'.",
              "Click the 'Output' tab. Select the desired audio output device from the list.",
            ],
          },
        },
        browserError: {
          title: "Your browser is not supported, so calls cannot be made.",
          text: [
            "Calliope is not supported on your current browser.",
            "To make calls, please use Google Chrome, Microsoft Edge, or Mozilla Firefox.",
          ],
        },
        manualCallLeaveConfirm: {
          title: "Do you want to leave the page and end the call?",
          description:
            "Leaving the page will end the call. In this case, the student's status and call schedule will be kept up to date.",
          confirmation: "Are you sure you want to leave this page?",
          submit: "Leave the page and end the call",
        },
        manualCallFinisher: {
          title: "Unfinished Call Detected",
          description: "Unfinished work detected, we will clean up for you...",
          submit: "Done",
        },
      },
      datepicker: {
        placeholder: "Select a date",
        rangePlaceholder: "Select a date range",
        timeLabel: "Time",
        today: "Today",
        thisWeek: "This week",
        last7days: "Last 7 days",
        thisMonth: "This month",
        last30days: "Last 30 days",
      },
    },
    sidebarMenu: {
      callPanel: "Call Panel",
      campaigns: "Campaigns",
      noCallList: "NG List",
      performance: "Performance",
      syncAgents: "Sync Agents",
      taskRenewal: "Task Renewal",
    },
    header: {
      admin: "Admin",
      agent: "Agent",
      callCenterManager: "Call Center Manager",
      callCenterManagerAndAgent: "Call Center Manager and Agent",
      qualityAnalyst: "Quality Analyst",
      agentSelectOptions: {
        routable: "Available",
        offline: "Offline",
        initializing: "Initializing",
      },
      language: "Language",
      logout: "Logout",
    },
    campaigns: {
      list: {
        title: "Campaigns",
        name: "Name",
        studentCount: "Student Count",
        studentsToBeCalled: "To be called today",
        status: "Status",
        create: "Create",
      },
      search: {
        activeOnly: "Show only active campaigns",
      },
      details: {
        viewList: "View list",
        lastUpdate: "Last update",
        currentNumbers: "Student numbers",
        viewStatuses: "View statuses",
        exportCSV: {
          action: "Export to CSV",
          loading: "Preparing your CSV file...",
          success: "Click on the following link to download the CSV file:",
          download: "Download CSV",
        },
        importCSV: {
          action: "Import entries CSV file",
          errorRow: "Row {{row}}: {{message}}",
          errorRowColumn: "Row {{row}}, column {{column}}: {{message}}",
        },
        search: {
          universities: "All universities",
          statuses: "All statuses",
        },
        students: {
          name: "Name",
          email: "Email",
          contactDate: "Contact Date",
          contactCount: "Number of Contacts",
          currentStatus: "Latest status",
          comment: "Comment",
        },
        actions: {
          editComment: {
            label: "Edit History",
            success: "The history was edited successfully.",
            error: "Unable to edit the history",
          },
        },
      },
      edit: {
        breadcrumb: "Edit",
      },
      create: {
        title: "New campaign",
        breadcrumb: "Create",
        queuePlaceholder: "Select a queue",
        errors: {
          MISSING_OUTBOUND_CALLER_NUMBER_ID:
            "This queue is missing an outbound caller number",
        },
      },
      statuses: {
        starting: {
          label: "Activating",
        },
        active: { label: "Active" },
        paused: { label: "Paused" },
        stopping: { label: "Pausing" },
        "starting-failure": {
          label: "Activation failed",
          explanation: [
            "Failed to start the campaign.",
            "Please contact the manager.",
          ],
        },
        "stopping-failure": {
          label: "Pausing failed",
          explanation: [
            "Failed to stop the campaign.",
            "Please contact the manager.",
          ],
        },
      },
      actions: {
        pause: "Pause",
        resume: "Activate",
      },
      dialogs: {
        error: "Unable to change the status of the campaign",
        pause: {
          title: "Pause campaign",
          description:
            "Confirming this action will immediately stop all outgoings related to the campaign",
          confirm: "Are you sure you want to pause this campaign?",
          success: "The campaign has been successfully paused.",
        },
        resume: {
          title: "Activate campaign",
          confirm: [
            "Are you sure you want to activate this campaign?",
            "This process may take a long time, especially if multiple campaigns are being stopped or started.",
            "Please click the refresh button to see the current status.",
          ],
          success: "The campaign has been successfully activated.",
        },
        statusUpdate: {
          addTitle: "Append Status",
          addButton: "Append",
          successDescription: "New status was appended successfully.",
          failedDescription: "Unable to append the new status.",
        },
      },
      filters: {
        title: "Filters",
        add: {
          short: "Add",
          long: "Add filter",
          smartFilters: "Smart filters",
          basicFilters: "Basic filters",
          manualAdditions: "Manual Additions",
        },
        shortSummary: "{{label}} ({{count}})",
        channels: {
          label: "QA Channels",
          short: "Channel",
          placeholder: "Pick a channel",
          add: "Add channel",
          remove: "Remove channel",
          operators: {
            and: "AND",
            or: "OR",
            "except-for": "None",
          },
          apply: "Apply filters",
        },
        university: {
          label: "University",
          selectedFilters: "{{count}} filters",
          list: "University List",
          group: "Groups",
          include: "Include",
          exclude: "Exclude",
        },
        graduation_year: {
          label: "Graduation Year",
        },
        major: {
          label: "Major",
        },
        selected_phone_numbers: {
          label: "Phone Numbers",
          header: "Add Students Manually",
          explanation:
            "Enter phone numbers to manually add students to the campaign.",
          details: [
            "If you want to add multiple students at once, enter one phone number per line.",
            "Use half-width numeric characters and hyphens only.",
          ],
          submit: "Preview",
          errors: {
            DUPLICATE: "Duplicate phone number",
            INVALID_PHONE_NUMBER: "Invalid input format",
            NOT_FOUND: "No matching students found",
          },
          preview: {
            header: "Students preview",
            noStudentsFound: "No students found!",
            found: "students will be added to the filter",
            errors:
              "items have errors. Errors will not be reflected in the filter",
            onlyErrors: "Show errors only",
          },
        },
        department_group: { label: "Department" },
        gender: { label: "Gender" },
        selected: "{{propertyName}}: {{count}} selected",
        question: {
          placeholder: "Pick a question",
        },
        answer: {
          placeholder: "Pick an answer",
        },
        noFilterSelected: "None",
        success: "Campaign saved successfully",
      },
      priorityFilters: {
        title: "Priority Filters",
        warningNoFilters: "Please select filters first",
      },
      preview: {
        table: {
          title: "{{count}} students found",
          noResults: "No students found",
          lastName: "Last Name",
          firstName: "First Name",
          phoneNumber: "Phone Number",
          channels: "QA Channels",
          university: "University",
          department: "Department",
          major: "Major",
          graduationYear: "Graduation Year",
          gender: "Gender",
        },
        emptyState: "Add filters to see the list of students",
      },
    },
    entries: {
      title: "Entries Management",
      upload: {
        title: "Add More Entries",
        panelTitle: "To add multiple results, please follow the steps below:",
        instruction1: "Download this template",
        instruction2:
          "Edit the additional files according to the following steps:",
        stepText1: "Enter the student's phone number",
        stepText2: "Input the result of the entry from below:",
        instruction3: "Upload this CSV file",
        noFilePlaceholder: "No file upload",
        uploadButtonText: "Upload File",
        cancelButtonText: "Cancel and Return to the List",
        confirmButtonText: "Confirm Upload",
        modal: {
          errorTitle: "Errors found ({{count}})",
          successTitle: "No errors!",
          successDescription:
            "{{count}} entry result(s) have been updated. To see the updated student information, please go to the student information list.",
        },
      },
    },
    performance: {
      export: {
        csvDownload: "Download CSV",
        confirmTitle: "Please Confirm Before Downloading",
        dateSelectText: "Select Date for Downloading",
        campaignConfirmText: "Selected Campaign",
        allCampaigns: "All Campaigns will be selected",
        note: "Note",
        noteText: "The download campaign data includes the following:",
        downloadRequestButton: "Request Download",
        downloadButton: "Download",
        loadingTitle: "Preparing...",
        loadingDescription:
          "CSV file is being prepared. Please wait for a moment.",
        successTitle: "Prepared for Download",
        successDescription:
          "The CSV file is ready for download. Please click the button below to download.",
      },
      tabs: {
        campaign: "Campaigns",
        staff: "Staff",
        personal: "Personal",
      },
      placeholder: {
        search: "Search",
      },
      table: {
        campaignName: "Campaign Name",
        agentName: "Name",
        deletedUser: "[User Deleted]",
      },
      detail: {
        agentTitle: "Staff Performance",
        campaignTitle: "Campaigns Detail",
        callsPerHour: "Calls Per Hour",
        creditForEntryConfirmedCVR: "Credit for Entry Confirmed CVR",
        totalProcessingTime: "Total Processing Time",
      },
      error: {
        invalidFormat: "There was a problem displaying the data",
      },
    },
    syncAgents: {
      description:
        "To synchronize with the Amazon connect database and update your staff information, click the button below.",
      buttonText: "Sync Agents",
      syncing: "Syncing...",
      success: "Agents have been successfully synchronized",
      error: "Failed to synchronize agents",
      latestUpdateAt: "Latest Updated: {{date}}",
    },
    dashboard: {
      title: "Metrics",
      tabs: {
        performance: "Performance",
        statuses: "Status",
      },
      csv: {
        exportAction: "Export to CSV",
        exportLoading: "Preparing CSV...",
        downloadAction: "Download CSV",
      },
      selectOptions: {
        values: {
          allStaff: "All Staff",
        },
        labels: {
          staffPerformance: "Staff Performance",
          campaignPerformance: "Campaign Performance",
        },
        searchPlaceholder: "Search",
        selectPlaceholder: "Select",
      },
      table: {
        header: {
          lastName: "Last Name",
          firstName: "First Name",
          numberOfCalls: "Number of Calls",
          connectedCalls: "Connected Calls",
          entries: "Entries",
          actualEntries: "Actual entries",
          login: "Login",
          ringTime: "Ring Time",
          talkTime: "Talk Time",
          operation: "Operation",
          grandTotal: "Total",
          totalNumber: "Total",
          totalTime: "Total",
          hourly: "Hourly",
          average: "AVG",
          rate: "Rate",
        },
        values: {
          hours: "hours",
        },
      },
    },
    ids: {
      taskId: "Task ID:",
      voiceId: "Voice ID:",
      uuid: "UUID:",
    },
    callPanel: {
      title: "Student Information",
      loadingSubtitle: "Preparing for the next call.",
      incomingCall: "Incoming Call",
      emptyQueue: {
        subtitle: "Thank you for your hard work!",
        cardTitle: "Today's call work is over.",
        cardText:
          "Please set your status to offline before turning off your computer.",
      },
      resetState: {
        title: "An unexpected error has occurred",
        subtitle: "Please refresh the page to continue.",
      },
      status: {
        connecting: "Connecting",
        error: "Error",
        missedCall: "Missed Call",
        missedCallAgentState: "Call connection issue: network or microphone",
        missedTask: "Missed Task",
        rejectedCall: "Call rejected",
        connected: "Connected",
        acw: "Call Result",
        skipped: "Skipped",
      },
      report: {
        title: "Call Status Editing",
      },
      phoneNumber: "Phone Number",
      reason: {
        invalidQueueARN:
          "Cannot make outbound call due to configuration issue of the system.",
        disabledCampaign:
          "You cannot call this student because the campaign is disabled.",
      },
      callErrorCard: {
        networkError: {
          title: "Network Error",
          description: [
            "A network error occurred.",
            "If the issue persists, please try the following solutions:",
            [
              "Refresh the page",
              "Restart the browser",
              "Restart your computer",
            ],
          ],
        },
        browserError: {
          title: "Browser not supported",
          description: [
            "Calliope is not supported on your current browser.",
            "To make calls, please use Google Chrome, Microsoft Edge, or Mozilla Firefox.",
          ],
        },
        micPermissionError: {
          title: "Microphone Connection Error",
          description: [
            "Please connect a microphone to your PC to continue making calls.",
          ],
          button: "Open Instructions",
        },
        unexpectedError: {
          title: "An unexpected error",
          description: [
            "An unexpected error occurred.",
            "If the issue persists, please try the following solutions:",
            [
              "Refresh the page",
              "Restart the browser",
              "Restart your computer",
            ],
          ],
        },
        missedCallNetworkError: {
          title: "Connection Error",
          description: [
            "A connection error occurred, and the call was terminated.",
            "Please enter the call result and save it, or return to the previous screen and make the call again by clicking 'Go back.'",
          ],
        },
      },
    },
    caseHistory: {
      entorId: "Entor ID",
      nameFurigana: "Furigana",
      university: "University",
      department: "Department",
      graduation: "Graduation Year",
      question: "What was my focus as a student?",
      agent: "Agent",
      campaign: "Campaign",
      callStatus: "Current call status",
      contactHistory: "Contact history of all campaigns",
      email: "Email",
      hiddenStatus: {
        scheduled: "Scheduled",
        renewed: "Renewed",
        recovery: "Recovery",
      },
      tabs: {
        history: "History",
        numberOfItems: "Entries {{count}}",
        detailedInformation: "Detailed Information",
      },
      activityFeed: {
        staff: "Agent",
        comments: "Memo",
        lastUpdate: "Last Update",
        nextCall: "Next Call",
        status: "Status",
        callType: {
          label: "Call Type",
          values: {
            noCall: "No Call",
            outbound: "Outbound Call",
            inbound: "Inbound Call",
          },
        },
      },
    },
    sidePanelMetrics: {
      open: "Today",
      title: ["Your Metrics", "Today"],
      description: [
        "The data is based only on the campaign you are currently working on",
        "Here’s the campaign list you are assigned to now:",
      ],
      callsPerHour: "Calls/h",
      connectedCalls: {
        perHour: "CC/h",
        rate: "CC Rate",
      },
      conversions: {
        total: "CV",
        rate: "CVR",
      },
      entries: {
        total: "Entries",
        rate: "Entry Rate",
      },
    },
    callResult: {
      title: "Call Result",
      status: "Status",
      reason: "Reason",
      scheduleCall: "Schedule a follow-up call",
      memo: "Memo",
      placeholder: "Type here",
      success: "Call result submitted successfully",
      pcrTitle: "Status Update",
    },
    callHistory: {
      heading: "Call History",
      searchPlaceholder: "Search name",
    },
    login: {
      emailLabel: "Email",
      emailPlaceholder: "name@domain.com",
      passwordLabel: "Password",
      passwordPlaceholder: "password",
      subtitle: "Login to your account",
      help: "Need help?",
      contactManager: "Please contact the manager.",
      forgotPassword: "Forgot your password?",
      loadingText: "Verifying information",
    },
    resetPassword: {
      request: {
        title: "Forgot your password?",
        subtitle:
          "Enter your email in the form below. A link to reset your password will be delivered to your email address.",
        loadingText: "Verifying information",
        success:
          "An email has been sent to your inbox with instructions to reset your password.",
        tryAgain: "Didn't get an email?",
      },
      confirm: {
        title: "Set a new password",
        subtitle: "Please set a password following the rules below.",
        bulletPoints: [
          "Password must be at least 8 characters long",
          "Use uppercase and lowercase letters",
          "Do not use spaces",
          "Include at least one of the following characters",
        ],
        subBulletPoints: ["Numbers", "Special characters"],
        confirmPasswordLabel: "Password (confirm)",
        loadingText: "Verifying information",
        success: "Password has been successfully updated.",
      },
    },
    updateCheckerNotification: {
      title: "An application update is available!",
      description: "Click on Update button to reload the window.",
      ignore: "Later",
      update: "Update now",
    },
    noCallList: {
      columns: {
        updatedAt: "Last Update",
      },
      importCSV: {
        action: "Upload CSV",
        intro: "Upload a CSV file to update the NG list.",
        step1: "Download the template",
        step2: "Edit the file with the following details",
        details: [
          "Enter the phone number in the first column, the email address in the second column.",
          "Enter TRUE in the No Call column for students you want to add to the NG list.",
          "Enter FALSE in the No Call column for students you want to remove from the NG list.",
        ],
        step3: "Pick the CSV to upload",
        explanations: "Errors will be shown in the next screen",
        globalError: "Unable to upload {{filename}}",
        errors: {
          TOO_MANY_ROWS:
            "Total row count exceeds {{max}}, please upload a smaller CSV.",
          INVALID_CSV: "Invalid CSV file",
          PHONE_NUMBER_INVALID: "Invalid phone number {{phoneNumber}}",
          PHONE_NUMBER_NOT_FOUND: "Phone number not found {{phoneNumber}}",
          PHONE_NUMBER_DUPLICATE: "Duplicate phone number {{phoneNumber}}",
          EMAIL_INVALID: "Invalid email {{email}}",
          EMAIL_NOT_FOUND: "Email not found {{email}}",
        },
      },
    },
    taskRenewal: {
      heading: "Task Renewal Schedule",
      days: "< {{days}} days",
      oneDay: "< 24 h",
      tasks: "{{tasks}} tasks",
    },
    errors: {
      errorPage: {
        goBack: "Go back to calliope.co.jp",
        notFoundError: "Page not found",
        notFoundDescription: "The page you are looking for was not found.",
        unexpectedError: "Unexpected error",
        unexpectedErrorDescription: "Please log out and log in again. ",
      },
      form: {
        required: "{{fieldName}} is required",
        wrongDate: "Please set a correct date",
        unknown: "{{fieldName}} is invalid",
        validStartDateTime: "{{fieldName}} needs to be a future date and time",
      },
      login: {
        passwordFormat: "Incorrect password format.",
        emailFormat: "Incorrect email format.",
        userNotFoundOrUnauthorized: "Incorrect user or password",
        unknownError: "Something went wrong",
        passwordAttemptsExceeded: "Password attempts exceeded",
      },
      resetPassword: {
        unknownError: "Something went wrong. Please try again later.",
        LimitExceededException:
          "You have exceeded the limit of password reset attempts.",
        UserNotFoundException: "Email not found",
        mismatchingPassword: "Passwords do not match",
        getNewCode:
          "Something went wrong. Please request a new code to reset your password.",
      },
      callPanel: {
        nextContactError: "Failed to switch to the next contact",
        callError: "Failed to make call",
        answerCallError: "Failed to answer call",
        skipCallError: "Failed to skip call",
        endCallError: "Failed to end call",
        resumeCallError: "Failed to resume call",
        holdCallError: "Failed to hold call",
        muteCallError: "Failed to mute call",
        unmuteCallError: "Failed to unmute call",
        statusUpdateError: "Failed to update status",
      },
      callResult: {
        submitError: "Failed to submit call result",
      },
    },
  },
} as const

// A recursive type that reflects the nested structure of the translations... isn't TS crazy? :)
type NestedTranslations<T> = {
  [K in keyof T]: T[K] extends object ? NestedTranslations<T[K]> : string
}

// Type to be used by other language files, English being the reference
export type Translations = NestedTranslations<typeof en>
