import { z } from "zod"

import { metricMetaSchema } from "./api/metrics"

const QuestionAnswerItemSchema = z.object({
  answers: z.array(z.string()),
  operator: z.enum(["and", "except-for", "or"]),
  question: z.string(),
})
export type QuestionAnswerItem = z.infer<typeof QuestionAnswerItemSchema>

const FilterSelectionChannelSchema = z.object({
  channel_id: z.number(),
  questionsAnswers: z.array(QuestionAnswerItemSchema),
})
export type FilterSelectionChannel = z.infer<
  typeof FilterSelectionChannelSchema
>

const FilterSelectionUniversitySchema = z.object({
  operator: z.enum(["exclude", "include"]),
  type: z.enum(["group", "university"]),
  values: z.array(z.string()),
})
export type FilterSelectionUniversity = z.infer<
  typeof FilterSelectionUniversitySchema
>

const PHONE_NUMBER_VALIDATION_STATUSES = {
  duplicate: "DUPLICATE",
  invalid: "INVALID_PHONE_NUMBER",
  notFound: "NOT_FOUND",
  success: "SUCCESS",
} as const

export const phoneNumberValidationResultSchema = z.object({
  first_name: z.string().nullable(),
  last_name: z.string().nullable(),
  normalized_phone_number: z.string().nullable(),
  phone_number: z.string(),
  validation_status: z.nativeEnum(PHONE_NUMBER_VALIDATION_STATUSES),
})

export type PhoneNumberValidationResult = z.infer<
  typeof phoneNumberValidationResultSchema
>

export const FilterSelectionsSchema = z.object({
  channels: z.optional(z.array(FilterSelectionChannelSchema)),
  department_group: z.optional(z.object({ values: z.array(z.string()) })),
  gender: z.optional(z.object({ values: z.array(z.string()) })),
  graduation_year: z.optional(z.object({ values: z.array(z.number()) })),
  major: z.optional(z.object({ values: z.array(z.string()) })),
  selected_phone_numbers: z.optional(
    z.array(phoneNumberValidationResultSchema), // See below for further clarification
  ),
  university: z.optional(z.array(FilterSelectionUniversitySchema)),
})
export type FilterSelections = z.infer<typeof FilterSelectionsSchema>

const lockStatuses = [
  "starting",
  "stopping",
  "starting-failure",
  "stopping-failure",
] as const

export const campaignLockSchema = z.object({
  campaign_id: z.string(),
  enabled: z.boolean(),
  lock_status: z.enum(lockStatuses).nullish(),
})

export const CampaignSchema = z.object({
  campaign_id: z.string(),
  enabled: z.boolean(),
  lock_status: z.enum(lockStatuses).nullish(),
  number_of_students_matched: z
    .object({
      n_matching_students: z.number().nullable(),
      n_student_to_be_scheduled: z.number().nullable(),
    })
    .optional(),
  created_at: z.string(),
  updated_at: z.string(),
  metrics: z
    .object({
      TotalStudentsCanBeCalledToday: z.number().nullable(),
      TotalStudentsInCampaign: z.number().nullable(),
    })
    .optional(),
})

export const CampaignListResponseSchema = z.object({
  data: z.object({
    campaigns: z.array(CampaignSchema),
    metrics_metadata: metricMetaSchema,
    total: z.number(),
  }),
})

export const CampaignDetailsSchema = CampaignSchema.extend({
  campaign_filter_selection: z.optional(FilterSelectionsSchema), // TODO remove optional after data is migrated
  priority_filter_selection: z.optional(z.nullable(FilterSelectionsSchema)),
  campaign_management_stats: z.object({
    metrics: z.object({
      TotalStudentsCanBeCalledToday: z.number().nullable(),
      TotalStudentsInCampaign: z.number().nullable(),
    }),
  }),
})

export const CampaignDetailsResponseSchema = z.object({
  data: CampaignDetailsSchema,
})

export type Campaign = z.infer<typeof CampaignSchema>

export type CampaignDetails = z.infer<typeof CampaignDetailsSchema>

export type CampaignStatus = (typeof lockStatuses)[number] | "active" | "paused"

export type CampaignMetricsMeta = z.infer<typeof metricMetaSchema>
