import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"

import { Button } from "@/components/ui/button"
import {
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"

export const FinisheManualCallPrompt: FunctionComponent<{
  isPending: boolean
  onClose: (val: boolean) => void
}> = ({
  isPending,
  onClose,
}: {
  isPending: boolean
  onClose: (val: boolean) => void
}) => {
  const { t } = useTranslation()

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>{t("global.modals.manualCallFinisher.title")}</DialogTitle>
      </DialogHeader>
      <DialogBody>
        {t("global.modals.manualCallFinisher.description")}
      </DialogBody>
      <DialogFooter>
        <Button isLoading={isPending} onClick={() => onClose(true)}>
          {t("global.modals.manualCallFinisher.submit")}
        </Button>
      </DialogFooter>
    </DialogContent>
  )
}
