import { useTranslation } from "react-i18next"

import { CurrentStatusPill } from "@/components/status-pill"
import { CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { CopyIdButton } from "@/components/ui/copy-id-button"
import { formatFullName } from "@/helpers/names"
import * as customConnectPanelValues from "@/pages/CustomConnectPanel/value"

import { CaseWithActivityFeed } from "../student-details-api"

export function StudentProfileSection({
  caseData,
  studentProfile: student,
}: {
  caseData: CaseWithActivityFeed
  studentProfile: customConnectPanelValues.Decoder.StudentProfile
}) {
  const { t } = useTranslation()

  return (
    <>
      <CardHeader>
        <div className="font-label-small flex items-center gap-1 text-neutral-500">
          <span>{t("caseHistory.entorId")}:</span>
          <span>
            <CopyIdButton
              uuid={student.legacy_id.toString()}
              className="text-xs font-bold"
            />
          </span>
        </div>
        <CardTitle>
          {formatFullName(student.first_name, student.last_name)}
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-2 gap-4">
          <StudentProfileItem
            label={t("caseHistory.nameFurigana")}
            value={student.last_name_kana + " " + student.first_name_kana}
          />

          <StudentProfileItem
            label={t("caseHistory.callStatus")}
            value={
              <CurrentStatusPill
                current={caseData.case.current_status}
                latestActionTaken={caseData.case.latest_status_action_taken}
              />
            }
          />
          <StudentProfileItem
            label={t("caseHistory.university")}
            value={student.university}
          />
          <StudentProfileItem
            label={t("caseHistory.department")}
            value={student.department}
          />
          <StudentProfileItem
            label={t("caseHistory.graduation")}
            value={student.graduation_year}
          />
          <StudentProfileItem
            label={t("caseHistory.email")}
            value={student.email}
          />
        </div>
      </CardContent>
    </>
  )
}

function StudentProfileItem({
  label,
  value,
}: {
  label: React.ReactNode
  value: React.ReactNode
}) {
  return (
    <div>
      <label className="font-label-small mb-2 block text-neutral-600">
        {label}
      </label>
      <div className="break-all text-sm">{value}</div>
    </div>
  )
}
