import { createContext, useContext } from "react"
import { AxiosInstance } from "axios"

import { isNull } from "@/helpers/typeguards"

const AxiosContext = createContext<AxiosInstance | null>(null)

const useAxiosClientContext = (): AxiosInstance => {
  const context = useContext(AxiosContext)

  if (isNull(context)) {
    throw new Error(
      "This component must be used inside an <AxiosContext.Provider>...</AxiosContext.Provider> block.",
    )
  }

  return context
}

export { AxiosContext, useAxiosClientContext }
