import { hasProperty, isString } from "@/helpers/typeguards"

const pseudoACWStatus = [
  "FailedConnectAgent",
  "FailedConnectCustomer",
  "MissedCallCustomer",
  "BadAddressAgent",
  "BadAddressCustomer",
  "LineEngagedAgent",
  "LineEngagedCustomer",
]

/**
 * A class for custom errors.
 *
 * @remarks
 * Based on:
 * https://www.codegrepper.com/code-examples/javascript/create+error+message+typescript
 */
class CustomError extends Error {
  /**
   * Creates a CustomError.
   *
   * @param errorLikeInput - An object with a `.message` potentially stacktrace that can be converted to an error.
   * @param params - Any additional parameters to pass to the Error constructor.
   */
  constructor(errorLikeInput: unknown, ...params: string[]) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params)

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CustomError)
    }
    Object.assign(this, errorLikeInput)
  }
}

/**
 * Convert UNKNOWN object to an Error (with `.message` attribute/`.name` attributes if available).
 *
 * @param error - The error to convert to an Error type.
 * @returns An Error type version of the error.
 */
const toError = (error: unknown): Error => {
  if (error instanceof Error) {
    return error
  }

  if (typeof error === "string") {
    return new Error(error)
  }

  // If the input as a message attribute or name attribute, make an Error with stack trace and message/name.
  if (
    hasProperty("message", isString)(error) ||
    hasProperty("name", isString)(error)
  ) {
    // Error was not an Error type but is error-like, so converting to CustomError type
    return new CustomError(error)
  }

  try {
    // 'error' object was not Error-like, so just stringify it as an Error type
    return new Error(JSON.stringify(error))
  } catch {
    // fallback in case there's an error stringifying the error
    // like with circular references for example.
    // error object was not Error-like or JSON-stringifiable so do the best we can with it as an Error
    return new Error(String(error))
  }
}

const connectErrorHandler = (err: unknown, message: string) => {
  if (typeof err === "string") {
    return new Error(`${message}: ${err}`)
  }

  if (err instanceof Error) {
    return new Error(`${message}: ${err.message}`, { cause: err })
  }

  return new Error(message)
}

interface CallReportData {
  call_contact?: {
    call_duration_seconds: number | null
    contact_id: string
    created_at: Date
    type: string
  }
  campaign_id: string
  comment?: {
    comment: string
    created_at: Date
  }
  scheduled_call_datetime?: Date | null
  status_event: {
    call_attempted: boolean
    created_at: Date
    event_name: string
    processing_duration_seconds: number
  }
  stop_most_recent_task: boolean
  student_id: string
}

class CallReportError extends Error {
  constructor(
    message: string,
    public callReportData: Record<string, unknown>,
    options?: ErrorOptions,
  ) {
    super(message, options)
    this.name = "CallReportError"
  }
}

const createCallReportError = (
  err: unknown,
  callReportData: CallReportData,
) => {
  const callReportContext = {
    ...callReportData,
    comment: {
      ...callReportData.comment,
      created_at: callReportData.comment?.created_at.toISOString(),
    },
    scheduled_call_datetime:
      callReportData.scheduled_call_datetime?.toISOString() ?? null,
    status_event: {
      ...callReportData.status_event,
      created_at: callReportData.status_event.created_at.toISOString(),
    },
  }

  const errorInstance = toError(err)

  return new CallReportError(
    `[call-report] Failed to save call report while moving to next contact: ${errorInstance.message}`,
    callReportContext,
    { cause: errorInstance },
  )
}

export {
  CallReportError,
  connectErrorHandler,
  createCallReportError,
  pseudoACWStatus,
  toError,
}
