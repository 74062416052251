import { CallViewHistoryTabs } from "@/components/ContactProfileView/history-tabs"
import { useContactProfileHook } from "@/components/ContactProfileView/hook"
import * as connectPanelValue from "@/pages/CustomConnectPanel/value"

import { StudentProfileSection } from "../shared/student-profile-section"
import { TwoColsLayout } from "../shared/two-cols-layout"
import { CaseWithActivityFeed } from "../student-details-api"

/** Student page layout when calling, made of two columns */
export function StudentCallView({
  callPanel,
  caseData,
  historyItem,
  studentProfile,
}: {
  callPanel: React.ReactNode
  caseData: CaseWithActivityFeed
  historyItem: connectPanelValue.ActivityFeedEntry.ActivityFeedEntryType | null
  studentProfile: connectPanelValue.Decoder.StudentProfile
}) {
  const { historyEntries } = useContactProfileHook({
    currentCase: caseData,
    studentProfile: null,
    historyItem: historyItem
      ? {
          ...historyItem,
          campaign_id: caseData.case.campaign_id,
        }
      : null,
  })
  const studentCase = caseData.case

  return (
    <TwoColsLayout
      main={
        <div className="border-b">
          <div className="rounded border bg-neutral-100">
            <StudentProfileSection
              studentProfile={studentProfile}
              caseData={caseData}
            />
          </div>
          <CallViewHistoryTabs
            studentCase={studentCase}
            historyEntries={historyEntries}
          />
        </div>
      }
      aside={callPanel}
    />
  )
}
