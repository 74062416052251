import { NonNullableProperties } from "@/types/helpers"
import { If } from "@/types/logic"
import { ModalMode } from "@/types/modes"
import { TupleToUnion } from "@/types/tuples"
import { IsOpenString } from "@/types/typechecks"

type TypeGuard<GuardedType> = (value: unknown) => value is GuardedType

type NonEmptyArray<T> = [T, ...T[]]

const not =
  <ExcludedType>(typeGuard: TypeGuard<ExcludedType>) =>
  <T>(value: ExcludedType | T): value is T =>
    !typeGuard(value)

const isNull = (value: unknown): value is null => value === null
const isUndefined = (value: unknown): value is undefined =>
  typeof value === "undefined"
const isNullish = (value: unknown): value is null | undefined =>
  isNull(value) || isUndefined(value)

const isNonNull = not(isNull)
const isDefined = not(isUndefined)
const hasValue = not(isNullish)

const isString = (value: unknown): value is string => typeof value === "string"
const isNumber = (value: unknown): value is number =>
  typeof value === "number" && !isNaN(value)
const isBoolean = (value: unknown): value is boolean =>
  typeof value === "boolean"

const isArray = (value: unknown): value is unknown[] => Array.isArray(value)
const isArrayOf =
  <ItemType>(typeGuard: TypeGuard<ItemType>) =>
  (value: unknown): value is ItemType[] => {
    return (
      isArray(value) &&
      value
        .map((item) => typeGuard(item))
        .reduce(
          (isFullyGuarded, isItemGuarded) => isFullyGuarded && isItemGuarded,
          true,
        )
    )
  }

const isObject = (value: unknown): value is Record<string, unknown> =>
  !isNull(value) && typeof value === "object"
const hasProperty =
  <Key extends string, PropertyType = unknown>(
    key: Key,
    typeGuard?: TypeGuard<PropertyType>,
  ) =>
  (
    value: unknown,
  ): value is If<IsOpenString<Key>, unknown, { [J in Key]: PropertyType }> => {
    return (
      !isNullish(value) &&
      isObject(value) &&
      Object.prototype.hasOwnProperty.call(value, key) &&
      (isUndefined(typeGuard) || typeGuard(value[key]))
    )
  }

const hasNonNullableProperties = <T>(
  value: T,
): value is NonNullableProperties<T> => {
  return (
    isObject(value) &&
    Object.values(value).every((property) => isNonNull(property))
  )
}

const isNullable = <GuardedType>(typeGuard: TypeGuard<GuardedType>) =>
  isSome(isNull, isUndefined, typeGuard)

const isOneOf =
  <Type extends boolean | number | string>(list: readonly Type[]) =>
  (value: unknown): value is Type =>
    list.some((item) => value === item)

const isSome =
  <GuardedTypes extends unknown[]>(
    ...typeGuards: [
      ...{ [Key in keyof GuardedTypes]: TypeGuard<GuardedTypes[Key]> },
    ]
  ) =>
  (value: unknown): value is TupleToUnion<GuardedTypes> =>
    typeGuards.some((typeGuard) => typeGuard(value))

const isEmptyObject = (value: unknown): value is object =>
  isObject(value) && Object.keys(value).length === 0

const isValidModalMode = (
  value: unknown,
): value is Exclude<ModalMode, null>["mode"] => {
  return (
    isString(value) &&
    (value === "skipTask" ||
      value === "goOfflineWithContact" ||
      value === "navigateWithContact")
  )
}

export {
  hasNonNullableProperties,
  hasProperty,
  hasValue,
  isArray,
  isArrayOf,
  isBoolean,
  isDefined,
  isEmptyObject,
  isNonNull,
  isNull,
  isNullable,
  isNullish,
  isNumber,
  isObject,
  isOneOf,
  isSome,
  isString,
  isUndefined,
  isValidModalMode,
  type NonEmptyArray,
  not,
  type TypeGuard,
}
