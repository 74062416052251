/* eslint-disable no-console */
import { FunctionComponent } from "react"
import { useSetAtom } from "jotai"

import AgentStatusSelect from "@/components/AgentStatusSelect"
import { useHeaderHook } from "@/components/Header/hook"
import Icon from "@/components/Icon"
import * as layoutValues from "@/components/layout/value"
import { cognitoModeAtom } from "@/helpers/atoms"

import AgentProfile from "./AgentProfile"
import { UserDropdownMenu } from "./user-dropdown-menu"

import "amazon-connect-streams"

interface Props {
  agentProfile: layoutValues.Decoder.ConnectUserInformation | null
}

const Header: FunctionComponent<Props> = ({ agentProfile }) => {
  const { agentKind, agentName } = useHeaderHook({ agentProfile })
  const setCognitoMode = useSetAtom(cognitoModeAtom)

  /**
   * This is only for testing purposes, it will be removed later
   * To be launched in the browser console
   */
  // @ts-expect-error TODO
  window.changeToken = (accessToken = "123") => {
    setCognitoMode({
      current: "AUTHENTICATED",
      state: { accessToken },
    })
    console.log("[auth] Fake token set!")
  }

  return (
    <div className="flex flex-row items-center justify-between border-b border-neutral-300 bg-neutral-100 px-6 py-4">
      <div className="px-2 py-1">
        <Icon name="logo" size="logo-size" />
      </div>
      <div className="flex flex-row items-center gap-x-4">
        <AgentStatusSelect />
        <UserDropdownMenu>
          <AgentProfile
            agentName={agentName}
            agentKind={agentKind}
            isLoading={!agentProfile}
          />
        </UserDropdownMenu>
      </div>
    </div>
  )
}

export default Header
