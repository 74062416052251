import { z } from "zod"

import { useQueryAPI } from "@/hooks/query-api"

const campaignDataSchema = z.object({
  campaignId: z.string(),
  taskAgeData: z.array(z.number()),
})

const schema = z.object({
  data: z.object({
    campaignData: z.array(campaignDataSchema),
    cacheDetails: z.object({
      cacheSetTimestamp: z.coerce.date(),
    }),
    dollarCostPerTask: z.number(),
  }),
})

export type CampaignItem = z.infer<typeof campaignDataSchema>

export function useFetchTasks() {
  return useQueryAPI({
    apiEndPoint: "/metrics/tasks/renewal",
    schema,
  })
}
