import { useTranslation } from "react-i18next"

import { useModal } from "@/components/core/Root/modal-root"
import { usePaginationParams } from "@/components/pagination/pagination-state"
import { Button } from "@/components/ui/button"

import { NoCallList } from "./search/no-call-list"
import { useFetchNoCallList } from "./search/search-api"
import { NoCallListSearchBar } from "./search/search-bar"
import { useNoCallListSearchOptions } from "./search/search-options"
import { DialogUploadNoCallCSV } from "./upload/dialog-upload-csv"

export function NoCallListPage() {
  const { t } = useTranslation()
  const { page, pageSize } = usePaginationParams()
  const searchState = useNoCallListSearchOptions()
  const { showModal } = useModal()

  const { data, isFetching, refetch } = useFetchNoCallList({
    page,
    pageSize,
    searchOptions: searchState.searchOptions,
  })

  const students = data?.data.students ?? []
  const total = data?.data.total ?? 0

  async function handleExport() {
    await showModal((close) => <DialogUploadNoCallCSV close={close} />)
    refetch()
  }

  return (
    <main className="flex h-full flex-col gap-y-4 px-8 pt-10">
      <div className="container flex flex-col gap-4">
        <h1 className="font-h2-bold text-neutral-700">
          {t("sidebarMenu.noCallList")}
        </h1>
        <div className="flex justify-between gap-8">
          <NoCallListSearchBar searchState={searchState} />
          <div>
            <Button onClick={handleExport}>
              {t("noCallList.importCSV.action")}
            </Button>
          </div>
        </div>
        <NoCallList
          students={students}
          isLoading={isFetching}
          pageSize={pageSize}
          total={total}
          page={page}
        />
      </div>
    </main>
  )
}
