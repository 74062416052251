import { QueryKey, useQuery, UseQueryOptions } from "@tanstack/react-query"
import { AxiosRequestConfig, Method } from "axios"
import { z } from "zod"

import { useAxiosClientContext } from "@/components/core/AxiosInstanceProvider/useAxiosClient"
import { config } from "@/config/index"
import { SentryLogger } from "@/helpers/sentryLogger"
import { useReactQueryOptions } from "@/hooks/reactQueryOptions"

const log = new SentryLogger()

/**
 * Wrapper around `useQuery` to fetch data from the API and validate the response with a Zod schema.
 * The query key is computed unless a custom one is provided, which can be useful for invalidating the cache.
 */
export function useQueryAPI<ResponseType>({
  apiEndPoint,
  data,
  method = "GET",
  options,
  params,
  queryKey: customQueryKey,
  schema,
}: {
  apiEndPoint: string
  data?: AxiosRequestConfig["data"]
  method?: Method
  options?: Omit<UseQueryOptions<ResponseType>, "queryFn" | "queryKey">
  params?: AxiosRequestConfig["params"]
  queryKey?: QueryKey
  schema: z.ZodType<ResponseType>
}) {
  const axiosClient = useAxiosClientContext()
  const { retry } = useReactQueryOptions()

  const queryKey = (
    customQueryKey
      ? [...customQueryKey, data, params]
      : [apiEndPoint, data, params]
  ).filter(Boolean)

  async function queryFn() {
    const response = await axiosClient.request({
      method,
      url: `https://${config.apiEndpoint}${apiEndPoint}`,
      data,
      params,
    })

    return parseDataFromAPI(response.data, schema)
  }

  return useQuery<ResponseType>({
    queryKey,
    queryFn,
    retry,
    ...options,
  })
}

export async function parseDataFromAPI<T>(data: unknown, schema: z.ZodType<T>) {
  try {
    const result = await schema.parseAsync(data) // `async` version to enable "refinements" and "transforms" feature

    return result
  } catch (error) {
    log.error(error) // send the detailed error to the error tracking system
    throw error
  }
}
