import { FunctionComponent } from "react"

import TruncatedAnswerList from "@/components/TruncatedAnswerList"
import TruncatedParagraph from "@/components/TruncatedParagraph"
import { isString } from "@/helpers/typeguards"

const styles = {
  studentTextContainer: "flex flex-col gap-y-2",
  studentTextTitle: "w-full text-neutral-600 font-label-small",
}

interface Props {
  answer: string[] | string
  question: string
}

const CollapsibleQuestion: FunctionComponent<Props> = ({
  answer,
  question,
}) => {
  return (
    <div className={styles.studentTextContainer}>
      <div className={styles.studentTextTitle}>{question}</div>
      {isString(answer) && <TruncatedParagraph text={answer} />}
      {Array.isArray(answer) && answer.length && (
        <TruncatedAnswerList items={answer} />
      )}
    </div>
  )
}

export default CollapsibleQuestion
