import { config } from "@/config/index"
import { isString } from "@/helpers/typeguards"

import LocalStorageService from "./localStorageService"

class ConnectSessionStorageService extends LocalStorageService {
  private readonly CONNECT_SESSION_KEY = "connect_login_timestamp"

  private static instance: ConnectSessionStorageService

  static getInstance(): ConnectSessionStorageService {
    if (!ConnectSessionStorageService.instance) {
      ConnectSessionStorageService.instance = new ConnectSessionStorageService()
    }

    return ConnectSessionStorageService.instance
  }

  setTimestampInLocalStorage(): void {
    const timestamp = Date.now()
    super.setItem(this.CONNECT_SESSION_KEY, timestamp.toString())
  }

  checkResetSession(): boolean {
    const connectLoginAt = super.getItem(this.CONNECT_SESSION_KEY)
    const issuedAt = isString(connectLoginAt)
      ? parseInt(connectLoginAt || "0", 10)
      : 0

    const now = Date.now()
    /**
     * This is the time interval in hours after which we need to refresh the connect access token.
     * Max age of the Connect access token is 12 hours. We can only refresh it once because the
     * refresh token is also valid for 12 hours (we're using an internal Connect API to refresh the access token)
     */
    const difference = now - issuedAt

    return difference > config.connectCookieRefreshTimeoutBuffer
  }

  clearConnectTimestamp(): void {
    super.removeItem(this.CONNECT_SESSION_KEY)
  }
}

const connectSessionStorageService = ConnectSessionStorageService.getInstance()

export default connectSessionStorageService
