import { useSearchParams } from "react-router-dom"

import { updateSearchParams } from "@/helpers/url"

import { searchOptionsSchema, StudentSearchOptions } from "./search-api"

export function useNoCallListSearchOptions() {
  const [params, setParams] = useSearchParams()

  const searchOptions = searchOptionsSchema.parse({
    search_query: params.get("search_query") || "",
  })

  const onChange =
    <K extends keyof StudentSearchOptions>(key: K) =>
    (value: StudentSearchOptions[K]) => {
      setParams((current) => {
        updateSearchParams(current, key, value)

        return current
      })
    }

  return { searchOptions, onChange }
}
