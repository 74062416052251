import toast from "react-hot-toast"
import { useLifecycles } from "react-use"
import { setTag } from "@sentry/react"

import { config } from "@/config"

import { AppUpdateChecker } from "./app-update-checker"
import { NotificationUpdateAvailable } from "./notification-update-available"

export const versionNumber = config.versionNumber
export const buildDate = config.buildDate

const updateChecker = new AppUpdateChecker({
  pollingInterval: config.checkAppUpdatePollingInterval,
  repeatInterval: config.checkAppUpdateRepeatInterval,
  versionNumber,
  buildDate,
})

export type AppMeta = {
  date: string
  version?: string
}

export function useUpdateChecker() {
  const notify = () => {
    toast(
      (t) => (
        <NotificationUpdateAvailable
          onIgnore={() => {
            updateChecker.skip()
            toast.dismiss(t.id)
          }}
          onUpdate={() => window.location.reload()}
        />
      ),
      {
        duration: Infinity,
        position: "top-center",
        id: "app-update",
      },
    )
  }
  useLifecycles(
    async () => {
      updateChecker.init(notify)
      setTag("version", versionNumber)
      setTag("release_date", buildDate)
    },
    () => {
      updateChecker.destroy()
    },
  )
}
