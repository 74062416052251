import { useEffect } from "react"
import { UseFormReturn, useWatch } from "react-hook-form"
import { useDebounce, useUpdateEffect } from "react-use"
import { useAtomValue } from "jotai"

import * as callViewValue from "@/components/CallView/value"
import { agentAtom } from "@/helpers/atoms"
import { isNull } from "@/helpers/typeguards"
import { useFormValidation } from "@/hooks/formValidation"
import { statusMap } from "@/models/cases"
import * as connectPanelValue from "@/pages/CustomConnectPanel/value"
import { contactStorageService } from "@/services/localStorageService"

import { CallReportModeType } from "../CallView"
import { ACWFormStatus } from "./hook"

export function useCallReportStatelessForm({
  callReportMode,
  caseStatuses,
  currentCase,
  form,
  onSave,
  status,
}: {
  callReportMode: CallReportModeType
  caseStatuses: connectPanelValue.Decoder.CaseStatuses | null
  currentCase: connectPanelValue.Decoder.CaseAndActivityFeeds | null
  form: UseFormReturn<callViewValue.Decoder.DecoderType>
  onSave: (data: callViewValue.Decoder.DecoderType) => void
  status: ACWFormStatus
}) {
  const agent = useAtomValue(agentAtom)
  const { validDate, validStartDateTime: validateCallScheduling } =
    useFormValidation()

  const isInboundCall =
    agent?.getContacts(connect.ContactType.VOICE)[0]?.isInbound() ?? false

  // persist form state to local storage; text fields are debounced
  const persistToLocalStorage = (isSaved: boolean) => {
    const formState = form.getValues()

    const value = {
      ...formState,
      updated_at: isSaved ? new Date() : undefined,
    }

    contactStorageService.setCallReport({ value, isSaved })
  }

  // Reset the form when user enters or leaves the "skip-mode" state
  useUpdateEffect(() => {
    form.reset()
  }, [callReportMode])

  usePersistForm(form, () => persistToLocalStorage(false))

  const { control, handleSubmit, resetField, watch } = form

  const selectedStatus = useWatch({
    control,
    name: "status",
  })

  const selectedReason = useWatch({
    control,
    name: "reason",
  })

  const options =
    callReportMode === "skip-call"
      ? getPreCallReportOptions(selectedStatus, currentCase)
      : getACWOptions(
          status === "pseudo_ACW",
          caseStatuses,
          selectedStatus,
          isInboundCall || callReportMode === "manual-call-mode",
        )

  const isDatePickerEnabled = shouldScheduleCall(
    selectedReason || selectedStatus,
  )

  const clearDateField = () =>
    resetField("scheduledCallDate", { defaultValue: null })

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      // Reset reason when status changes
      if (name === "status" && type === "change") {
        resetField("reason", { defaultValue: "" })
      }

      // Reset date value when the field is not available
      const shouldClearDateField =
        !shouldScheduleCall(value.reason || value.status) &&
        Boolean(value.scheduledCallDate)
      if (shouldClearDateField) {
        clearDateField()
      }
    })

    return () => subscription.unsubscribe()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmitValidForm = handleSubmit((data) => {
    persistToLocalStorage(true)

    onSave(data)
  })

  return {
    clearDateField,
    handleSubmit: handleSubmitValidForm,
    validDate,
    options,
    isDatePickerEnabled,
    validateCallScheduling,
  }
}

function shouldScheduleCall(reason?: string) {
  return reason === "日時を指定して再架電"
}

export function usePersistForm(
  form: UseFormReturn<callViewValue.Decoder.DecoderType>,
  persistFormState: (state: callViewValue.Decoder.DecoderType) => void,
) {
  const [status, reason, scheduledCallDate] = form.watch([
    "status",
    "reason",
    "scheduledCallDate",
  ])
  const memoText = form.watch("memo")

  useEffect(() => {
    persistFormState(form.getValues())
  }, [status, reason, scheduledCallDate]) // eslint-disable-line react-hooks/exhaustive-deps

  useDebounce(
    () => {
      persistFormState(form.getValues())
    },
    500,
    [memoText],
  )
}

function getPreCallReportOptions(
  selectedStatus: string,
  currentCase: connectPanelValue.Decoder.CaseAndActivityFeeds | null,
) {
  const fixedOptions = ["スキップ", "アウト", "リスト削除"]
  const latestRepeaterStatus = currentCase?.case.latest_repeater_status || null

  const statusOptions = isNull(latestRepeaterStatus)
    ? fixedOptions
    : [
        latestRepeaterStatus,
        ...fixedOptions.filter((status) => status !== latestRepeaterStatus),
      ]
  const reasonStatusMap: { [key: string]: string[] } = {
    アウト: ["アウト(架電前)"],
  }

  const reasonStatuses = reasonStatusMap[selectedStatus] ?? []

  return {
    status: statusOptions.map(toOption),
    reason: reasonStatuses.map(toOption),
  }
}

const toOption = (status: string) => ({ label: status, value: status })

function getACWOptions(
  isPseudoACW: boolean,
  caseStatuses: connectPanelValue.Decoder.CaseStatuses | null,
  selectedStatus: string,
  enableLeaveAsIs: boolean,
) {
  const pseudoACWRootStatuses = ["不通", "通信不可", "リスト削除", "アウト"]
  const rootStatuses = caseStatuses?.root ?? []
  const selectableRootStatues = enableLeaveAsIs
    ? rootStatuses
    : rootStatuses.filter((status) => status !== statusMap.LeaveAsIs)

  const rootCaseStatuses = isPseudoACW
    ? pseudoACWRootStatuses
    : selectableRootStatues

  const statusOptions = [
    ...(rootCaseStatuses ?? []),
    // Do not allow the agent to skip a contact if in the ACW state. But allow in Pseudo ACW state.
    ...(isPseudoACW ? caseStatuses?.skip ?? [] : []),
  ].map(toOption)

  const reasonStatuses = getReasonStatuses({
    selectedStatus,
    isPseudoACW,
    caseStatuses,
  })

  const reasonOptions = reasonStatuses.map(toOption)

  return {
    status: statusOptions,
    reason: reasonOptions,
  }
}

function getReasonStatuses({
  caseStatuses,
  isPseudoACW,
  selectedStatus,
}: {
  caseStatuses: connectPanelValue.Decoder.CaseStatuses | null
  isPseudoACW: boolean
  selectedStatus: string
}) {
  let reasonStatuses: string[]
  const consecutiveMissedCalls = "アウト(不通)"
  const rejectBeforeCall = "アウト(架電前)"
  const rejectUnsuitableDates = "アウト(有効商談)"
  const pseudoACWOutStatuses = [consecutiveMissedCalls, rejectUnsuitableDates]
  const outStatusesToExcludeFromACW = [consecutiveMissedCalls, rejectBeforeCall]

  switch (selectedStatus) {
    case "アウト":
      reasonStatuses = isPseudoACW
        ? pseudoACWOutStatuses
        : caseStatuses?.out?.filter(
            (status) => !outStatusesToExcludeFromACW.includes(status),
          ) ?? []
      break
    case "再架電":
      reasonStatuses = caseStatuses?.callAgain ?? []
      break
    default:
      reasonStatuses = []
  }

  return reasonStatuses
}
